import { Alert, Button, Card, Col, Form, Icon, Input, List, Result, Row, Select, Steps, Spin, Typography } from 'antd';
import InputCurrency from "../../components/InputCurrency";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import config from "../../config";
import { s3GetDownloadURL } from "../../libs/awsLib";
import { dealWithError, EIAPIHelper, formatCurrency, formatDate, getMetas, getMetasStepDetail, getStepCascade, getDocumentsList } from "../../libs/ei-helpers";
import _ from 'loadsh';
import "./PTAWizard.css";

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');


const { Step } = Steps
const { Option } = Select
const { Paragraph, Text } = Typography
const { TextArea } = Input;
const EIAPI = new EIAPIHelper()

class PTAWizardComponent extends React.Component {
	_isMounted = false;
	params = {};
	state = {
		current: 0,
		metas: [],
		data: {},
		person: {},
		documentList: [],
		metaTextList: [],
		pta: {},
		stepDetail: {},
		attachments: [],

		expense_pta_provider1_name: '',
		expense_pta_provider1_value_total: '',
		expense_pta_provider1_propose_id: '',

		expense_pta_provider2_name: '',
		expense_pta_provider2_value_total: '',
		expense_pta_provider2_propose_id: '',

		expense_pta_provider3_name: '',
		expense_pta_provider3_value_total: '',
		expense_pta_provider3_propose_id: '',

		expense_pta_invoice_id: '',
		expense_pta_receipt_id: '',
		isLoading: true
	}

	constructor(props) {
		super(props);
		this.params = this.props.match.params;

		props.appSetTitle('Depesas')
	}

	async componentDidMount() {
		// if (!this.props.isAuthenticated) {
		// 	return;
		// }

		this._isMounted = true;

		try {
			await this.getData()

			this.setState({ isLoading: false });
		} catch (e) {
			dealWithError('PTA Wizard', e)
		}

	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	async getData() {
		const id = this.params.id

		if (this._isMounted) {
			const data = await EIAPI.get(`/expenses/${id}`)
			const metasFull = await getMetas()
			const metas = metasFull[data.crea_pack_id].children
			const person = await EIAPI.get(`/persons/${data.person_id}`)
			const documentList = await getDocumentsList()

			const attachments = await s3GetDownloadURL(data.attachments || [])

			if (data.expense_crea_step_id !== '') {
				const expenseMetaKey = getStepCascade(data.expense_crea_step_id, metas)
				data.expense_crea_step = expenseMetaKey
			}

			const metaList = _.reject(_.uniq(_.map(data.payments, (i) => i.expense_crea_step_id)), _.isNil);
			const metaTextList = metaList.map(function (i) {
				const cascade = _.last(getStepCascade(i, metas, "label"));
				return cascade
			})

			const stepDetail = await getMetasStepDetail(_.first(metaList))

			this.setState({
				metas: metas,
				data: data,
				person: person,
				documentList: documentList,
				metaTextList: metaTextList,
				// pta: pta,
				stepDetail: stepDetail,
				attachments: attachments,

				expense_pta_provider1_name: stepDetail.provider_name1,
				expense_pta_provider1_value_total: parseFloat(stepDetail.provider_value_total1),
				expense_pta_provider1_propose_id: stepDetail.propose1_id,

				expense_pta_provider2_name: stepDetail.provider_name2,
				expense_pta_provider2_value_total: parseFloat(stepDetail.provider_value_total2),
				expense_pta_provider2_propose_id: stepDetail.propose2_id,

				expense_pta_provider3_name: stepDetail.provider_name3,
				expense_pta_provider3_value_total: parseFloat(stepDetail.provider_value_total3),
				expense_pta_provider3_propose_id: stepDetail.propose3_id,

			})

			// this.updateData()
		}
	}

	async signItem(data) {
		const id = this.params.id

		return await EIAPI.put(`/expenses/${id}/sign`, {
			body: data
		})
	}

	handleSubmit = async event => {
		event.preventDefault()

		this.props.form.validateFieldsAndScroll(async (err, values) => {
			try {
				if (err)
					throw new Error('Precisamos da sua atenção para conluir essa despesa, pois existem algumas informações que não foram preenchidas corretamente.');

				this.setState({ isLoading: true })

				const expense = {
					'expense_id': this.state.data.expense_id,

					'expense_pta_provider1_name': this.state.expense_pta_provider1_name,
					'expense_pta_provider1_value_total': this.state.expense_pta_provider1_value_total,
					'expense_pta_provider1_propose_id': this.state.expense_pta_provider1_propose_id,

					'expense_pta_provider2_name': this.state.expense_pta_provider2_name,
					'expense_pta_provider2_value_total': this.state.expense_pta_provider2_value_total,
					'expense_pta_provider2_propose_id': this.state.expense_pta_provider2_propose_id,

					'expense_pta_provider3_name': this.state.expense_pta_provider3_name,
					'expense_pta_provider3_value_total': this.state.expense_pta_provider3_value_total,
					'expense_pta_provider3_propose_id': this.state.expense_pta_provider3_propose_id,

					'expense_pta_invoice_id': this.state.expense_pta_invoice_id,
					'expense_pta_receipt_id': this.state.expense_pta_receipt_id,
				}

				const res = await this.signItem(expense)
				if (res.status !== 'success')
					throw new Error('Não foi possível salvar o registro :-(')

				this.props.history.push(`/expenses/view/${expense.expense_id}/`)
			} catch (e) {
				dealWithError("PTAWizard.handleSubmit", e)
			}
			this.setState({ isLoading: false })
		})
		return false
	}

	handleFieldChange = (field_name, field_value) => {
		console.debug('=== FIELD ON CHANGE', field_name, this.state[field_name], '-->', field_value)
		this.setState({
			[field_name]: field_value
		})
	}

	onChange = event => {
		const field_name = (event.target.id || event.target.name)
		const field_value = event.target.value

		this.handleFieldChange(field_name.replace(/^form_/, ''), field_value);
	}
	onChangeCurrency = event => {
		const field_name = (event.target.id || event.target.name)
		const field_value = Number.parseFloat(event.target.value.replace(/\./g, '').replace(/,/, '.'))

		this.handleFieldChange(field_name.replace(/^form_/, ''), field_value);
	}

	// TODO: fix this shit
	handleAttachmentChange1 = (value) => this.handleFieldChange('expense_pta_provider1_propose_id', value)
	handleAttachmentChange2 = (value) => this.handleFieldChange('expense_pta_provider2_propose_id', value)
	handleAttachmentChange3 = (value) => this.handleFieldChange('expense_pta_provider3_propose_id', value)
	handleAttachmentChangeInvoice = (value) => this.handleFieldChange('expense_pta_invoice_id', value)
	handleAttachmentChangeReceipt = (value) => this.handleFieldChange('expense_pta_receipt_id', value)

	next() {
		const current = this.state.current + 1;
		this.setState({ current });
	}

	prev() {
		const current = this.state.current - 1;
		this.setState({ current });
	}

	goToFirstStep = () => {
		this.setState({
			current: 0
		})
	}

	render() {
		const { getFieldDecorator } = this.props.form;
		const { current } = this.state

		const attachments = this.state.attachments
		// const metas = this.state.metas || []
		const metaTextList = this.state.metaTextList
		const data = this.state.data
		const person = this.state.person
		// const pta = this.state.pta
		const stepDetail = this.state.stepDetail

		const tips = [
			"Verifique se você está dentro do prazo para emissão dessa despesa",
			// "Estar dentro do prazo de validade para sua emissão",
			"Anexe uma cópia original e sem rasuras da nota fiscal e do comprovante de pagamento",
			"Documentes devem ser emitidos em nome do convenente (Entidade)",
			"Estar devidamente identificado com o número do convênio, e o nome do Concedente (CREA-SP) no corpo do comprovante de despesa",
			"Conter especificação detalhada dos materiais adquiridos e/ou  dos serviços prestados",
			// "Conter a atestação do recebimento definitivo do bem ou serviço, devidamente identificado (nome e CPF)",
			// "O CNAE do emitente da Nota Fiscal deve ser compatível o tipo da despesa"
		]

		const steps = [
			{
				title: 'Dados da despesa',
				content: (
					<Form className="step-form" {...config.FORM_LAYOUT} onSubmit={this.handleSubmit}>
						<Form.Item label="Meta / Etapa">
							{getFieldDecorator("expense_crea_step", {
								initialValue: metaTextList.join('\n')
							})(<TextArea
								style={{ width: '100%' }}
								disabled
							/>)}
						</Form.Item>

						<Form.Item label="Fornecedor">
							{getFieldDecorator("person_name", {
								initialValue: person.person_name
							})(<Input
								id="person_name"
								disabled
							/>)}
						</Form.Item>

						<Form.Item label="Valor Pago">
							{getFieldDecorator("expense_value_paid", {
								initialValue: formatCurrency(data.expense_value_paid)
							})(<Input
								id="expense_value_paid"
								disabled
							/>)}
						</Form.Item>

						<Card style={{ marginLeft: '80px', maxWidth: '80%', backgroundColor: 'lightyellow' }}>
							<Paragraph>
								<Text
									strong
									style={{
										fontSize: 16,
									}}
								>
									{/* <h3>{stepDetail.name}</h3>
									<h3>{stepDetail.subtitle}</h3> */}
									<h4>Dicas para preenchimento dessa despesa:</h4>
								</Text>
							</Paragraph>
							<List
								split={false}
								dataSource={tips}
								renderItem={item => <List.Item>{item}</List.Item>}
							/>
						</Card>
					</Form>
				),
			},
			{
				title: 'Orçamentos',
				content: (
					<Row className="step-form" gutter={12}>
						<Col lg={8}>
							<Card title="Proposta 1" >
								<Form.Item label="Fornecedor">
									{getFieldDecorator("expense_pta_provider1_name", {
										initialValue: stepDetail.provider_name1,
										// rules: [
										// 	{
										// 		required: true,
										// 		message: "Você precisa preencher o nome do fornecedor"
										// 	}
										// ]
									})(<Input
										id="expense_pta_provider1_name"
										onChange={this.onChange}
										placeholder="Fornecedor 1" />)}
								</Form.Item>

								<Form.Item label="Valor">
									{getFieldDecorator("expense_pta_provider1_value_total", {
										initialValue: stepDetail.provider_value_total1,
										// rules: [
										// 	{
										// 		required: true,
										// 		message: "Você precisa preencher o valor que foi pago"
										// 	}
										// ]
									})(<InputCurrency
										id="expense_pta_provider1_value_total"
										onChange={this.onChangeCurrency}
										min={0}
										placeholder="Qual o valor foi pago?" />)}
								</Form.Item>

								<Form.Item label="Orçamento 1">
									{getFieldDecorator("expense_pta_provider1_propose_id", {
										initialValue: this.state.expense_pta_provider1_propose_id,
										// rules: [
										// 	{
										// 		required: true,
										// 		message: "Ops! Esse campo é obrigatório"
										// 	}
										// ]
									})(
										<Select
											onChange={this.handleAttachmentChange1}
											showArrow={true}
											filterOption={false}
										>
											{_.sortBy(_.clone(attachments), 'name').map(d => (
												<Option key={d.uid} value={d.uid}>
													{d.name}
												</Option>
											))}
										</Select>
									)}
								</Form.Item>
							</Card>
						</Col>
						<Col lg={8}>
							<Card title="Proposta 2" >
								<Form.Item label="Fornecedor">
									{getFieldDecorator("expense_pta_provider2_name", {
										initialValue: stepDetail.provider_name2,
										// rules: [
										// 	{
										// 		required: true,
										// 		message: "Você precisa preencher o nome do fornecedor"
										// 	}
										// ]
									})(<Input
										id="expense_pta_provider2_name"
										onChange={this.onChange}
										placeholder="Fornecedor 2" />)}
								</Form.Item>

								<Form.Item label="Valor">
									{getFieldDecorator("expense_pta_provider2_value_total", {
										initialValue: stepDetail.provider_value_total2,
										// rules: [
										// 	{
										// 		required: true,
										// 		message: "Você precisa preencher o valor que foi pago"
										// 	}
										// ]
									})(<InputCurrency
										id="expense_pta_provider2_value_total"
										onChange={this.onChangeCurrency}
										min={0}
										placeholder="Qual o valor foi pago?" />)}
								</Form.Item>

								<Form.Item label="Orçamento 2">
									{getFieldDecorator("expense_pta_provider2_propose_id", {
										initialValue: this.state.expense_pta_provider2_propose_id,
										// rules: [
										// 	{
										// 		required: true,
										// 		message: "Ops! Esse campo é obrigatório"
										// 	}
										// ]
									})(
										<Select
											onChange={this.handleAttachmentChange2}
											showArrow={true}
											filterOption={false}
										>
											{_.sortBy(_.clone(attachments), 'name').map(d => (
												<Option key={d.uid} value={d.uid}>
													{d.name}
												</Option>
											))}
										</Select>
									)}
								</Form.Item>

							</Card>
						</Col>
						<Col lg={8}>
							<Card title="Proposta 3" >
								<Form.Item label="Fornecedor">
									{getFieldDecorator("expense_pta_provider3_name", {
										initialValue: stepDetail.provider_name3,
										// rules: [
										// 	{
										// 		required: true,
										// 		message: "Você precisa preencher o nome do fornecedor"
										// 	}
										// ]
									})(<Input
										id="expense_pta_provider3_name"
										onChange={this.onChange}
										placeholder="Fornecedor 3" />)}
								</Form.Item>

								<Form.Item label="Valor">
									{getFieldDecorator("expense_pta_provider3_value_total", {
										initialValue: stepDetail.provider_value_total3,
										// rules: [
										// 	{
										// 		required: true,
										// 		message: "Você precisa preencher o valor que foi pago"
										// 	}
										// ]
									})(<InputCurrency
										id="expense_pta_provider3_value_total"
										onChange={this.onChangeCurrency}
										min={0}
										placeholder="Qual o valor foi pago?" />)}
								</Form.Item>

								<Form.Item label="Orçamento 3">
									{getFieldDecorator("expense_pta_provider3_propose_id", {
										initialValue: this.state.expense_pta_provider3_propose_id,
										// rules: [
										// 	{
										// 		required: true,
										// 		message: "Ops! Esse campo é obrigatório"
										// 	}
										// ]
									})(
										<Select
											onChange={this.handleAttachmentChange3}
											showArrow={true}
											filterOption={false}
										>
											{_.sortBy(_.clone(attachments), 'name').map(d => (
												<Option key={d.uid} value={d.uid}>
													{d.name}
												</Option>
											))}
										</Select>
									)}
								</Form.Item>
							</Card>
						</Col>
					</Row>
				)
			},
			{
				title: 'Comprovantes',
				content: (
					<Row className="step-form" gutter={12}>
						<Col lg={12}>
							<Card title={
								<div>Nota Fiscal&nbsp;<Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /></div>
							}>
								<Form.Item label="Nota Fiscal">
									<Input
										id="expense_invoice"
										type="text"
										value={data.expense_invoice}
										disabled
									/>
								</Form.Item>
								<Form.Item label="Data Emissão">
									<Input
										id="expense_invoice_issued_at"
										type="text"
										value={formatDate(data.expense_invoice_issued_at)}
										disabled
									/>
								</Form.Item>

								<Form.Item label="Anexo da Nota Fiscal">
									{getFieldDecorator("expense_pta_invoice_id", {
										initialValue: this.state.expense_pta_invoice_id,
										rules: [
											{
												required: true,
												message: "Ops! Esse campo é obrigatório"
											}
										]
									})(
										<Select
											onChange={this.handleAttachmentChangeInvoice}
											showArrow={true}
											filterOption={false}
										>
											{_.sortBy(_.clone(attachments), 'name').map(d => (
												<Option key={d.uid} value={d.uid}>
													{d.name}
												</Option>
											))}
										</Select>
									)}
								</Form.Item>
							</Card>
						</Col>
						<Col lg={12}>
							<Card title={
								<div>Comprovante de Pagamento&nbsp;<Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /></div>
							}>
								<Form.Item label="Tipo Documento">
									<Input
										id="expense_document_name"
										type="text"
										value={typeof this.state.documentList[data.expense_document_type] !== 'undefined' ? this.state.documentList[data.expense_document_type].document_name : ''}
										disabled
									/>
								</Form.Item>
								<Form.Item label="Número Documento">
									<Input
										id="expense_document_nro"
										type="text"
										value={data.expense_document_nro}
										disabled
									/>
								</Form.Item>
								<Form.Item label="Anexo do Comprovante de Pagamento">
									{getFieldDecorator("expense_pta_receipt_id", {
										initialValue: this.state.expense_pta_receipt_id,
										rules: [
											{
												required: true,
												message: "Ops! Esse campo é obrigatório"
											}
										]
									})(
										<Select
											onChange={this.handleAttachmentChangeReceipt}
											showArrow={true}
											filterOption={false}
										>
											{_.sortBy(_.clone(attachments), 'name').map(d => (
												<Option key={d.uid} value={d.uid}>
													{d.name}
												</Option>
											))}
										</Select>
									)}
								</Form.Item>
							</Card>
						</Col>
					</Row>
				)
			},
			{
				title: 'Conclusão',
				content: (
					<Result
						status="success"
						title="Tudo pronto para concluir essa despesa"
						// subTitle="Veja o que foi conferido:"
						extra={[
							<Button.Group key="go-back">
								<Button onClick={this.goToFirstStep} icon="left">
									Voltar para revisar
								</Button>
							</Button.Group>,
							<Link key="list" to="/expenses">
								<Button type="primary" icon="check" onClick={this.handleSubmit}>Concluir</Button>
							</Link>
						]}
					>
						<div className="desc">
							<Paragraph>
								<Text
									strong
									style={{
										fontSize: 16,
									}}
								>
									Resumo da conferência dessa despesa:
								</Text>
							</Paragraph>
							<Paragraph>
								<Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /> Você está dentro do prazo de emissão
							</Paragraph>
							{/* <Paragraph>
								<Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /> Documentos enviados em nome da entidade
							</Paragraph> */}
							{
								(this.state.data.expense_value !== this.state.data.expense_value_paid) &&
									(<Paragraph><Icon type="exclamation-circle" theme="twoTone" twoToneColor="#ff4d4f" /> O valor pago é diferente do valor da despesa.</Paragraph>)
							}
							{
								(!_.isEmpty(this.state.expense_pta_invoice_id))
									? (<Paragraph><Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /> Nota fiscal selecionada</Paragraph>)
									: (<Paragraph><Icon type="exclamation-circle" theme="twoTone" twoToneColor="#ff4d4f" /> Você não selecionou a nota fiscal referente dessa despesa</Paragraph>)
							}
							{
								(!_.isEmpty(this.state.expense_pta_receipt_id))
									? (<Paragraph><Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /> Recibo de pagamento selecionado</Paragraph>)
									: (<Paragraph><Icon type="exclamation-circle" theme="twoTone" twoToneColor="#ff4d4f" /> Você não selecionou o recibo de pagamento referente dessa despesa</Paragraph>)
							}
							{
								(!_.isEmpty(this.state.expense_pta_provider1_propose_id)
									&& !_.isEmpty(this.state.expense_pta_provider2_propose_id)
									&& !_.isEmpty(this.state.expense_pta_provider3_propose_id))
									? (<Paragraph><Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /> Você selecionou os 3 orçamentos necessários</Paragraph>)
									: (<Paragraph><Icon type="question-circle" theme="twoTone" twoToneColor="#faad14" /> Você não selecionou os 3 orçamentos necessários - nem sempre todos os orçamentos são obrigatórios</Paragraph>)
							}
							{/* <Paragraph>
								<Icon type="close-circle" theme="twoTone" twoToneColor="red" /> O CNAE do emitente da Nota Fiscal não é compatível com o CNAE de palestrantes.
								<div style={{ marginLeft: '25px' }}>
									<small><Icon type="robot" /> Validação automática por inteligencia artificial (<a href="/help/1234" target="_blank">mais informações</a>)</small>
								</div>
							</Paragraph> */}
						</div>
					</Result>
				),
			},
		]

		const wrapperCol = {
			xs: {
				span: 24,
				offset: 0,
			},
			sm: {
				span: 22,
				offset: 1,
			},
		}

		const RenderPTALoading = (state) => {
			return (
				<div className="up-spin-area">
					<Spin size="large" />
				</div>
			)
		}

		const RenderPTAWizard = () => {
			const isExpenseBlocked = this.state.data.expense_blocked === 1

			return (
				<div className="PTAWizard">
					{isExpenseBlocked && <Alert
						message="Despesa Bloqueada"
						description={
							<span>
								<span>Essa despesa já foi assinada digitalmente e por este motivo está bloqueada para alterações.</span>
							</span>
						}
						showIcon
						type="success"
						style={{ marginBottom: 20 }}
					/>}

					{!isExpenseBlocked && <Row>
						<Col {...wrapperCol}>
							<Steps current={current}>
								{steps.map(item => (
									<Step key={item.title} title={item.title} />
								))}
							</Steps>
							<div className="steps-content">{steps[current].content}</div>
							<div className="steps-action">
								{current < steps.length - 1 && (
									<Button className="up-right-button" type="primary" onClick={() => this.next()}>
										Próximo
									</Button>
								)}
								{current > 0 && current !== steps.length - 1 && (
									<Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
										Anterior
									</Button>
								)}
							</div>
						</Col>
					</Row>}
				</div>
			)
		}

		return (
			<div>
				{this.state.isLoading ? RenderPTALoading() : RenderPTAWizard()}
			</div>
		)
	}
}

export default class PTAWizard extends Component {
	MainForm = Form.create({ name: "form" })(PTAWizardComponent);

	render() {
		const childProps = {
			// isAuthenticated: this.state.isAuthenticated,
			// isAppLoading: this.state.isAppLoading,
			match: this.props.match,
			history: this.props.history,
			appSetTitle: this.props.appSetTitle
		};

		return <this.MainForm {...childProps} />;
	}
}

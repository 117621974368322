import { Auth, Storage } from "aws-amplify";

export async function s3Upload(file) {
  const filename = `${Date.now()}-${file.name}`

  const stored = await Storage.vault.put(filename, file, {
    contentType: file.type
  })
 
  const credentials = await Auth.currentCredentials();

  return `${credentials.params.IdentityId}/${stored.key}` 
}

export async function s3Get(storeKey, args) {
  const params = {
    ...args,
    level: 'private'
  }
  const url = await Storage.vault.get(storeKey, params)
  return url
}

export async function s3GetPublic(storeKey, args) {
  const params = {
    ...args,
    level: 'public'
  }
  const url = await Storage.get(storeKey, params)
  return url
}

export async function s3GetDownloadURL(attachments) {
  const list = []

  const promises = attachments.map(async (attachment) => {
    const url = (typeof attachment.signed_url !== 'undefined' ? attachment.signed_url : await s3Get(attachment.attachment_store_key));
    list.push({
      uid: attachment.attachment_id,
      name: attachment.attachment_name,
      size: attachment.attachment_size,
      createdAt: attachment.attachment_created_at,
      status: 'done',
      url: url
    })
  })

  await Promise.all(promises);
  return list
}

import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";

import EntityForm from "./containers/Entity/EntityForm";
import EntityIntegration from "./containers/Entity/Integration";

import Persons from "./containers/Persons/Persons";
// import PersonAssociates from "./containers/Persons/PersonsAssociates";
// import PersonEmployees from "./containers/Persons/PersonsEmployees";
// import PersonProviders from "./containers/Persons/PersonsProviders";

import BankAccounts from "./containers/BankAccounts/BankAccounts";
import BankAccountsForm from "./containers/BankAccounts/BankAccountsForm";

import Notes from "./containers/Notes/Notes";
import NotesForm from "./containers/Notes/NotesForm";

import PersonsForm from "./containers/Persons/PersonsForm";
import PersonsImport from "./containers/Persons/PersonsImport";

import Expenses from "./containers/Expenses/Expenses";
import ExpensesForm from "./containers/Expenses/ExpensesForm";
import ExpensesView from "./containers/Expenses/ExpensesView";
import ExpensesAttachmentsView from "./containers/Expenses/ExpensesAttachmentsView";
import ExpensesCheck from "./containers/Expenses/ExpensesCheck";

import PTA from "./containers/PTA/PTA";
import PTAWizard from "./containers/PTA/PTAWizard";
import PTAFilesView from "./containers/PTA/PTAFilesView";
import PTAComplete from "./containers/PTA/PTAComplete";

import CDU from "./containers/CDU/CDU";
import CDUComplete from "./containers/CDU/CDUComplete";

import CreaEntityList from "./containers/CREA-SP/EntityList";

import Login from "./containers/Login";
import Signup from "./containers/Signup";
// import Settings from "./containers/Settings";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";

import TermsOfUse from "./containers/TermsOfUse";

export default ({ childProps }) =>
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/signup/:entityId" exact component={Signup} props={childProps} />

    <UnauthenticatedRoute path="/validar/:codeVerify/:codeEI" exact component={ExpensesCheck} props={childProps} />
    <UnauthenticatedRoute path="/validar/" exact component={ExpensesCheck} props={childProps} />
    {/* <UnauthenticatedRoute path="/expenses/view/:id" exact component={ExpensesView} props={childProps} /> */}

    {/* <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps} /> */}

    {/* <AuthenticatedRoute path="/entity/associates" exact component={PersonAssociates} props={{
      ...childProps,
      pageTitle: "Associados",
      filters: {
        person_tag: 'Associado'
      }
    }} />
    <AuthenticatedRoute path="/entity/employees" exact component={PersonEmployees} props={{
      ...childProps,
      pageTitle: "Funcionários",
      filters: {
        person_tag: 'Funcionario'
      }
    }} /> */}
    <AuthenticatedRoute path="/entity" exact component={EntityForm} props={childProps} />
    <AuthenticatedRoute path="/entity/integration" exact component={EntityIntegration} props={childProps} />

    <AuthenticatedRoute path="/bank-accounts" exact component={BankAccounts} props={childProps} />
    <AuthenticatedRoute path="/bank-accounts/new" exact component={BankAccountsForm} props={childProps} />
    <AuthenticatedRoute path="/bank-accounts/:id" exact component={BankAccountsForm} props={childProps} />

    <AuthenticatedRoute path="/notes" exact component={Notes} props={childProps} />
    <AuthenticatedRoute path="/notes/new" exact component={NotesForm} props={childProps} />
    <AuthenticatedRoute path="/notes/:id" exact component={NotesForm} props={childProps} />

    <AuthenticatedRoute path="/expenses" exact component={Expenses} props={childProps} />
    <AuthenticatedRoute path="/expenses/new" exact component={ExpensesForm} props={childProps} />
    <AuthenticatedRoute path="/expenses/wizard/:id" exact component={PTAWizard} props={childProps} />
    <AuthenticatedRoute path="/expenses/view/:id" exact component={ExpensesView} props={childProps} />
    <AuthenticatedRoute path="/expenses/view/:id/attachments" exact component={ExpensesAttachmentsView} props={childProps} />
    <AuthenticatedRoute path="/expenses/:id" exact component={ExpensesForm} props={childProps} />

    <AuthenticatedRoute path="/pta" exact component={PTA} props={childProps} />
    <AuthenticatedRoute path="/pta/complete" exact component={PTAComplete} props={childProps} />

    <AuthenticatedRoute path="/cdu" exact component={CDU} props={childProps} />
    <AuthenticatedRoute path="/cdu/complete" exact component={CDUComplete} props={childProps} />

    <AuthenticatedRoute path="/crea-admin/" exact component={CreaEntityList} props={{
      ...childProps,
      showMenu: false
    }} />
    <AuthenticatedRoute path="/pta/:ptaId/files" exact component={PTAFilesView} props={{
      ...childProps,
      showMenu: false
    }} />

    <AuthenticatedRoute path="/persons" exact component={Persons} props={{
      ...childProps,
      pageTitle: "Pessoas",
    }} />
    <AuthenticatedRoute path="/persons/new" exact component={PersonsForm} props={childProps} />
    <AuthenticatedRoute path="/persons/import" exact component={PersonsImport} props={childProps} />
    <AuthenticatedRoute path="/persons/:id" exact component={PersonsForm} props={childProps} />

    <AuthenticatedRoute path="/terms-of-use" exact component={TermsOfUse} props={childProps} />
    <AuthenticatedRoute path="/termos-de-uso" exact component={TermsOfUse} props={childProps} />

    {/* <AuthenticatedRoute path="/providers" exact component={PersonProviders} props={{
      ...childProps,
      pageTitle: "Fornecedores",
      filters: {
        person_tag: 'Fornecedor'
      }
    }} /> */}

    { /* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>;

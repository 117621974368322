import React, { Component } from "react";
import { Auth } from "aws-amplify";
import { Link, withRouter } from "react-router-dom";
// import { Nav, Navbar, NavItem } from "react-bootstrap";
// import { LinkContainer } from "react-router-bootstrap";
import Routes from "./Routes";
import ReactGA from "react-ga";
import {
  Avatar,
  Button,
  ConfigProvider,
  Divider,
  Tooltip,
  Layout,
  PageHeader,
  Menu,
  Modal,
  Icon,
  Form,
  Input,
  message,
} from "antd";
import ptBR from "antd/es/locale/pt_BR";
import ls from "local-storage";
import "./App.css";

import { dealWithError } from "./libs/ei-helpers";

import moment from "moment";
import "moment/locale/pt-br";
// import { formatCountdown } from "antd/lib/statistic/utils";
moment.locale("pt-br");

const { SubMenu } = Menu;
const { Header, Sider, Content } = Layout;

// const history = createBrowserHistory();

// history.listen(location => {
//   ReactGA.set({ page: location.pathname }); // Update the user's current page
//   ReactGA.pageview(location.pathname); // Record a pageview for the given page
// });

class App extends Component {
  constructor(props) {
    super(props);

    this.appSetTitle = this.appSetTitle.bind(this);

    const currentNewsVersion = 20200615;
    const userNewsVersion = ls.get("app.newsVersion");
    const newsVisible = currentNewsVersion !== userNewsVersion;

    this.state = {
      isAppLoading: true,
      isAuthenticated: false,
      isAuthenticating: true,
      collapsed: true,
      userInfo: {},
      session: {
        user_nickname: "",
        entity_name: "",
      },
      pageTitle: "Dashboard",
      newsModalVisible: newsVisible,
      current: document.location.pathname.split("/")[1] || "dashboard",
      changePassword: false,
      old_password: "",
      new_password: "",
      confirm_new_password: "",

      packList: [
        {
          "crea_pack_id": "4582bb40-8bc4-11ea-ba6e-1636a2bc008f",
          "crea_pack_description": "Plano de Trabalho 2020"
        },
        {
          "crea_pack_id": "0741bcb5-9fa8-11eb-8587-167a37a95f75",
          "crea_pack_description": "Plano de Trabalho 2021"
        },
        {
          "crea_pack_id": "0742f704-9fa8-11eb-8587-167a37a95f75",
          "crea_pack_description": "Cessão de Uso 2021"
        }
      ]
    };

    const trackingId = "UA-157788689-1";
    ReactGA.initialize(trackingId);
    ReactGA.set({
      userId:
        typeof Auth !== "undefined" && typeof Auth.currentUserInfo == "function"
          ? Auth.currentUserInfo().username
          : "",
    });
  }

  appSetTitle(newTitle) {
    this.setState({
      pageTitle: newTitle,
    });
  }

  async componentDidMount() {
    try {
      // const session = await Auth.currentSession()
      const user = await Auth.currentAuthenticatedUser();
      const userInfo = await Auth.currentUserInfo();

      if (
        typeof user === "undefined" ||
        typeof userInfo === "undefined" ||
        typeof userInfo.attributes === "undefined"
      )
        throw new Error(
          "Não foi possível fazer a autorização desse usuário :-("
        );

      this.setState(
        {
          userInfo: userInfo,
        },
        () => {
          this.userHasAuthenticated(true);
        }
      );
    } catch (e) {
      dealWithError("App", e);
      this.setState({ isAuthenticating: false, isAppLoading: false });
      this.userHasAuthenticated(false);
    }
  }

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  userHasAuthenticated = async (authenticated) => {
    if (authenticated) {
      // const sessionInfo = await API.get("ei-api", "/session")

      // if (typeof sessionInfo.user_id === 'undefined' || sessionInfo.user_id === '' || typeof sessionInfo.entity_id === 'undefined' || sessionInfo.entity_id === '')
      //   throw new Error('Não encontramos informações para o usuário: ' + this.state.userInfo.attributes.email)

      // Auth.updateUserAttributes(user, {
      //   'custom:entityId': sessionInfo.entity_id
      // });

      this.setState({
        session: {},
      });
    }

    this.setState({
      isAuthenticated:
        typeof authenticated !== "undefined" ? authenticated : false,
      isAppLoading: false,
    });
  };

  handleLogout = async (event) => {
    await Auth.signOut();

    this.userHasAuthenticated(false);

    this.props.history.push("/");
  };

  handleChangePassword = (event) => {
    this.setState({ changePassword: true });
  };

  handleAppMenuClick = (e) => {
    this.setState({
      current: e.key,
    });
  };

  newsModalOnClick = (e) => {
    ls.set("app.newsVersion", 20200615);
    this.setState({
      newsModalVisible: false,
    });
  };

  confirmChangePassword = async () => {
    this.setState({ changePassword: false })
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, this.state.old_password, this.state.new_password);
      })
      .then((data) => {
        this.setState({
          new_password: "",
          old_password: "",
          confirm_new_password: ""
        })
        message.success("Senha alterada com sucesso.", 10)
      })
      .catch((err) => {
        dealWithError("changePassword", err)
      });
  };

  cancelChangePassword = () => {
    this.setState({
      changePassword: false,
      old_password: "",
      new_password: "",
      confirm_new_password: "",
    });
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      userHasAuthenticated: this.userHasAuthenticated,
      isAppLoading: this.state.isAppLoading,
      pageTitle: this.state.pageTitle,
      packList: this.state.packList,
      appSetTitle: this.appSetTitle,
    };

    if (!this.state.isAuthenticated) return <Routes childProps={childProps} />;

    const newpwd = this.state.new_password || "";
    const newpwdconfirmed = this.state.confirm_new_password || "";
    const pwdCheck1 = newpwd.length >= 8;
    const pwdCheck2 = newpwd.match(/[A-Z]/) && newpwd.match(/[a-z]/);
    const pwdCheck3 = newpwd.match(/[0-9]/);
    const pwdCheck4 = newpwd.match(/\W/);
    const pwdCheck5 = newpwd === newpwdconfirmed && newpwd.length > 0;
    const pwdCheckAll =
      pwdCheck1 && pwdCheck2 && pwdCheck3 && pwdCheck4 && pwdCheck5;

    const hideMenu = this.props.location.pathname.match(/\/pta.*files/g)
      || this.props.location.pathname.match(/\/crea-admin/g);

    return (
      this.state.isAuthenticated &&
      !this.state.isAppLoading &&
      typeof this.state.userInfo != "undefined" && (
        <ConfigProvider locale={ptBR}>
          <Layout>
            <Sider
              collapsible
              collapsed={this.state.collapsed}
              onCollapse={(collapsed, type) => {
                this.setState({ collapsed });
              }}
              hidden={hideMenu}
              breakpoint="lg"
              collapsedWidth="80"
              onBreakpoint={(broken) => {
                this.setState({ broken });
              }}
            >
              <div className="logo">
                <img src="/imgs/ei_logo.png" alt="Entidade Integrada" />
              </div>
              <Menu
                theme="dark"
                mode="inline"
                multiple={true}
                onClick={this.handleAppMenuClick}
                selectedKeys={[this.state.current]}
              >
                <Menu.Item key="home">
                  <Link to="/">
                    <Icon type="project" />
                    <span className="nav-text">Resumo</span>
                  </Link>
                </Menu.Item>
                <Menu.Item key="notes">
                  <Link to="/notes">
                    <Icon type="file-text" />
                    <span className="nav-text">Anotações</span>
                  </Link>
                </Menu.Item>
                <SubMenu
                  key="entity"
                  title={
                    <div>
                      <Icon type="carry-out" />
                      <span className="nav-text">Minha entidade</span>
                    </div>
                  }
                >
                  <Menu.Item key="entity">
                    <Link to="/entity">
                      <Icon type="home" />
                      Dados da entidade
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="entity-persons">
                    <Tooltip
                      placement="right"
                      mouseEnterDelay={0.4}
                      title="Associados, Fornecedores e Funcionários"
                    >
                      <Link to="/persons">
                        <Icon type="usergroup-add" />
                        Pessoas&nbsp;&nbsp;
                        <Icon type="info-circle" />
                      </Link>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item key="entity-bank-account">
                    <Tooltip
                      placement="right"
                      mouseEnterDelay={0.4}
                      title="Cadastro de Contas Correntes utilizadas pela Entidade"
                    >
                      <Link to="/bank-accounts">
                        <Icon type="bank" />
                        Contas Correntes&nbsp;&nbsp;
                        <Icon type="info-circle" />
                      </Link>
                    </Tooltip>
                  </Menu.Item>
                  <Menu.Item key="expenses">
                    <Link to="/expenses">
                      <Icon type="credit-card" />
                      Despesas
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="workplan"
                  title={
                    <div>
                      <Icon type="user" />
                      <span className="nav-text">Plano de Trabalho</span>
                    </div>
                  }
                >
                  <Menu.Item key="metas">
                    <Link to="/pta">
                      <Icon type="reconciliation" />
                      <span>Resumo</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="complete">
                    <Link to="/pta/complete">
                      <Icon type="book" theme="twoTone" />
                      <span>Fechamento</span>
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Divider />
                  <Menu.Item key="cdu-resume">
                    <Link to="/cdu">
                      <Icon type="reconciliation" />
                      <span>Cessão de Uso</span>
                    </Link>
                  </Menu.Item> */}
                </SubMenu>
                <SubMenu
                  key="concession"
                  title={
                    <div>
                      <Icon type="coffee" />
                      <span className="nav-text">Cessão de Uso</span>
                    </div>
                  }
                >
                  <Menu.Item key="concession-resume">
                    <Link to="/cdu">
                      <Icon type="reconciliation" />
                      <span>Resumo</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="concession-complete">
                    <Link to="/cdu/complete">
                      <Icon type="book" theme="twoTone" />
                      <span>Fechamento</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu
                  key="website"
                  title={
                    <div>
                      <Icon type="global" />
                      <span className="nav-text">Meu Site</span>
                    </div>
                  }
                >
                  <Menu.Item key="pages" disabled>
                    <Link to="/pages">
                      <Icon type="read" />
                      <span className="nav-text">Páginas</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="events" disabled>
                    <Link to="/events">
                      <Icon type="calendar" />
                      <span className="nav-text">Eventos</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="news" disabled>
                    <Link to="/news">
                      <Icon type="notification" />
                      <span className="nav-text">Notícias</span>
                    </Link>
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </Sider>
            <Layout>
              <Header style={{ background: "#fff", padding: 0 }}>
                <div>
                  <PageHeader
                    style={{
                      border: "1px solid rgb(235, 237, 240)",
                    }}
                    backIcon="false"
                    title={this.state.pageTitle}
                    subTitle={this.state.session.entity_name}
                    // extra={[
                    //   <Button key="3">Operation</Button>,
                    //   <Button key="2">Operation</Button>,
                    //   <Button key="1" type="primary">
                    //     Primary
                    //   </Button>,
                    //   <DropdownMenu key="more" />,
                    // ]}

                    extra={[
                      <div key="1">
                        <Avatar
                          key="1"
                          size={24}
                          src={this.state.session.avatar}
                        />
                        <span>{this.state.session.user_nickname}</span>
                        <Tooltip key="3" title="Alterar Senha">
                          <Link to="/" onClick={this.handleChangePassword}>
                            <Icon type="lock" style={{ marginLeft: 13 }} />
                          </Link>
                        </Tooltip>
                        <Tooltip key="4" title="Sair">
                          <Link to="/" onClick={this.handleLogout}>
                            <Icon type="logout" style={{ marginLeft: 13 }} />
                          </Link>
                        </Tooltip>
                      </div>,
                    ]}
                  />
                </div>
              </Header>
              <Content style={{ margin: "24px 16px 0" }}>
                <div>
                  {this.state.isAppLoading && <div id="loader"></div>}
                  <Modal
                    visible={this.state.newsModalVisible}
                    centered={false}
                    closable={false}
                    className="ei-modal-news"
                    footer=""
                  >
                    <h1>Cadastro de Contas Correntes</h1>
                    <p>
                      Agora você pode cadastrar suas contas correntes e
                      utilizá-las na baixa de pagamento, inclusive pode
                      cadastrar até o caixinha que você tem na entidade :-)
                    </p>
                    <Divider />
                    <h1>Evolução da Baixa de Pagamentos</h1>
                    <p>
                      Agora é possível especificar pagamentos parciais, ou seja,
                      é possível informar numa mesma despesa verba da entidade e
                      também verba disponibilizada pelo CREA.
                    </p>
                    <Divider />
                    <h1>Melhorias no relatório do PTA</h1>
                    <p>
                      Ouvindo a sugestão dos usuários, agora temos 3 opções de
                      visualização do PTA:
                    </p>
                    <ul>
                      <li>Verba Total (CREA + Entidade);</li>
                      <li>
                        Verba do Concedente (apenas recursos concedidos pelo
                        CREA).
                      </li>
                      <li>
                        Verba do Proponente (apenas valores planejados para a
                        Entidade).
                      </li>
                    </ul>
                    <Divider />
                    <h1>Time EI</h1>
                    <p>
                      Não esqueça que você pode tirar suas dúvidas e
                      compartilhar ideias/sugestões utilizando o botão azul de
                      ajuda (fica no canto direito inferior da sua tela).
                    </p>
                    <Divider />
                    <Button
                      key="submit"
                      type="primary"
                      onClick={this.newsModalOnClick}
                    >
                      Entendi! :-)
                    </Button>
                  </Modal>
                  <Modal
                    visible={this.state.changePassword}
                    centered={false}
                    closable={true}
                    className="ei-modal-change-password"
                    title="Alterar Senha"
                    footer={null}
                    onCancel={this.cancelChangePassword}
                  >
                    <Form>
                      <Form.Item>
                        <h3>Informe a senha antiga: </h3>
                        <Input
                          id="old_password"
                          type="password"
                          value={this.state.old_password}
                          prefix={
                            <Icon
                              type="lock"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          placeholder="Senha antiga"
                          onChange={(e) => this.handleChange(e)}
                        />
                      </Form.Item>
                      <Form.Item>
                        <h3>Informe a nova senha: </h3>
                        <Input
                          id="new_password"
                          type="password"
                          value={this.state.new_password}
                          prefix={
                            <Icon
                              type="lock"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          placeholder="Nova senha"
                          onChange={(e) => this.handleChange(e)}
                        />
                      </Form.Item>
                      <Form.Item>
                        <h3>Confirme a nova senha: </h3>
                        <Input
                          id="confirm_new_password"
                          value={this.state.confirm_new_password}
                          type="password"
                          prefix={
                            <Icon
                              type="lock"
                              style={{ color: "rgba(0,0,0,.25)" }}
                            />
                          }
                          placeholder="Confirmar nova senha"
                          onChange={(e) => this.handleChange(e)}
                        />
                      </Form.Item>
                      <Form.Item>
                        Por questões de segurança, sua senha precisa conter:
                        <div style={{ marginLeft: 8, padding: 4 }}>
                          <Icon
                            type={pwdCheck1 ? "check-circle" : "stop"}
                            theme="twoTone"
                            twoToneColor={pwdCheck1 ? "#52c41a" : "red"}
                          />{" "}
                          No mínimo 8 caracteres
                        </div>
                        <div style={{ marginLeft: 8, padding: 4 }}>
                          <Icon
                            type={pwdCheck2 ? "check-circle" : "stop"}
                            theme="twoTone"
                            twoToneColor={pwdCheck2 ? "#52c41a" : "red"}
                          />{" "}
                          Letras minúsculas e maiúsculas
                        </div>
                        <div style={{ marginLeft: 8, padding: 4 }}>
                          <Icon
                            type={pwdCheck3 ? "check-circle" : "stop"}
                            theme="twoTone"
                            twoToneColor={pwdCheck3 ? "#52c41a" : "red"}
                          />{" "}
                          Números
                        </div>
                        <div style={{ marginLeft: 8, padding: 4 }}>
                          <Icon
                            type={pwdCheck5 ? "check-circle" : "stop"}
                            theme="twoTone"
                            twoToneColor={pwdCheck5 ? "#52c41a" : "red"}
                          />{" "}
                          Nova senha confirmada
                        </div>
                        <div style={{ marginLeft: 8, padding: 4 }}>
                          <Icon
                            type={pwdCheck4 ? "check-circle" : "stop"}
                            theme="twoTone"
                            twoToneColor={pwdCheck4 ? "#52c41a" : "red"}
                          />{" "}
                          Pelo menos um caracter especial
                          <br />
                          (ex: !, $, #, %, &, etc)
                        </div>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          onClick={this.confirmChangePassword}
                          disabled={!pwdCheckAll}
                          block
                        >
                          Alterar
                        </Button>
                        <Button
                          onClick={this.cancelChangePassword}
                          type="danger"
                          block
                        >
                          Cancelar
                        </Button>
                      </Form.Item>
                    </Form>
                  </Modal>
                  <Routes childProps={childProps} />
                </div>
              </Content>
              {/* <Footer style={{ textAlign: 'center' }}>EI © 2021</Footer> */}
            </Layout>
          </Layout>
        </ConfigProvider>
      )
    );
  }
}

export default withRouter(App);

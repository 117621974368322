import React, { Component } from "react"
// import ReactDOM from "react-dom";
import { API } from "aws-amplify"
// import { LinkContainer } from "react-router-bootstrap"
// import { ListGroup, ListGroupItem } from "react-bootstrap"
import { dealWithError } from "../../libs/ei-helpers"
import { Link } from "react-router-dom";
import { Table, Button, Icon } from 'antd'

// import "./Notes.css"

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

export default class Notes extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false
    this.state = {
      isLoading: true,
      notes: [],
      notesPagination: {}
    }

    props.appSetTitle('Anotações')
  }

  async componentDidMount() {
    this._isMounted = true

    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const res = await API.get("ei-api", "/notes");

      if (this._isMounted) {
        this.setState({
          notes: res.data,
          notesPagination: res.pagination
        });
      }
    } catch (e) {
      dealWithError('Notes', e);
    }

    if (this._isMounted) {
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onChange(pagination, filters, sorter, extra) {
    console.debug('params', pagination, filters, sorter, extra);
  }

  renderNotesList(notes) {
    var columns = [
      {
        title: 'Título',
        dataIndex: 'note_title',
        filters: notes.map((note) => { return { "text": note.note_title, "value": note.note_title } }),
        onFilter: (value, record) => record.note_title.indexOf(value) === 0,
        sorter: (a, b) => a.content.length - b.content.length,
        render: (text, row) => <Link to={`/notes/${row.note_id}`}>{text}</Link>
      },
      {
        title: 'Data Criação',
        dataIndex: 'note_created_at',
        sorter: (a, b) => moment(a.note_created_at) - moment(b.note_created_at),
        render: (text) => <span>{moment(text).fromNow()}</span>
      },
      {
        title: 'Data Alteração',
        dataIndex: 'note_updated_at',
        defaultSortOrder: 'descend',
        sorter: (a, b) => moment(a.note_updated_at) - moment(b.note_updated_at),
        render: (text) => <span>{moment(text).fromNow()}</span>
      }
    ];

    return (
      <div>
        <div style={{ 'textAlign': 'right', 'marginBottom': '10px' }}>
          <Link to="/notes/new">
            <Button type="primary">
              <Icon type="plus" />
              <span className="nav-text">Nova anotação</span>
            </Button>
          </Link>
        </div>
        <Table
          bordered
          columns={columns}
          rowKey='note_id'
          dataSource={notes}
          onChange={this.onChange}
          pagination={{
            'position': 'both'
          }}
        />
      </div>
    )
    // return [{}].concat(notes).map(
    //   (note, i) =>
    //     i !== 0
    //       ? <LinkContainer
    //           key={note.noteId}
    //           to={`/notes/${note.noteId}`}
    //         >
    //           <ListGroupItem header={note.content.trim().split("\n")[0]}>
    //             {"Created: " + new Date(note.createdAt).toLocaleString()}
    //           </ListGroupItem>
    //         </LinkContainer>
    //       : <LinkContainer
    //           key="new"
    //           to="/notes/new"
    //         >
    //           <ListGroupItem>
    //             <h4>
    //               <b>{"\uFF0B"}</b> Criar nova anotação 2
    //             </h4>
    //           </ListGroupItem>
    //         </LinkContainer>
    // );
  }

  render() {
    return (
      <div className="notes">
        {!this.state.isLoading && this.renderNotesList(this.state.notes)}
      </div>
    )
  }
}

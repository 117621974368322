import React, { Component } from "react"
import { EIAPIHelper, dealWithError, formatDate } from "../../libs/ei-helpers"
import { Link } from "react-router-dom"
import { Card, Table, Menu, Dropdown, Icon, Tooltip, Popover } from 'antd'
// import { LockOutlined } from '@ant-design/icons';

import qs from 'qs'
import _ from 'loadsh'
import Excel from "exceljs"
import { saveAs } from 'file-saver';

import moment from 'moment'
import 'moment/locale/pt-br'
moment.locale('pt-br')

const { Meta } = Card
const EIAPI = new EIAPIHelper()

export default class Expenses extends Component {
  constructor(props) {
    super(props)

    props.appSetTitle('Despesas')

    const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });

    this.state = {
      isLoading: true,
      expenses: [],
      expensesPagination: {},
      packList: props.packList,
      query
    }
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return
    }

    try {
      const EIAPI = new EIAPIHelper()
      const filters = {}

      if (typeof this.state.query['filter.step_id'] !== 'undefined')
        filters.search = this.state.query['filter.step_id']

      const res = await EIAPI.get(`/expenses`, {
        ...filters
      })

      this.setState({
        expenses: res.data,
        expensesPagination: res.pagination
      })
    } catch (e) {
      dealWithError('expenses', e)
    }

    this.setState({ isLoading: false })
  }

  doExport = async () => {
    const res = await EIAPI.get("/expenses", this.state.pageFilter)

    // try {
    const workbook = new Excel.Workbook()
    const worksheet = workbook.addWorksheet("Despesas")

    worksheet.columns = [
      { header: 'Documento Fornecedor', key: 'person_document', width: 70 },
      { header: 'Nome Fornecedor', key: 'person_nicename', width: 100 },
      { header: 'Descrição', key: 'expense_description', width: 100 },
      { header: 'Valor', key: 'expense_value', width: 40 },
      { header: 'Valor Pago', key: 'expense_value_paid', width: 40 },
      { header: 'Data Vencimento', key: 'expense_due_day', width: 40 },
      { header: 'Previsão Pagamento', key: 'expense_payment_forecast', width: 40 },
      { header: 'Nota Fiscal', key: 'expense_invoice', width: 40 },
      { header: 'PTA / Categoria', key: 'expense_crea_step_title', width: 70 },
      { header: 'PTA / Etapa', key: 'expense_crea_category_title', width: 70 },
      { header: 'Data Emissão Nota Fiscal', key: 'expense_invoice_issued_at', width: 40 },
      { header: 'Chave NF-e', key: 'expense_invoice_nfe', width: 55 },
      { header: 'Número Documento', key: 'expense_document_nro', width: 40 },
      { header: 'Tipo Documento', key: 'expense_document_type', width: 40 },
      { header: 'Observações', key: 'expense_notes', width: 100 },
      { header: 'Criado por', key: 'created_by_nickname', width: 90 },
      { header: 'Data Criação', key: 'expense_created_at', width: 40 },
      { header: 'Alterado por', key: 'updated_by_nickname', width: 90 },
      { header: 'Data Última Alteração', key: 'expense_updated_at', width: 40 },
    ];

    const headRow = worksheet.getRow(1)
    headRow.font = { bold: true }

    _.map(res.data, (row) => {
      row.expense_due_day = formatDate(row.expense_due_day, 'DD/MM/YYYY')
      row.expense_payment_forecast = formatDate(row.expense_payment_forecast, 'DD/MM/YYYY')
      row.expense_invoice_issued_at = formatDate(row.expense_invoice_issued_at, 'DD/MM/YYYY')
      row.expense_created_at = formatDate(row.expense_created_at, 'DD/MM/YYYY HH:mm')
      row.expense_updated_at = formatDate(row.expense_updated_at, 'DD/MM/YYYY HH:mm')
    })
    worksheet.addRows(res.data)

    worksheet.autoFilter = {
      from: {
        row: 1,
        column: 1
      },
      to: {
        row: worksheet.lastRow._number,
        column: worksheet.columnCount
      }
    }

    workbook.xlsx.writeBuffer().then(function (data) {
      var blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      saveAs(blob, `despesas-${moment().format()}.xlsx`);
    });

    // } catch (e) {
    //   dealWithError('Exportar', e)
    // }
  }

  renderExpensesList(expenses) {
    const getStatusDescription = (row) => {
      let status = getStatus(row)
      let dueDay = moment.utc(row.expense_due_day)
      let label = ''

      if (['pago', 'concluido'].includes(status))
        label = (
          <span><Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" /> Pago</span>
        )
      else if (status === 'pagoamais')
        label = (
          <span><Icon type="exclamation-circle" theme="twoTone" twoToneColor="#52c41a" /> Pago a mais</span>
        )
      else if (status === 'vencido')
        label = (
          <span><Icon type="exclamation-circle" theme="twoTone" twoToneColor="#eb2f96" /> Vencido {dueDay.fromNow()}</span>
        )
      else if (status === 'vencehoje')
        label = (
          <span><Icon type="alert" theme="twoTone" twoToneColor="#eb2f96" /> Vence Hoje</span>
        )
      else if (status === 'avencer')
        label = (
          <span><Icon type="clock-circle" /> Vence {dueDay.fromNow()}</span>
        )

      if (row.expense_blocked === 1)
        label = (
          <span>{label} <Icon type="lock" /></span>
        )

      if (row.attachments_count > 0)
        label = (
          <span>{label} <Icon type="paper-clip" /></span>
        )

      return label
    }

    const getStatus = (row) => {
      if (row.expense_blocked)
        return 'concluido'

      if (row.expense_value === row.expense_value_paid)
        return 'pago'
      else if (row.expense_value <= row.expense_value_paid)
        return 'pagoamais'

      if (row.expense_value > row.expense_value_paid) {
        let dueDay = moment.utc(row.expense_due_day)
        let today = moment().utc()

        if (dueDay.isBefore(today))
          return 'vencido'
        else if (dueDay.isSame(today, 'day'))
          return 'vencehoje'
        else if (dueDay.isAfter(today))
          return 'avencer'

        return ''
      }
    }

    var packFiltersList = [];
    var packFilters = [];
    _.each(this.state.packList, (row) => {
      packFilters[row.crea_pack_id] = row.crea_pack_description;
      packFiltersList.push({
        "text": row.crea_pack_description,
        "value": row.crea_pack_id
      })
    })
    var columns = [
      {
        title: 'Situação',
        dataIndex: 'expense_status',
        filters: [
          { "text": "Pagos", "value": ["pago", "pagoamais", "concluido"] },
          { "text": "Vencidos", "value": ["vencido"] },
          { "text": "A vencer", "value": ["vencehoje", "avencer"] },
          { "text": "Concluídos", "value": ["concluido"] },
        ],
        onFilter: (value, row) => value.indexOf(getStatus(row)) >= 0,
        sorter: (a, b) => getStatus(a).localeCompare(getStatus(b)),
        render: (_text, row) => <Link to={`/expenses/${row.expense_id}`} style={{ whiteSpace: "nowrap" }}>{getStatusDescription(row)}</Link>
      },
      {
        title: 'Chamamento',
        dataIndex: 'crea_pack_id',
        filters: packFiltersList,
        onFilter: (value, row) => value === row.crea_pack_id,
        sorter: (a, b) => packFilters[a.crea_pack_id].localeCompare(packFilters[b.crea_pack_id]),
        render: (id, row) => <Link to={`/expenses/${row.expense_id}`} style={{ whiteSpace: "nowrap" }}>{packFilters[id]}</Link>
      },
      {
        title: 'Vencimento',
        dataIndex: 'expense_due_day',
        defaultSortOrder: 'descend',
        sorter: (a, b) => moment(a.expense_due_day) - moment(b.expense_due_day),
        render: (text, row) => text && <Link to={`/expenses/${row.expense_id}`}>
          {moment.utc(text).format('DD/MM/YYYY')}
          {!moment(text).isSame(row.expense_payment_forecast, 'day') && <Tooltip title={`Previsão de pagamento: ${moment.utc(row.expense_payment_forecast).format('DD/MM/YYYY')}`}> <Icon type="calendar" /></Tooltip>}
        </Link>
      },
      {
        title: 'Nro. Documento',
        dataIndex: 'expense_document_nro',
        // filters: expenses && expenses.map((note) => { return { "text": note.person_document, "value": note.person_document } }),
        // onFilter: (value, record) => record.person_document.indexOf(value) === 0,
        sorter: (a, b) => a.expense_document_nro !== null && a.expense_document_nro.localeCompare(b.expense_document_nro),
        render: (text, row) => <Link to={`/expenses/${row.expense_id}`}>{text}</Link>
      },
      {
        title: 'Meta / Etapa',
        dataIndex: 'expense_crea_step_title',
        // filters: expenses && expenses.map((row) => { return { "text": row.person_document, "value": row.person_document } }),
        // onFilter: (value, row) => !row.person_document.localeCompare(value),
        sorter: (a, b) => a.expense_crea_step_title !== null
          && a.expense_crea_step_title.localeCompare(b.expense_crea_step_title),
        render: (text, row) => {
          return <Link to={`/expenses/${row.expense_id}`}>
            {typeof row.expense_crea_step_title !== 'string' ? '' : row.expense_crea_step_title.split(',').map(d => (
              <div key={d}>
                {d}
              </div>
            ))}
          </Link>
        }
      },
      {
        title: 'Fornecedor',
        dataIndex: 'person_nicename',
        // filters: expenses && expenses.map((note) => { return { "text": note.name, "value": note.name } }),
        // onFilter: (value, record) => record.name.indexOf(value) === 0,
        sorter: (a, b) => a.person_nicename !== null && a.person_nicename.localeCompare(b.person_nicename),
        render: (text, row) => <Link to={`/expenses/${row.expense_id}`}>{text}<br /><small>{row.person_document}</small></Link>
      },
      {
        title: 'Valor',
        dataIndex: 'expense_value',
        // filters: expenses && expenses.map((note) => { return { "text": note.nicename, "value": note.nicename } }),
        // onFilter: (value, record) => record.nicename.indexOf(value) === 0,
        sorter: (a, b) => a.expense_value > b.expense_value,
        render: (text, row) => <Link to={`/expenses/${row.expense_id}`}>
          {text.toLocaleString('pt-br', { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 })}
        </Link>
      },
      {
        title: <Icon type="info-circle" />,
        width: 50,
        render: (_text, row) => <Popover placement="bottomRight" content={
          <Card
            style={{ width: 300 }}
            size="small"
            bordered={false}
            actions={[
              <Link key="edit" to={`/expenses/${row.expense_id}`}><Icon type="edit" /> Editar</Link>,
              <Link key="check" to={`/expenses/wizard/${row.expense_id}`}><Icon type="check" /> Validar e Concluir</Link>
            ]}
          >
            <div>
              <Meta
                title="Detalhes"
                description={<span>Essa despesa foi criada por {row.created_by_nickname} em {moment(row.expense_created_at).format('DD/MM/YYYY HH:mm')}, mas foi alterada em {moment(row.expense_updated_at).format('DD/MM/YYYY HH:mm')} por {row.updated_by_nickname}</span>}
              />
            </div>
          </Card>
        } trigger="click">
          <Icon type="more" />
        </Popover>
      }
    ]

    return (
      <div>
        <div className="ei-table-actions">
          <Dropdown.Button type="primary" overlay={(
            <Menu>
              <Menu.Item key="1" onClick={this.doExport}>
                Exportar para Excel
              </Menu.Item>
            </Menu>
          )}>
            <Link to="/expenses/new">
              <Icon type="plus" />
              <span className="nav-text">Nova despesa</span>
            </Link>
          </Dropdown.Button>
        </div>
        <Table
          bordered
          columns={columns}
          rowKey='expense_id'
          dataSource={expenses}
          pagination={{
            'position': 'both'
          }}
        />
      </div>
    )

  }

  render() {
    return (
      <div className="expenses">
        {!this.state.isLoading && this.renderExpensesList(this.state.expenses)}
      </div>
    )
  }
}

import React, { Component } from "react";
import {
    Alert,
    Avatar,
    Badge,
    Cascader,
    Form,
    Input,
    DatePicker,
    Descriptions,
    Button,
    Popconfirm,
    Modal,
    Upload,
    Spin,
    Select,
    Icon,
    Card,
    Radio,
    Tabs,
    Tooltip,
    Table,
    Row, Col,
    // Switch,
    message,
} from "antd";

import LoaderButton from "../../components/LoaderButton";
import InputCurrency from "../../components/InputCurrency";
import { getStepCascade, dealWithError, parseInt, parseFloat, parseBoolean, EIAPIHelper, getMetas, getBaseUri, formatDate, formatCurrency } from "../../libs/ei-helpers";
import _ from 'loadsh';
import uuid from 'uuid'

import { s3Upload, s3GetDownloadURL } from "../../libs/awsLib";
import config from "../../config";
import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const { TextArea } = Input
const { TabPane } = Tabs
const { MonthPicker } = DatePicker;

const EIAPI = new EIAPIHelper()

const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
    state = {
        editing: false,
    };

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, title } = this.props;
        const { editing } = this.state;
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ],
                    initialValue: record[dataIndex],
                })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24 }}
                onClick={this.toggleEdit}
            >
                {children}
            </div>
        );
    };

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
                ) : (
                    children
                )}
            </td>
        );
    }
}

const PaymentRegisterForm = Form.create({ name: 'payment_register_form' })(
    // eslint-disable-next-line
    class extends React.Component {
        constructor(props) {
            super(props);

            this.state = {
                expense_payment_by_proponent: 1,
                changes: 0
            };
        }


        // onMetaChange = async (value) => {
        //     this.setState({
        //         data: {
        //             ...this.state.data,
        //             expense_crea_step: value,
        //             expense_crea_step_id: _.last(value) || ''
        //         }
        //     })
        // }

        render() {
            const { visible, onCancel, onCreate, bankAccounts, packId, metas, checkedMetas, form, paymentFormData } = this.props;
            const { getFieldDecorator } = form;
            const _onCreate = () => {
                onCreate(form)
            }
            const _onCancel = () => {
                this.setState({
                    changes: 0,
                    expense_payment_by_proponent: 1
                })
                onCancel(form)
            }

            const onPayerChange = async (e) => {
                // if(paymentFormData) paymentFormData['expense_payment_by_proponent'] = parseInt(e.target.value)
                this.setState({
                    expense_payment_by_proponent: parseInt(e.target.value),
                    changes: this.state.changes + 1
                })
            }

            // const isDisabled = () => paymentFormData && this.state.changes === 0 ? paymentFormData.expense_payment_by_proponent !== 0 : this.state.expense_payment_by_proponent !== 0;

            const getRadioInitialValue = () => paymentFormData ? `${paymentFormData.expense_payment_by_proponent}` : '1'
            const getAccountId = () => bankAccounts[0] ? bankAccounts[0]['account_id'] : 0

            const metasList = typeof packId !== 'undefined' && typeof metas[packId] !== 'undefined' ? metas[packId].children : Object.values(metas)

            return (
                <Modal
                    visible={visible}
                    title={paymentFormData ? "Alterar Pagamento" : "Registrar Pagamento"}
                    okText={paymentFormData ? "Alterar" : "Registrar"}
                    cancelText=""
                    onCancel={_onCancel}
                    onOk={_onCreate}
                >
                    <Form layout="vertical">
                        {
                            paymentFormData ?
                                <Form.Item hidden={true}>
                                    {getFieldDecorator('payment_id', {
                                        initialValue: paymentFormData.expense_payment_id
                                    })}
                                    <Input id="payment_id" disabled={true}></Input>
                                </Form.Item> :
                                null
                        }
                        <Form.Item label="Data do Pagamento">
                            {getFieldDecorator('expense_payment_date', {
                                initialValue: paymentFormData ? moment(paymentFormData.expense_payment_date) : moment(),
                                rules: [{ required: true, message: 'Quando esse pagamento foi realizado?' }],
                            })(<DatePicker
                                id="expense_payment_date"
                                format="DD/MM/YYYY"
                                placeholder="Quando esse valor foi pago?" />)}
                        </Form.Item>
                        <Form.Item label="Valor Pago">
                            {getFieldDecorator("expense_payment_value", {
                                initialValue: paymentFormData ? paymentFormData.expense_payment_value : 0,
                                rules: [
                                    {
                                        required: true,
                                        message: "Você precisa preencher o valor que foi pago"
                                    }
                                ]
                            })(<InputCurrency
                                id="expense_payment_value"
                                // disabled={isExpenseBlocked}
                                min={0}
                                placeholder="Qual o valor foi pago?" />)}
                        </Form.Item>
                        <Form.Item label="Conta Corrente">
                            {getFieldDecorator('account_id', {
                                rules: [
                                    {
                                        required: true,
                                        message: "Qual a conta corrente foi utilizada para realizar o pagamento?"
                                    },
                                ],
                                initialValue: paymentFormData ? paymentFormData.account_id : getAccountId()
                            })(<Select
                                // showSearch
                                // disabled={isExpenseBlocked}
                                // style={this.props.style}
                                hasFeedback
                                // defaultActiveFirstOption={true}
                                filterOption={true}
                                // onSearch={this.fetchPersons}
                                // onChange={this.handleChange}
                                // notFoundContent={this.state.personListStatus || 'Comece a digitar para pesquisar por um fornecedor...'}
                                // loading={this.state.isFetchingBanks}
                                // validateStatus={this.state.isFetchingBanks ? "validating" : "valid"}
                                optionLabelProp="label"
                            >
                                {bankAccounts.map(d => (
                                    <Select.Option key={d.account_id} value={d.account_id} label={(`${d.account_name}`)}>
                                        <div>
                                            {/* <span style={{ float: 'right' }}>{d.bank_type_name}</span> */}
                                            <div className="bank-small-icon">
                                                <img src={(`/${d.bank_icon}`)} alt={d.bank_nro} />
                                            </div>
                                            <strong>{d.account_name}</strong>
                                        </div>
                                    </Select.Option>
                                ))}
                            </Select>)}
                        </Form.Item>
                        <Form.Item label="Qual a verba utilizada?" className="collection-create-form_last-form-item">
                            {getFieldDecorator('expense_payment_by_proponent', {
                                initialValue: getRadioInitialValue(),

                            })(
                                <Radio.Group onChange={onPayerChange}>
                                    <Radio value="1">Entidade</Radio>
                                    <Radio value="0">CREA</Radio>
                                </Radio.Group>,
                            )}
                        </Form.Item>
                        <Form.Item label="Meta / Etapa">
                            {getFieldDecorator("expense_crea_step", {
                                initialValue: paymentFormData ? getStepCascade(paymentFormData.expense_crea_step_id, metasList, "value") : checkedMetas
                            })(<Cascader
                                style={{ width: '100%' }}
                                options={metasList}
                                // onChange={this.onMetaChange}
                                // disabled={isDisabled()}
                                // defaultValue={checkedMetas}
                                // showSearch={{ filter }}
                                placeholder="Selecione a meta e a etapa correspondente"
                            />)}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    },
);

class PaymentsTable extends React.Component {
    constructor(props) {
        super(props);

        this.onDataChanged = props.onDataChanged || function () { }

        this.state = {
            dataSource: props.dataSource,
            bankAccounts: props.bankAccounts,
            packId: props.packId,
            metas: props.metas,
            isExpenseBlocked: props.isExpenseBlocked
        };

        const packMetas = typeof this.state.metas !== 'undefined'
                            && typeof this.state.packId !== 'undefined'
                            && typeof this.state.metas[this.state.packId] !== 'undefined' ? this.state.metas[this.state.packId].children : []

        this.columns = [
            {
                key: 'col_expense_payment_date',
                title: 'Data Pagamento',
                dataIndex: 'expense_payment_date',
                render: (text) => formatDate(text),
                editable: false
            },
            {
                key: 'col_expense_payment_value',
                title: 'Valor',
                dataIndex: 'expense_payment_value',
                render: (text) => formatCurrency(text),
                editable: false
            },
            {
                key: 'col_account',
                title: 'Conta / Verba',
                render: (text, row) => <div>
                    <Badge dot={parseBoolean(row.expense_payment_by_proponent)}>
                        <Avatar shape="square" size="small" icon={<img src={getBaseUri(row.bank_icon)} alt={row.bank_name} />} />
                    </Badge>
                    <span style={{ marginLeft: 16 }}>
                        {row.expense_payment_by_proponent ? "Entidade" : "CREA"}
                    </span>
                </div>,
                editable: false
            },
            {
                key: 'col_expense_crea_step_id',
                title: 'Etapa',
                dataIndex: 'expense_crea_step_id',
                render: (text, row) => _.last(getStepCascade(text, packMetas, "label")),
                editable: false
            },
            {
                key: 'col_operation_del',
                title: '',
                dataIndex: 'operation_del',
                width: 30,
                render: (text, record) =>
                    !this.state.isExpenseBlocked && <Popconfirm title="Tem certeza que deseja excluir esse registro de pagamento?" onConfirm={() => this.handleDelete(record.expense_payment_id)}>
                        <Button type="link" icon="delete" />
                    </Popconfirm>
            },
            {
                key: 'col_operation_edit',
                title: '',
                dataIndex: 'operation_edit',
                width: 30,
                render: (text, record) => (
                    !this.state.isExpenseBlocked && <Button type="link" icon="edit" onClick={() => this.handleEdit(record.expense_payment_id)} />
                )
            }
        ];
    }

    handleEdit = key => {
        const dataEdit = this.state.dataSource.filter(row => row.expense_payment_id === key);
        this.setState({
            paymentFormVisible: true,
            paymentFormData: dataEdit[dataEdit.length - 1],

        })
    }

    handleDelete = key => {
        const dataSource = _.map([...this.state.dataSource], (row) => {
            if (row.expense_payment_id === key)
                row.expense_payment_deleted_at = new Date()

            return row
        });
        this.setState({
            dataSource: dataSource
        }, () => this.onDataChanged(this.state.dataSource));
    };

    paymentRegisterFormToggle = () => {
        this.setState({
            paymentFormVisible: true
        })
    };

    handlePaymentCancel = (form) => {
        form.resetFields()
        this.setState({
            expense_payment_by_proponent: 0,
            paymentFormVisible: false,
            paymentFormData: this.initialPaymentFormData
        })
    }

    handlePaymentCreate = async (form) => {
        if (typeof form !== 'undefined')
            form.validateFieldsAndScroll(async (err, values) => {
                if (!err) {
                    const { dataSource, bankAccounts } = this.state;
                    const accountId = form.getFieldValue('account_id');
                    const stepId = _.last(form.getFieldValue('expense_crea_step')); // parseInt(form.getFieldValue('expense_payment_by_proponent')) !== 1 ? _.last(form.getFieldValue('expense_crea_step')) : null;
                    const account = _.find(bankAccounts, (row) => { if (row.account_id === accountId) { return row } })
                    const newData = {
                        expense_payment_id: form.getFieldValue('payment_id') || uuid.v1(),
                        account_id: accountId,
                        expense_crea_step_id: stepId,
                        account_name: account.account_name,
                        bank_name: account.bank_name,
                        bank_icon: account.bank_icon,
                        expense_payment_date: form.getFieldValue('expense_payment_date'),
                        expense_payment_value: parseFloat(form.getFieldValue('expense_payment_value')),
                        expense_payment_by_proponent: parseInt(form.getFieldValue('expense_payment_by_proponent')),
                        expense_payment_deleted_at: null
                    };
                    // console.log("============================================================================", newData)
                    form.resetFields()

                    if (form.getFieldValue('payment_id')) {
                        this.state.dataSource.forEach(data => {
                            if (data.expense_payment_id === newData.expense_payment_id) {
                                this.handleDelete(data.expense_payment_id);
                            }
                        })

                        this.setState({
                            dataSource: [...this.state.dataSource, newData],
                            paymentFormVisible: false,
                            paymentFormData: this.initialPaymentFormData,
                        }, () => {
                            this.onDataChanged(this.state.dataSource)
                        })
                    } else {
                        this.setState({
                            dataSource: [...dataSource, newData],
                            paymentFormVisible: false,
                        }, () => {
                            this.onDataChanged(this.state.dataSource)
                        });
                    }

                }
            })
    }

    handleSave = row => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ dataSource: newData }, () => this.onDataChanged(this.state.dataSource));
    };

    render() {
        const dataSource = _.filter(this.state.dataSource, (row) => {
            return row.expense_payment_deleted_at === null
        })

        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });

        const bankAccounts = this.state.bankAccounts || []
        const packId = this.state.packId
        const metas = this.state.metas || []
        const checkedMetas = '' // this.state.data.expense_crea_step

        return (
            <div>
                <Button icon="plus" type="link" disabled={this.state.isExpenseBlocked} onClick={this.paymentRegisterFormToggle} style={{ marginBottom: 16 }}>
                    Registrar pagamento
                </Button>
                <PaymentRegisterForm
                    // wrappedComponentRef={this.saveFormRef}
                    closable={true}
                    visible={this.state.paymentFormVisible}
                    paymentFormData={this.state.paymentFormData}
                    bankAccounts={bankAccounts}
                    metas={metas}
                    packId={packId}
                    checkedMetas={checkedMetas}
                    onCancel={this.handlePaymentCancel}
                    onCreate={this.handlePaymentCreate}
                />
                <Table
                    {...this.props}
                    components={components}
                    rowClassName={() => 'editable-row'}
                    // bordered
                    dataSource={dataSource}
                    columns={columns}
                />
            </div>
        );
    }
}

class ExpensesFormComponent extends React.Component {
    _isMounted = false;
    params = {};
    initialPaymentFormData = {};
    state = {
        isLoading: false,
        isDeleting: false,
        isDataLoading: false,
        isFetchingPersons: false,
        isFetchingCategories: false,
        paymentFormVisible: false,
        paymentFormData: this.initialPaymentFormData,
        bankAccounts: [],
        personList: [],
        categoryList: [],
        documentList: [],
        packList: [],
        data: {
            person_id: '',
            crea_pack_id: '',
            expense_value_full_proponent: false,
            expense_value_paid: 0,
            expense_value_proponent: 0
        },

        payments: [],

        attachments: [],
        newAttachments: []
    }

    constructor(props) {
        super(props);

        this.params = this.props.match.params;
        this.state.packList = this.props.packList;

        this.props.appSetTitle("Despesas");
    }

    async componentDidMount() {
        this._isMounted = true;

        try {
            await this.getData()
        } catch (e) {
            dealWithError("componentDidMount", e)
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getData() {
        const id = typeof this.params.id !== "undefined" ? this.params.id : "new"
        let data = {}

        this.setState({
            isLoading: true,
            isFetchingCategories: true,
            isFetchingDocuments: true
        })

        await EIAPI.get('/expenses/categories', {
            expense_category_outgoing: 1
        }).then((res) => {
            if (typeof res.data !== 'undefined')
                this.setState({
                    categoryList: res.data,
                    isFetchingCategories: false
                })
        })

        await EIAPI.get('/bank-accounts').then((res) => {
            this.setState({
                bankAccounts: res.data
            })
        })

        EIAPI.get('/expenses/documents').then((res) => {
            this.setState({
                documentList: res.data,
                isFetchingDocuments: false
            })
        })

        this.setState({
            metas: await getMetas()
        })

        if (id !== "new") {
            data = await EIAPI.get(`/expenses/${id}`)
        } else {
            data = {
                expense_id: uuid.v1(),
                payments: []
            }
        }

        if (this._isMounted) {
            const attachments = await s3GetDownloadURL(data.attachments || []);

            data.expense_due_day = data.expense_due_day !== null ? moment.utc(data.expense_due_day) : ''
            data.expense_payment_forecast = data.expense_payment_forecast !== null ? moment.utc(data.expense_payment_forecast) : ''
            data.expense_invoice_issued_at = data.expense_invoice_issued_at !== null ? moment.utc(data.expense_invoice_issued_at) : ''
            data.expense_ref_month = data.expense_ref_month !== null ? moment.utc(data.expense_ref_month) : ''

            // if (data.expense_crea_step_id !== '') {
            //     const expenseMetaKey = getStepCascade(data.expense_crea_step_id, this.state.metas);
            //     data.expense_crea_step = expenseMetaKey;
            // }

            if (typeof data.person_id !== 'undefined')
                await this.fetchPersons({ 'person_id': data.person_id })

            data.expense_value_paid = _.sumBy(data.payments, (row) => {
                return row.expense_payment_deleted_at === null ? row.expense_payment_value : 0
            })

            this.setState({
                data: data,
                payments: data.payments,
                attachments: attachments,
                isLoading: false
            }, () => {
                this.updateData()
            })
        }
    }

    async getVisiblePayments(data) {
        // console.log('getVisiblePayments 1', data)
        const newData = await _.filter(data, (row) => {
            return row.expense_payment_deleted_at === null
        })
        // console.log('getVisiblePayments 2', newData)
        return newData
    }

    updateData(objKeys) {
        const keys = objKeys || this.props.form.getFieldsValue()
        Object.keys(keys).forEach(key => {
            if (key !== 'key' && key !== 'keys' && key !== 'phone_keys') {
                const obj = {
                    [key]: this.state.data[key]
                }
                this.props.form.setFieldsValue(obj)
            }
        })
    }

    async saveItem(data) {
        const id = typeof this.params.id !== "undefined" ? this.params.id : "new"

        const numberFields = [
            'expense_value',
            'expense_value_paid'
        ]
        const dateFields = [
            'expense_due_day',
            'expense_payment_forecast',
            'expense_invoice_issued_at',
            'expense_ref_month'
        ]

        numberFields.forEach((fieldKey) => {
            data[fieldKey] = parseFloat(data[fieldKey])
        })
        dateFields.forEach((fieldKey) => {
            data[fieldKey] = moment.utc(data[fieldKey]).format('YYYY-MM-DD')
        })

        const payments = await _.map(this.state.payments, (row) => {
            if (typeof row.expense_payment_created_at === 'undefined' || row.expense_payment_created_at === null)
                delete row.expense_payment_id

            return row
        })

        const postData = _.merge(data, {
            expense_id: this.state.data.expense_id,
            payments: payments,
            newAttachments: this.state.newAttachments
        })

        if (id !== "new")
            return await EIAPI.put(`/expenses/${this.params.id}`, {
                body: postData
            })
        else
            return await EIAPI.post(`/expenses/`, {
                body: postData
            })
    }

    async deleteItem() {
        return EIAPI.del(`/expenses/${this.params.id}`)
    }

    handleSubmit = async event => {
        event.preventDefault()

        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true })

                try {
                    const res = await this.saveItem(values)
                    if (res.status !== 'success')
                        throw new Error('Não foi possível salvar o registro :-(')

                    this.props.history.push(`/expenses/`)
                } catch (e) {
                    dealWithError("Expenses.handleSubmit", e)
                    this.setState({ isLoading: false })
                }
            }
        })

        return false
    }

    handleDelete = async event => {
        event.preventDefault();

        this.setState({ isDeleting: true });

        try {
            await this.deleteItem();
            this.props.history.push("/expenses");
        } catch (e) {
            dealWithError('Expenses.handleDelete', e)
            this.setState({ isDeleting: false })
        }
    }

    handleLock = async event => {
        event.preventDefault();

        this.setState({ isLocking: true });

        try {
            await this.deleteItem();
            this.props.history.push("/expenses");
        } catch (e) {
            dealWithError('Expenses.handleDelete', e)
            this.setState({ isLocking: false })
        }
    }

    onChange = event => {
        const field_name = event.target.id || event.target.name
        const field_value = event.target.value

        this.setState({
            [field_name]: field_value
        })
    }

    // onMetaChange = async (value) => {
    //     this.setState({
    //         data: {
    //             ...this.state.data,
    //             expense_crea_step: value,
    //             expense_crea_step_id: _.last(value) || ''
    //         }
    //     })
    // }

    customRequest = async ({ onSuccess, onError, file }) => {
        if (file && file.size > config.MAX_ATTACHMENT_SIZE) {
            message.info(`O tamanho máximo de anexos é de ${config.MAX_ATTACHMENT_SIZE / 1000000} MB.`);
            return;
        }

        try {
            if (file) {
                const storedKey = await s3Upload(file)

                const fileData = {
                    attachment_name: file.name,
                    attachment_size: file.size,
                    attachment_content_type: file.type,
                    attachment_store_key: storedKey
                }

                this.setState({
                    newAttachments: [...this.state.newAttachments, fileData]
                })

                onSuccess(file.storedKey, file)
            }
        } catch (e) {
            dealWithError('ExpensesForm.customRequest', e)
            onError(e.message)
        }
    }

    onAttachmentRemove = async event => {
        const upload_id = event.uid
        const attachments = this.state.newAttachments.filter(file => file.upload_id !== upload_id)
        this.setState({
            newAttachments: attachments
        })
        EIAPI.del(`/attachments/expenses/${this.params.id}/${upload_id}`);
    }

    // onCategorySelect = (value, el) => {
    //     const self = this;
    //     const stepSuggestion = el.props['data-step-suggestion'];

    //     this.setState({
    //         categoryChanged: _.size(self.state.data.expense_crea_step) > 0
    //     })

    //     if (_.size(self.state.data.expense_crea_step) === 0) {
    //         const expenseMetaKey = getStepCascade(stepSuggestion, this.state.metas);
    //         const stepId = _.last(expenseMetaKey) || '';

    //         if (stepId) {
    //             self.setState({
    //                 data: {
    //                     ...this.state.data,
    //                     expense_crea_step_id: stepId
    //                 }
    //             })
    //             self.props.form.setFieldsValue({
    //                 expense_crea_step: expenseMetaKey,
    //                 expense_crea_step_id: stepId,
    //             })
    //         }
    //     }
    // }

    _fetchTimeout = null
    _fetchTimeoutDelay = 450
    fetchPersons = async value => {
        if (typeof value === 'undefined' || value === '')
            return

        if (this._fetchTimeout !== null) {
            clearTimeout(this._fetchTimeout)
        }

        this._fetchTimeout = setTimeout(async () => {
            try {
                if (value !== '') {
                    this.setState({
                        personList: [],
                        personListStatus: `Pesquisando por ${value}...`,
                        isFetchingPersons: true
                    })

                    const res = await EIAPI.get("/persons", typeof value === 'object' ? value : { search: value })
                    const personList = typeof res.data !== 'undefined' ? res.data : []

                    this.setState({
                        personList,
                        personListStatus: _.size(personList) === 0 ? `Nenhum registro encontrado para "${value}"` : '',
                        isFetchingPersons: false
                    })
                } else {
                    this.setState({
                        personListStatus: '',
                        isFetchingPersons: false
                    })
                }

            } catch (e) {
                dealWithError('ExpensesForm->fetchPersons', e)
            }
        }, this._fetchTimeoutDelay)
    }

    render() {
        const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;

        const attachments = this.state.attachments
        // const metas = this.state.metas || []
        // const checkedMetas = this.state.data.expense_crea_step
        // ["e30a2c55-0bca-11ea-b765-16c68ce42311", "fb5d41b2-0bcb-11ea-b765-16c68ce42311", "8d21fb0a-0bd0-11ea-b765-16c68ce42311"]        

        const wrapperCol = {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 22,
                offset: 1,
            },
        }

        const onPaymentsChanged = async (paymentsList) => {
            const expense_value_paid = _.sumBy(paymentsList, (row) => {
                return row.expense_payment_deleted_at === null ? row.expense_payment_value : 0
            })

            this.setState({
                payments: paymentsList,
                data: { expense_value_paid: expense_value_paid }
            }, () => {
                this.props.form.setFieldsValue({
                    expense_value_paid: expense_value_paid
                })
            })
        }

        const packId = this.state.data.crea_pack_id
        const isExpenseBlocked = this.state.data.expense_blocked === 1
        return (
            <div className="Expenses">
                {this.state.isLoading && (
                    <div className="up-spin-area">
                        <Spin />
                    </div>
                )}
                {!this.state.isLoading && (
                    <Form {...config.FORM_LAYOUT} onSubmit={this.handleSubmit}>
                        {isExpenseBlocked && <Alert
                            message="Despesa Bloqueada"
                            description={
                                <div>
                                    <p>Essa despesa já foi assinada digitalmente e por este motivo está bloqueada para alterações.</p>
                                    <Button href={`/expenses/view/${this.state.data.expense_id}`} target="_blank">Visualizar confirmação</Button>
                                </div>
                            }
                            showIcon
                            type="success"
                            style={{ marginBottom: 20 }}
                        />}
                        <Form.Item label="Fornecedor">
                            {getFieldDecorator("person_id", {
                                initialValue: this.state.data.person_id,
                                rules: [
                                    {
                                        required: true,
                                        message: "Ops! Esse campo é obrigatório"
                                    }
                                ]
                            })(
                                <Select
                                    showSearch
                                    disabled={isExpenseBlocked}
                                    // style={this.props.style}
                                    hasFeedback
                                    defaultActiveFirstOption={false}
                                    showArrow={true}
                                    filterOption={false}
                                    onSearch={this.fetchPersons}
                                    // onChange={this.handleChange}
                                    notFoundContent={this.state.personListStatus || 'Comece a digitar para pesquisar por um fornecedor...'}
                                    loading={this.state.isFetchingPersons}
                                    validateStatus={this.state.isFetchingPersons ? "validating" : "valid"}
                                    optionLabelProp="label"
                                >
                                    {this.state.personList.map(d => (
                                        <Select.Option key={d.person_id} value={d.person_id} label={(`${d.person_nicename} [${d.person_document}]`)}>
                                            <div>
                                                <b>{d.person_nicename}</b>
                                                <span style={{ float: 'right' }}>{d.person_document}</span>
                                            </div>
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Categoria">
                            {getFieldDecorator("expense_category_id", {
                                initialValue: this.state.data.expense_category_id,
                                rules: [
                                    {
                                        required: true,
                                        message: "Ops! Esse campo é obrigatório"
                                    }
                                ]
                            })(
                                <Select
                                    // showSearch
                                    // style={this.props.style}
                                    disabled={isExpenseBlocked}
                                    defaultActiveFirstOption={true}
                                    showArrow={true}
                                    loading={this.state.isFetchingCategories}
                                    optionLabelProp="label"
                                    // onSelect={this.onCategorySelect}
                                >
                                    {this.state.categoryList.map(d => (
                                        <Select.Option disabled={d.expense_category_total} key={d.expense_category_id} value={d.expense_category_id} data-step-suggestion={d.expense_crea_step_id_suggestion} label={(`${d.expense_category_code} - ${d.expense_category_name}`)}>
                                            <div>
                                                <b>{d.expense_category_code} - {d.expense_category_name}</b>
                                                <Tooltip title={d.expense_category_description}>
                                                    <small style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden', marginLeft: 10, marginRight: 35 }}>
                                                        {d.expense_category_description.substr(0, 120)}
                                                        {d.expense_category_description.length > 120 ? "..." : ""}
                                                    </small>
                                                </Tooltip>
                                            </div>
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                            {/* {this.state.categoryChanged && <Alert
                                // message="Dica / Plano de Trabalho"
                                description={
                                    <span>
                                        <span>Você alterou a categoria dessa despesa e recomendamos que você revise a meta do</span>
                                        <Tooltip title="Plano de Trabalho"> PTA </Tooltip>
                                        <span>:-)</span>
                                    </span>
                                }
                                showIcon
                                closable
                                type="info"
                            />} */}
                        </Form.Item>
                        <Form.Item label="Chamamento">
                            {/* <Select
                                defaultValue="1"
                                disabled
                            >
                                <Select.Option value="1">
                                    CREA-SP - CHAMAMENTO PÚBLICO 002/2019
                                </Select.Option>
                            </Select> */}
                            {getFieldDecorator("crea_pack_id", {
                                initialValue: "0741bcb5-9fa8-11eb-8587-167a37a95f75", // PTA 2021
                                rules: [
                                    {
                                        required: true,
                                        message: "Ops! Esse campo é obrigatório"
                                    }
                                ]
                            })(
                                <Select
                                    disabled={isExpenseBlocked}
                                    defaultActiveFirstOption={true}
                                    showArrow={true}
                                    options={this.state.packList}
                                    onSelect={this.onPackSelect}
                                >
                                    {this.state.packList.map(d => (
                                        <Select.Option key={d.crea_pack_id} value={d.crea_pack_id}>
                                            {d.crea_pack_description}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item label="Observações">
                            {getFieldDecorator("expense_notes", {
                                initialValue: this.state.expense_notes
                            })(<TextArea
                                id="expense_notes"
                                disabled={isExpenseBlocked}
                                autoSize
                            />)}
                        </Form.Item>
                        <Row style={{ marginBottom: '20px' }}>
                            <Col {...wrapperCol}>
                                <Tabs>
                                    <TabPane tab="Datas & Valores" key="1">
                                        <Card title="Datas & Valores">
                                            <Row gutter={[12, 12]}>
                                                <Col lg={12}>
                                                    <Form.Item label="Valor da Conta" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("expense_value", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "Você precisa preencher o valor da conta"
                                                                }
                                                            ]
                                                        })(<InputCurrency
                                                            id="expense_value"
                                                            disabled={isExpenseBlocked}
                                                            min={0}
                                                            placeholder="Qual o valor dessa despesa?" />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={12}>
                                                    <Form.Item label="Data de Vencimento" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("expense_due_day", {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "Ops! Esse campo é obrigatório"
                                                                }
                                                            ]
                                                        })(<DatePicker
                                                            id="expense_due_day"
                                                            disabled={isExpenseBlocked}
                                                            format="DD/MM/YYYY"
                                                            onChange={(current => (typeof getFieldValue("expense_payment_forecast") === 'undefined') && setFieldsValue({ "expense_payment_forecast": current }))}
                                                            placeholder="Quando essa conta vai vencer?" />)}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[12, 12]}>
                                                <Col lg={12}>
                                                    <Form.Item label="Valor Pago" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("expense_value_paid", {
                                                            // rules: [
                                                            //     {
                                                            //         max: parseFloat(getFieldValue("expense_value")),
                                                            //         message: 'O valor pago não pode ser maior que o valor da conta'
                                                            //     }
                                                            // ]
                                                        })(
                                                            <InputCurrency
                                                                id="expense_value_paid"
                                                                min={0}
                                                                max={parseFloat(getFieldValue("expense_value"))}
                                                                disabled={true}
                                                                placeholder="Quanto você já pagou dessa despesa?"
                                                            />
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={12}>
                                                    <Form.Item label="Previsão de Pagamento" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("expense_payment_forecast", {
                                                            initialValue: this.state.expense_payment_forecast,
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: "Ops! Esse campo é obrigatório"
                                                                }
                                                            ]
                                                        })(<DatePicker
                                                            id="expense_payment_forecast"
                                                            disabled={isExpenseBlocked}
                                                            format="DD/MM/YYYY"
                                                            placeholder="Quando você pretende pagar essa conta?" />)}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <div>
                                                <PaymentsTable
                                                    header="Pagamentos realizados"
                                                    pagination={false}
                                                    dataSource={this.state.payments}
                                                    bankAccounts={this.state.bankAccounts}
                                                    isExpenseBlocked={isExpenseBlocked}
                                                    packId={packId}
                                                    metas={this.state.metas}
                                                    onDataChanged={onPaymentsChanged}
                                                    rowKey="expense_payment_id"
                                                    size="small"
                                                />
                                            </div>
                                        </Card>
                                    </TabPane>

                                    <TabPane tab="Informações do Documento" key="2">
                                        <Card title="Informações do Documento">
                                            <Row gutter={[12, 12]}>
                                                <Col lg={6}>
                                                    <Form.Item label="Data Emissão" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("expense_invoice_issued_at", {
                                                            initialValue: this.state.expense_invoice_issued_at,
                                                            rules: []
                                                        })(<DatePicker
                                                            id="expense_invoice_issued_at"
                                                            disabled={isExpenseBlocked}
                                                            format="DD/MM/YYYY"
                                                            placeholder="Quando essa nota foi emitida?" />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Item label="Mês Referência" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("expense_ref_month", {
                                                            initialValue: this.state.expense_ref_month,
                                                            rules: []
                                                        })(<MonthPicker
                                                            id="expense_ref_month"
                                                            disabled={isExpenseBlocked}
                                                            format="MM/YYYY"
                                                            placeholder="Qual o mês de referência dessa nota? (Usado para PTA e Cessão de Uso)" />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={4}>
                                                    <Form.Item label="Número da Nota" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("expense_invoice", {
                                                            initialValue: this.state.data.expense_invoice
                                                        })(<Input
                                                            disabled={isExpenseBlocked}
                                                            id="expense_invoice"
                                                            placeholder="" />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={8}>
                                                    <Form.Item label="Chave NF-e" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("expense_invoice_nfe", {
                                                            initialValue: this.state.data.expense_invoice_nfe
                                                        })(<Input
                                                            disabled={isExpenseBlocked}
                                                            id="expense_invoice_nfe"
                                                            placeholder="" />)}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[12, 12]}>
                                                <Col lg={12}>
                                                    <Form.Item label="Tipo Documento" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("expense_document_type", {
                                                        })(
                                                            <Select
                                                                disabled={isExpenseBlocked}
                                                                showArrow={true}
                                                                loading={this.state.isFetchingDocuments}
                                                            >
                                                                {this.state.documentList.map(d => (
                                                                    <Select.Option key={d.document_id} value={d.document_id}>
                                                                        {d.document_name}
                                                                    </Select.Option>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={12}>
                                                    <Form.Item label="Número do Documento" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("expense_document_nro", {
                                                            initialValue: this.state.data.expense_document_nro
                                                        })(<Input
                                                            disabled={isExpenseBlocked}
                                                            id="expense_document_nro"
                                                            placeholder="" />)}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>

                                    <TabPane tab="Anexos" key="3">
                                        <Card title="Anexar Arquivos">
                                            <Row gutter={[12, 12]}>
                                                <Col lg={24}>
                                                    <Form.Item label="Anexo">
                                                        <Upload
                                                            id="upload-field"
                                                            multiple={true}
                                                            customRequest={this.customRequest}
                                                            defaultFileList={attachments}
                                                            onRemove={this.onAttachmentRemove}
                                                            disabled={isExpenseBlocked}
                                                            showUploadList={{
                                                                showRemoveIcon: !isExpenseBlocked,
                                                                removeIcon: !isExpenseBlocked
                                                            }}
                                                        >
                                                            <Button disabled={isExpenseBlocked}>
                                                                <Icon type="upload" /> Enviar anexos
                                                            </Button>
                                                        </Upload>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>

                                    {false && this.state.data.expense_id && <TabPane tab="Mais informações" key="4">
                                        <Card>
                                            <Descriptions title="Mais informações" layout="vertical" size="small" column={2} >
                                                <Descriptions.Item label="Criado por">
                                                    {this.state.data.expense_ || ''}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Alterado por">
                                                    {this.state.data.expense_ || ''}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Data criação">
                                                    {this.state.data.expense_ || ''}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Data alteração">
                                                    {this.state.data.expense_ || ''}
                                                </Descriptions.Item>

                                            </Descriptions>
                                        </Card>
                                    </TabPane>}
                                </Tabs>
                            </Col>
                        </Row>
                        {!isExpenseBlocked && <Form.Item {...config.TAIL_FORM_ITEM_LAYOUT}>
                            {typeof this.state.data.expense_id !== "undefined" && (
                                <Popconfirm
                                    title="Tem certeza que deseja excluir?"
                                    onConfirm={this.handleDelete}
                                    okText="Sim"
                                    cancelText="Não"
                                    className="up-right-button"
                                >
                                    <Button type="danger" ghost loading={this.state.isDeleting}>
                                        Excluir
                                    </Button>
                                </Popconfirm>
                            )}
                            <LoaderButton
                                type="primary"
                                htmlType="submit"
                                loading={this.state.isLoading}
                                text="Salvar"
                                loadingText="Salvando..."
                            />
                        </Form.Item>}
                    </Form>
                )
                }
            </div>
        )
    }
}

export default class ExpensesForm extends Component {
    MainForm = Form.create({ name: "form" })(ExpensesFormComponent);

    render() {
        const childProps = {
            // isAuthenticated: this.state.isAuthenticated,
            // isAppLoading: this.state.isAppLoading,
            match: this.props.match,
            history: this.props.history,
            packList: this.props.packList,
            appSetTitle: this.props.appSetTitle
        };

        return <this.MainForm {...childProps} />;
    }
}

// import { Auth } from "aws-amplify";
import React, { Component } from "react";
// import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import "./TermsOfUse.css";
import { Form, Card } from 'antd';
import { EIAPIHelper } from "../libs/ei-helpers";

class TermsOfUseForm extends React.Component {
  state = {
    isLoading: true,
    entity: {}
  }

  constructor(props) {
    super(props);

    this.props.appSetTitle("Termos e Condições de Uso");
  }

  async componentDidMount() {
    const EIAPI = new EIAPIHelper()

    const entity = await EIAPI.get(`/entity`)

    this.setState({
      isLoading: false,
      entity
    });
  }

  render() {

    const entity = this.state.entity

    return !this.state.isLoading && (
      <div className="TermsOfUse" style={{ marginBottom: 30 }}>
        <Card>
          <h2>TERMOS E CONDIÇÕES DE USO DO SISTEMA DE PRESTAÇÃO DE CONTAS ENTIDADE INTEGRADA</h2>

          <p>Pelo presente <b>CONTRATO DE USO DO SISTEMA</b> (doravante "Contrato"), de um lado:</p>
          <p><b>METAURO AUTOMACAO COMERCIAL LTDA</b>, pessoa jurídica de direito privado, com sede na PC LUCINDA BRASI BRANDAO nº 60, LOTEAMENTO INOCOOP, Mogi Mirim, SP, Brasil, CEP 13806-516, inscrita no CNPJ/MF sob o no 10.754.453/0001-67, doravante denominada <b>METAURO</b> e <b>{entity.person_name}</b>, pessoa jurídica de direito privado, com sede na {entity.person_address.address_street}, {entity.person_address.address_number} - {entity.person_address.address_city}/{entity.person_address.address_state}, inscrita no CNPJ/MF sob o no {entity.person_document}, doravante denominada <b>Entidade</b>.</p>

          <p>METAURO e Entidade, quando referidas em conjunto, denominadas "Partes" e individualmente "Parte".</p>

          <p>Os serviços do Aplicativo ENTIDADE INTEGRADA ("Entidade Integrada") são fornecidos por METAURO.</p>
          <p>Ao usar os serviços ENTIDADE INTEGRADA, você concorda com as presentes condições gerais de uso do SISTEMA DE PRESTAÇÃO DE CONTAS ("Condições Gerais"). Por isso, é impositiva a leitura dessas Condições Gerais, com atenção.</p>
          <p>Produtos e Serviços disponibilizados dentro do SISTEMA DE PRESTAÇÃO DE CONTAS ENTIDADE serão regidos por essas Condições Gerais, mas poderão se aplicar termos ou exigências adicionais a determinados Produtos e/ou Serviços. Os termos adicionais, se aplicáveis, estarão disponíveis com os Produtos e/ou Serviços, e se incorporarão a essas Condições Gerais, caso você utilize esses Produtos e/ou Serviços.</p>

          <h3>Objeto:</h3>
          <div>
            <p>Estas Condições Gerais têm por objeto regular o seu acesso ao SISTEMA DE PRESTAÇÃO DE CONTAS ENTIDADE ("Aplicativo"), disponibilizado pela METAURO, através da Internet, primordialmente pelo endereço www.entidadeintegrada.com.br, a este não se limitando, bem como estabelecer condições relativas à licença de uso de programas de computador disponibilizados no portal ("Produtos"), de titularidade da METAURO ou de terceiros, sem prejuízo de eventuais termos adicionais, aplicáveis a alguns Produtos e/ou Serviços, que se incorporarão a essas Condições Gerais.</p>
          </div>

          <h3>Condições de Acesso:</h3>
          <div>
            <p>O acesso ao Aplicativo é privado e pago, será feito através do endereço www.entidadeintegrada.com.br, ou outro indicado pela Entidade Integrada, a seu critério.</p>
            <p>À Entidade Integrada fica reservado o direito de, a qualquer momento e a seu exclusivo critério, modificar as condições de acesso ao Aplicativo, alterando seu design e/ou sua forma de apresentação, incluindo ou suprimindo funcionalidades e/ou informações, ou realizando qualquer outra alteração, de qualquer ordem, sem que tal fato resulte em qualquer direito da Entidade reclamar perdas e danos, a qualquer título.</p>
          </div>

          <h3>Sua Conta no Aplicativo:</h3>
          <div>
            <p>Os dados para a criação da conta para acesso ao Aplicativo devem ser dados verdadeiros e precisos, ficando responsável pelo sigilo de seu nome de usuário e senha, que são pessoais e intransferíveis.</p>
            <p>Qualquer atividade a partir da conta da Entidade no Aplicativo, mediante a utilização de suas credenciais de acesso (nome de usuário e senha), ainda que indevida, será de responsabilidade da Entidade.</p>
          </div>

          <h3>Licenças de Uso de Programas de Computador:</h3>
          <div>
            <p>Os programas de computador fornecidos no Aplicativo, mediante pagamento, são licenciados aa Entidade de forma não exclusiva, intransferível, e temporária, observadas as seguintes condições:</p>
            <p>A. O Aplicativo será acessível on-line, via Internet, sujeitos às disposições destas Condições Gerais.</p>
            <p>B. Licenças de uso do Aplicativo concedidas gratuitamente, ou por período de testes gratuitos, terão validade pelo período determinado pelo titular dos direitos autorais do programa de computador ("Licenciante"), que poderá ser reduzido ou estendido, a qualquer tempo e a critério do Licenciante, e independentemente de prévio aviso.</p>
            <p>C. Licenças de uso do Aplicativo concedida gratuitamente, ou por período de testes gratuitos poderão ter limitações em relação à versão paga, a critério do Licenciante, e não contarão com qualquer tipo de suporte ou garantia, implícita ou explícita, a qualquer título, inclusive de nível de serviço.</p>
            <p>D. Licenças de uso do Aplicativo em fase de pré-lançamento não contarão com qualquer tipo de suporte ou garantia, implícita ou explícita, a qualquer título, inclusive de nível de serviço.</p>
            <p>E. Ao final do período de qualquer licença de uso do Aplicativo concedida por período de testes gratuitos, a Entidade deverá manifestar, expressamente, seu eventual interesse na contratação do Aplicativo testado, sob pena de ter seu acesso ao Aplicativo interrompido, automaticamente, ao final do período de testes, ficando cientes que todos os dados da Entidade, inseridos por ele no Aplicativo, durante os testes, serão automaticamente apagados.</p>
            <p>F. O Aplicativo licenciado deverá ser utilizado somente para as operações da Entidade, e por prepostos a serviço da Entidade.</p>
            <p>G. Somente usuários autorizados poderão fazer uso dos Aplicativos licenciados, e somente usuários administradores poderão adicionar ou excluir usuários autorizados, bem como alterar a contratação do Aplicativo, com a inclusão/exclusão de novos usuários, módulos, ou qualquer outra característica disponível.</p>
            <p>H. Todas as licenças de uso comercializadas através do Aplicativo são de caráter temporário, válidas pelo período pago pela Entidade, e o não pagamento de qualquer novo período ensejará o cancelamento imediato do acesso ao programa de computador licenciado, que somente será reestabelecido mediante a quitação do débito.</p>
            <p>I. Talvez seja necessário que a Entidade instale um software cliente para acessar e usar o Aplicativo licenciado, especialmente se tratando de dispositivos móveis. Nessa hipótese, os Usuários deverão observar os termos destas Condições Gerais também no uso do software cliente, sem prejuízo de observar condições específicas de licenciamento do referido software cliente.</p>
            <p>J. Ao adquirir qualquer licença de uso do Aplicativo, e fornecer seus dados de cobrança e/ou cartão de crédito aos parceiros de intermediação financeira da Entidade Integrada, a Entidade autoriza a cobrança recorrente pela licença, sempre que vencido o período temporário de validade da licença. A cobrança seguirá os valores ajustados, e aceitos pela Entidade no ato da contratação da licença de uso do Aplicativo, comprometendo-se a Entidade, assim, ao pagamento do valor periódico da licença de uso do Aplicativo.</p>
            <p>K. O Aplicativo licenciado será constantemente atualizado, de forma automática, sempre que houver lançamento de nova versão, podendo, assim, ocorrer, a qualquer tempo, mudanças no Aplicativo licenciado, em decorrência de atualizações de versão, com o que consente a Entidade, ao contratar a licença de qualquer Aplicativo.</p>
            <p>L. Sem prejuízo de modificações em decorrência de atualizações, a Entidade Integrada e/ou o Licenciante poderão, a qualquer tempo, alterar a forma de fornecimento de qualquer Aplicativo, a seu exclusivo critério, e independentemente de aviso prévio, com o que anui a Entidade. Adicionalmente, a Entidade Integrada e/ou o Licenciante poderão, a qualquer tempo, estabelecer limites no uso do Aplicativo, a seu exclusivo critério, que serão comunicados previamente aa Entidade.</p>
            <p>M. O Aplicativo é licenciado aa Entidade da forma como estão, não existindo opção de qualquer customização para as necessidades da Entidade, que deverá se assegurar de que o Aplicativo, da forma como está disponível, atende às suas necessidades, não podendo imputar qualquer responsabilidade à Entidade Integrada ou ao Licenciante, caso verifique, no futuro, que o Aplicativo licenciado não atende à totalidade de suas necessidades.</p>
            <p>N. Qualquer dano, a qualquer título, decorrente de mau uso, acidente, imperícia, imprudência ou negligência na operação, pela Entidade, dos programas de computador licenciados, é de exclusiva responsabilidade da Entidade, sem qualquer responsabilidade, solidária ou subsidiária, e a qualquer título, da Entidade Integrada ou do Licenciante.</p>
            <p>O. A Entidade Integrada e/ou o Licenciante não assumem qualquer responsabilidade por danos aa Entidade, decorrentes de tentativas de reparo ou alterações não autorizadas nos programas licenciados, invasões no sistema, vírus, e outros elementos nocivos.</p>
            <p>P. A Entidade Integrada e/ou o Licenciante não assumem qualquer responsabilidade pelas decisões da Entidade, baseadas no uso dos programas de computador licenciados.</p>
            <p>O Usuário declara estar ciente que pequenos defeitos de programação (bugs) são comuns a todos os programas de computador, isentando, assim, a Entidade Integrada e o Licenciante, de qualquer responsabilidade por danos decorrentes de tais bugs usuais, limitando a responsabilidade da Licenciante à correção desses pequenos defeitos de programação.</p>
            <p>Os direitos não expressamente garantidos aa Entidade, através destas Condições Gerais, são reservados à Entidade Integrada e/ou ao Licenciante do programa de computador adquirido pela Entidade.</p>
          </div>

          <h3>Garantias de Níveis de Serviço:</h3>
          <div>
            <p>Para o Aplicativo fornecido mediante pagamento, a Entidade Integrada garante aa Entidade a disponibilidade de 99,9% (noventa e nove virgula nove por cento) do tempo ao mês, calculada de acordo com a seguinte fórmula:</p>
            <p><b>PAM = (TMM – TMP)/TMM</b></p>
            <ul>
              <li>PAM = Porcentagem do tempo de atividade do mês</li>
              <li>TMM = Total de minutos no mês</li>
              <li>TMP = Total de minutos de paralisação no mês</li>
            </ul>
            <p>Caso a disponibilidade, em qualquer mês, seja inferior à garantida acima, a Entidade Integrada concorda em conceder aa Entidade os seguintes créditos:</p>

            <h4>Porcentagem de Atividade do Mês</h4>
            <p><b>Nível de Crédito</b></p>
            <ul>
              <li>&lt; 99,9%: 10% sobre o valor do mês.</li>
              <li>&lt; 99%: 20% sobre o valor do mês.</li>
              <li>&lt; 95%: 40% sobre o valor do mês.</li>
            </ul>
            <p>Os níveis de crédito descritos na tabela acima não são cumulativos, e representam o recurso exclusivo da Entidade, em caso de descumprimento dos níveis de serviço, até pela natureza do serviço, que poderá ser afetado por diversos fatores alheios à vontade da Entidade Integrada, conforme prevê o artigo 8º, do Código de Defesa do Consumidor. Fica ajustado, ainda, que o crédito concedido jamais excederá, em nenhuma circunstância, o valor mensal pago pela Entidade pelo Aplicativo afetado pelo descumprimento do nível de serviço.</p>
            <p>Para fazer jus aos créditos previstos acima, a Entidade deverá enviar solicitação formal à Entidade Integrada, indicando a data e hora da(s) ocorrência(s) que importou(aram) em descumprimento do nível de serviço. Dentro de 10 (dez) dias após a solicitação da Entidade, a Entidade Integrada averiguará a eventual procedência da solicitação, e, se procedente, informará aa Entidade o nível de crédito, que será calculado sobre o valor do mês em que ocorreu o descumprimento do nível de serviço, e descontado da fatura com vencimento no mês subsequente.</p>
            <p>Não serão computados como minutos de paralisação no mês, para efeito de cálculo da porcentagem do tempo de atividade do mês, as paradas decorrentes de manutenção preventiva, ou de qualquer ocorrência alheia ao controle e diligência da Entidade Integrada, tais como falta de energia fornecida pela concessionária, falhas nas operadoras interconectadas à rede, eventos de força maior e caso fortuito, entre outros, e estes não se limitando.</p>
            <p>Serviços de suporte técnico serão fornecidos pela Entidade Integrada através dos meios divulgados em https://www.entidadeintegrada.com.br/ajuda/, de segunda a sexta-feira, das 8:00 às 18:00 horas, com tempo de resposta de até 24 (vinte e quatro) horas, não havendo quaisquer serviços de suporte técnico nas dependências da Entidade inclusos.</p>
          </div>

          <h3>Privacidade:</h3>
          <div>
            <p>A Entidade Integrada se compromete a manter em sigilo os dados que forem inseridos pela Entidade, em áreas restritas do Aplicativo, abstendo-se de divulgá-los a terceiros.</p>
            <p>Observado o sigilo descrito acima, a Entidade autoriza à Entidade Integrada coletar determinadas informações relacionadas aa Entidade e aos dados por ele inseridos durante a utilização de Aplicativos licenciados, através de ferramentas e rotinas automatizadas, e sem intervenção humana, com objetivo de traçar perfil da Entidade e/ou integração com outros sistemas ("Perfil da Entidade").</p>
            <p>O Usuário consente com o recebimento de ofertas da Entidade Integrada e de Parceiros, durante o uso do Aplicativo, baseadas no Perfil da Entidade, previamente coletado pela Entidade Integrada, declarando que tal fato não resulta em qualquer violação de sua privacidade.</p>
            <p>Em virtude da natureza do Aplicativo, dos Serviços, e dos Produtos, a Entidade Integrada terá de notificar a Entidade periodicamente, sobre anúncios importantes relativos à operação do Aplicativo. Como uma condição do Aplicativo, dos Serviços, e dos Produtos, a Entidade poderá receber, periodicamente, comunicações de marketing, e outros tipos de comunicações, relacionadas ao Aplicativo, seus Serviços e Produtos.</p>
          </div>

          <h3>Limitação e Isenção de Responsabilidade:</h3>
          <div>
            <p>É de integral responsabilidade da Entidade a manutenção de contrato com qualquer provedor de serviços de Internet, para o fornecimento dos recursos mínimos necessários ao acesso ao Aplicativo, e aos Produtos e Serviços contratados, sendo igualmente de responsabilidade da Entidade a aquisição e manutenção de equipamentos que atendam aos requisitos mínimos necessários ao acesso ao Aplicativo, e aos Produtos e Serviços Contratados.</p>
            <p>A Entidade Integrada não se responsabiliza por perdas e danos causados pela utilização irregular do Aplicativo, seus Produtos e Serviços, ou pela utilização inadequada dos equipamentos da Entidade, nem por falhas na instalação elétrica da Entidade, que deverá ser adequada aos equipamentos utilizados para acesso ao Aplicativo, excluindo de sua responsabilidade, ainda, a ocorrência de efeitos de caso fortuito e força maior.</p>
            <p>É de responsabilidade exclusiva da Entidade verificar a adequação do(s) programa(s) de computador licenciado(s) ao seu ramo de atividade, notadamente às regras tributárias e fiscais, municipais, estaduais e federais aplicáveis aa Entidade, uma vez que o(s) programa(s) de computador licenciado(s) é(são) fornecido(s) sem qualquer customização ou parametrização para a localidade e o ramo de atividade específico da Entidade, não havendo garantias a qualquer título, implícitas ou explícitas, da Entidade Integrada e/ou do Licenciante, quanto à adequação do(s) programa(s) de computador à legislação vigente e aplicável à atividade da Entidade.</p>
            <p>Também não são de responsabilidade da Entidade Integrada quaisquer perdas e danos suportados pela Entidade em função de problemas software, hardware, sistemas, e aplicativos da Entidade, fornecidos por terceiros, bem como perdas de dados, invasões, vírus, e qualquer outro evento que fuja ao controle e diligência da Entidade Integrada.</p>
            <p>O backup realizado pela Entidade Integrada possui a finalidade exclusiva de garantir a restauração dos serviços em caso de pane nos sistemas de armazenamento, não permitindo restauração de dados danificados por falhas no uso do Produto licenciado. Entretanto, uma cópia de segurança poderá ser baixada pelo usuário nos formatos disponibilizados pelo Aplicativo licenciado.</p>
            <p>Considerando que os termos destas Condições Gerais, e os exibidos no Aplicativo ou em condições específicas para aquisição de Produtos e/ou Serviços disponibilizados no Aplicativo alocam os riscos entre a Entidade Integrada e a Entidade, e que os preços dos Produtos e Serviços disponibilizados refletem esta alocação de riscos, acordam as partes que, em qualquer hipótese, qualquer indenização decorrente dos Produtos e Serviços disponibilizados pela Entidade Integrada e seu representante, por danos diretos ou indiretos, se e quando existir alguma responsabilidade, ficará limitada ao valor total pago pela Entidade à Entidade Integrada e seus representantes, desde o início da contratação de qualquer Produto ou Serviço no Aplicativo, até a data do prejuízo, ainda que eventual prejuízo exceda tal valor.</p>
            <p>O Usuário reconhece que a Entidade Integrada não controla nem aprova conteúdo que o usuário adiciona aos Produtos por ele utilizados, que será de responsabilidade integral da Entidade, sem qualquer responsabilidade da Entidade Integrada e seus representantes.</p>
          </div>

          <h3>Propriedade Intelectual e Industrial:</h3>
          <div>
            <p>Pertencem exclusivamente aos titulares dos Aplicativos os direitos de propriedade industrial e intelectual, cujo uso será permitido aa Entidade, de acordo com os termos destas Condições Gerais.</p>
            <p>O Usuário não poderá ter acesso aos códigos fonte do Aplicativo, nem a camadas fechadas do Aplicativo, ficando ciente que a tentativa de acesso a esses itens, ou o efetivo acesso a esses itens, por qualquer meio, constituirá violação de direitos autorais do titular dos direitos sobre o Aplicativo, sujeitando a Entidade às sanções previstas em Lei.</p>
            <p>O Usuário não poderá, direta ou indiretamente, inclusive através de atos de seus prepostos, copiar, modificar, transferir, sublicenciar, ceder, vender, dar em locação, dar em garantia, doar, ou alienar a terceiros o Aplicativo, sob qualquer forma ou modalidade, gratuita ou onerosa, provisória ou permanente, no todo ou em parte, nem submetê-los a processos de engenharia reversa ou, ainda, traduzi-lo ou decodificá-lo, no todo ou em parte. As mesmas vedações aplicam-se a quaisquer documentações ou informações relativas ao Aplicativo.</p>
            <p>A Entidade Integrada pode citar o nome e marca da Entidade, como fonte de referência e/ou usuário do Aplicativo, seus Serviços e Produtos, inclusive em sua publicidade, independentemente de qualquer pagamento adicional, com o que consente a Entidade.</p>
            <p>O Usuário se compromete a não utilizar as especificações do Aplicativo, ou permitir que terceiros as utilizem, com vistas a criar outros de mesma destinação.</p>
            <p>A Entidade Integrada não garante que o Aplicativo operará em combinações com outras, que não aquelas para as quais foram especificados o Aplicativo, e que sua operação será ininterrupta ou livre de erros.</p>
            <p>A responsabilidade da Entidade Integrada e/ou dos Representantes, em relação ao Aplicativo, ficará limitada à correção dos eventuais erros encontrados no Aplicativo, assegurando aa Entidade a devolução dos valores pagos, caso a Entidade Integrada e/ou o Representantes não possam fazer com que o Aplicativo opere conforme garantido.</p>
            <p>Os nomes, marcas e logotipos da Entidade Integrada, do Licenciante, ou de qualquer outro terceiros, contidas no Aplicativo, e qualquer documentação que os acompanhe, não poderão ser adulterados ou modificados, bem como não poderão ser objeto de venda, licenciamento, doação, locação ou qualquer forma de transferência ou transmissão, onerosa ou gratuita, permanente ou temporária.</p>
          </div>

          <h3>Custos e prazos contratuais:</h3>
          <div>
            <p>Não há custo de ativação e o custo mensal com impostos é de R$ 500,00.</p>
            <p>A relação entre a METAURO e a Entidade, nos termos destas Condições Gerais, e de eventuais outras condições adicionais que a ela possam se acrescer, será renovado automaticamente a cada 12 meses, por um período de até 5 anos, podendo ser interrompida a qualquer tempo.</p>
            <p>A simples desistência de uso pela Entidade não gera o cancelamento ou a rescisão do seu contrato com a Entidade Integrada/Representante.</p>
            <p>Não havendo interesse do usuário em continuar a utilizar quaisquer Serviços e Produtos disponibilizados no Aplicativo, deverá a Entidade formalizar, expressamente, sua intenção de cancelamento do Serviço ou Produto, mediante acesso à área restrita do Aplicativo, com a utilização de seu nome de usuário e senha, onde deverá realizar a solicitação de cancelamento do Serviço e/ou Produto, através de opção própria para cancelamento.</p>
            <p>O cancelamento se tornará efetivo na data em que se encerrar o ciclo temporário de validade da licença de uso em vigor, já pago anteriormente pela Entidade, que valerá como aviso prévio da Entidade.</p>
            <p>Dentro desse período, a Entidade poderá utilizar livremente os Serviços e/ou Produtos contratados, que permanecerão disponíveis.</p>
            <p>Caso a Entidade Integrada, e/ou o Representante de qualquer Produto ou Serviço disponível através do Aplicativo, resolva por descontinuar qualquer Produto ou Serviço, tal decisão será informada aa Entidade com antecedência de 90 (noventa) dias, que valerá como aviso prévio, durante o qual a Entidade poderá utilizar livremente o Produto ou Serviço em processo de descontinuidade, mediante o pagamento dos valores correspondentes.</p>
            <p>Em qualquer hipótese de rescisão, solicitada por qualquer das partes, não incidirão quaisquer cláusulas penais, ou dever de indenizar, ficando os ônus da parte que solicitou a rescisão limitados ao aviso prévio previsto nos parágrafos antecedentes.</p>
            <p>No caso de qualquer descumprimento destas Condições Gerais, ou de quaisquer outras condições associadas a qualquer Produto ou Serviço disponibilizado pelo Aplicativo, o acesso da Entidade também poderá ser interrompido, com a rescisão dessas Condições Gerais e de qualquer outro contrato com a Entidade, mediante aviso prévio de 48 (quarenta e oito) horas.</p>
            <p>Durante o período de aviso prévio, a Entidade deverá efetuar o download de todos os seus dados, que eventualmente estejam armazenados nos Produtos ou Serviços em período de aviso prévio, visando criar uma cópia de segurança desses dados, pois a Entidade Integrada e poderá apagar todo e qualquer dado da Entidade que esteja armazenado em seus servidores, imediatamente após o encerramento do aviso prévio, e suspensão do acesso aa Entidade, sendo tais dados irrecuperáveis, após a remoção realizada, o que declara estar ciente o usuário.</p>
            <p>O download destes dados poderá ser realizado dentro da área restrita do Aplicativo, mediante acesso da área de acesso para backup, e estará disponível nos formatos definidos pelo Programa, que podem ser consultados na mesma área.</p>
            <p>No caso de ser negado o pagamento recorrente de qualquer novo período de licença dos Produtos fornecidos mediante pagamento no Aplicativo, o acesso a esses produtos será imediatamente suspenso, sendo que, não havendo regularização dos pagamentos pela Entidade, após o prazo de 15 (dias) dias, o acesso ao Produto não pago será automaticamente cancelado, com a rescisão da contratação, e consequente deleção de todos os dados eventualmente inseridos pela Entidade no Produto, sem que a Entidade tenha direito a qualquer indenização, a qualquer título.</p>
            <p>O Usuário reconhece e declara que, salvo o expressamente descrito nessas Condições Gerais, a Entidade Integrada e seus Representantes não terão obrigação de continuar a manter, exportar ou devolver os dados da Entidade, após expirado o aviso prévio de qualquer rescisão contratual, por qualquer motivo, reconhecendo a Entidade que a Entidade Integrada não terá nenhuma obrigação, de qualquer natureza, pela exclusão de dados da Entidade, de acordo com esses termos.</p>
          </div>

          <h3>Correção Monetária e Enquadramento Fiscal:</h3>
          <div>
            Os preços pagos pela Entidade por eventuais Serviços fornecidos mediante pagamento, serão corrigidos monetariamente, a cada 12 (doze) meses, ou na menor periodicidade que venha a ser permitida pela legislação em vigor, com base na <p>variação positiva do IGPM/FGV, ou outro índice que venha a substituí-lo, no caso de sua extinção.  </p>
            <p>Os preços pagos pela Entidade por eventuais Serviços fornecidos mediante pagamento, serão reavaliados e enquadrados conforme tabela de preços vigente a cada 12 (doze) meses, com base no enquadramento fiscal da empresa da Entidade."</p>
          </div>

          <h3>Disposições Finais:</h3>
          <div>
            <p>É vedado aa Entidade ceder ou transferir a terceiros os direitos e obrigações decorrentes do presente instrumento, total ou parcialmente, salvo se com a concordância prévia e expressa da Entidade Integrada.</p>
            <p>Todas as comunicações entre as partes poderão ser realizadas por carta, fax, ou e-mail, o que as partes declaram aceitar, neste ato, ficando cientes que a validade jurídica de todas as comunicações fica condicionada à existência de protocolo, ou aviso de recebimento, sob pena de serem tidas como não entregues.</p>
            <p>No caso de um tribunal ou juízo competente julgar ilegal, nula ou ineficaz qualquer estipulação destas Condições Gerais, as estipulações restantes permanecerão em plena força e vigor. Caso a estipulação ou estipulações julgadas ilegais, nulas ou ineficazes sejam de ordem a afetar substantivamente o equilíbrio das partes perante o presente acordo, elas deverão negociar, de boa fé, uma alternativa que, não contendo os vícios da estipulação ou estipulações invalidadas, reflitam, na maior extensão possível, as suas intenções originárias.</p>
            <p>A tolerância de uma parte para com a outra, relativamente a descumprimento de qualquer das obrigações assumidas, não será considerada novação ou renúncia a qualquer direito, constituindo mera liberalidade, que não impedirá a parte tolerante de exigir da outra o fiel cumprimento destas Condições Gerais, a qualquer tempo.</p>
            <p>No caso de uso de Produtos fora do território brasileiro, a Entidade concorda em cumprir, de forma integral, todas as Leis e Regulamentos de exportação do Brasil, e dos países de destino dos Produtos em eventual utilização, que é restrita a empresas do mesmo grupo econômico da Entidade, alocadas fora do país, e mediante prévia e expressa autorização da Entidade Integrada, para assegurar que nem os Programas, nem qualquer outro produto deles diretamente decorrente (1) sejam exportados, direta ou indiretamente, em violação às Leis de Exportação; ou (2) sejam obtidos com a intenção de serem usados para quaisquer propósitos proibidos pelas Leis de Exportação.</p>
            <p>Eventuais atualizações destas Condições Gerais serão publicadas no Aplicativo, e submetidas à aceitação da Entidade, que, se não concordar com as alterações, terá a faculdade de dar por rescindido o contrato, sem quaisquer ônus, cessando seu acesso ao Aplicativo, bem como aos Serviços e Produtos disponibilizados pela Entidade Integrada.</p>
            <p>As partes exercem a sua liberdade de contratar, observados os preceitos de ordem pública e o princípio da função social do presente contrato, que atende também aos princípios da economicidade, razoabilidade e oportunidade, permitindo o alcance dos respectivos objetivos societários das partes e atividades empresariais, servindo, consequentemente, a toda a sociedade.</p>
            <p>As partes declaram que sempre guardarão, na execução destas Condições Gerais, os princípios da probidade e da boa-fé, presentes também, tanto na sua negociação, quanto na sua celebração.</p>

          </div>

          <h3>Legislação Aplicável, Foro e Encerramento:</h3>
          <div>
            <p>Estas Condições Gerais serão regidas e interpretadas de acordo com as Leis vigentes na República Federativa do Brasil.</p>
            <p>Este contrato tem valor legal e aceite automático a partir do momento do cadastro e ativação do Aplicativo para a Entidade, não sendo necessária a assinatura em papel.</p>
            <p>Elegem as partes o Foro da Comarca de São Paulo, como competente para dirimir qualquer dúvida ou controvérsia decorrente das presentes Condições Gerais, renunciando a qualquer outro, por mais privilegiado que seja, ou venha a ser.</p>
          </div>
        </Card>
      </div>
    )
  }
}

export default class TermsOfUse extends Component {
  MainForm = Form.create({ name: "form" })(TermsOfUseForm);

  render() {
    const childProps = {
      appSetTitle: this.props.appSetTitle
    };

    return <this.MainForm {...childProps} />;
  }
}

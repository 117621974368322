import React, { Component } from "react";
import {
    Form,
    Tooltip,
    Input,
    InputNumber,
    DatePicker,
    Button,
    Popconfirm,
    Upload,
    Radio,
    Spin,
    Select,
    Icon,
    Card,
    message,
    Tabs,
    Tag,
    Switch,
    Row, Col
} from "antd";
// import MaskedInput from 'antd-mask-input'
import LoaderButton from "../../components/LoaderButton";
import InputCurrency from "../../components/InputCurrency";
import { dealWithError, parsePhoneNumber, parseFloat, getTaxIRRF, getTaxINSS, PersonDocument, EIAPIHelper, showMessageError} from "../../libs/ei-helpers";

import _ from 'loadsh'
import { s3Upload, s3GetDownloadURL } from "../../libs/awsLib";
import config from "../../config";

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const { TextArea } = Input
const { TabPane } = Tabs
const { CheckableTag } = Tag

const EIAPI = new EIAPIHelper()

const tagsFromServer = ['Associado', 'Cliente', 'Fornecedor', 'Funcionário'];

class HotTags extends React.Component {
    state = {
    }

    constructor(props) {
        super(props)
        this.state.selectedTags = props.value || []
    }

    handleChange(tag, checked) {
        const { selectedTags } = this.state;
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        this.setState({ selectedTags: nextSelectedTags })

        if (typeof this.props.onChange)
            this.props.onChange(nextSelectedTags)
    }

    render() {
        const { selectedTags } = this.state;

        return (
            <div>
                {tagsFromServer.map(tag => (
                    <CheckableTag
                        key={tag}
                        checked={selectedTags.indexOf(tag) > -1}
                        className={`ei-tag ei-tag-${tag} ei-tag-${selectedTags.indexOf(tag) > -1 ? 'checked' : 'unchecked'}`}
                        onChange={checked => this.handleChange(tag, checked)}
                    >
                        {tag}
                    </CheckableTag>
                ))}
            </div>
        );
    }
}

class PersonsFormComponent extends React.Component {
    _isMounted = false;
    params = {};
    state = {
        isLoading: false,
        isDataLoading: false,

        person_type: "LEGAL",
        data: {
            person_document: "",
            person_address: {
                address_id: "",
                person_address_id: ""
            }
        },
        person_phones: [],

        attachments: [],
        newAttachments: []
    }

    constructor(props) {
        super(props);

        this.params = this.props.match.params;

        this.props.appSetTitle("Cadastro de Pessoas");
    }

    async componentDidMount() {
        this._isMounted = true;

        try {
            await this.getData()
        } catch (e) {
            dealWithError("componentDidMount", e)
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getData() {
        const id = typeof this.params.id !== "undefined" ? this.params.id : "new"
        let data = {}

        this.setState({ isLoading: true })

        if (id !== "new")
            data = await EIAPI.get(`/persons/${id}`)

        if (this._isMounted) {
            // const { person_type, person_document } = data;
            const attachments = await s3GetDownloadURL(data.attachments || []);

            data.person_employee_hired_at = data.person_employee_hired_at !== null && data.person_employee_hired_at !== '0000-00-00 00:00:00' ? moment.utc(data.person_employee_hired_at) : ''
            data.person_employee_turnoffed_at = data.person_employee_turnoffed_at !== null && data.person_employee_turnoffed_at !== '0000-00-00' ? moment.utc(data.person_employee_turnoffed_at) : null
            data.person_info_open_date = data.person_info_open_date !== null && data.person_info_open_date !== '0000-00-00 00:00:00' ? moment.utc(data.person_info_open_date) : ''
            data.person_info_status_date = data.person_info_status_date !== null && data.person_info_status_date !== '0000-00-00 00:00:00' ? moment.utc(data.person_info_status_date) : ''
            data.person_member_since = data.person_member_since !== null && data.person_member_since !== '0000-00-00 00:00:00' ? moment.utc(data.person_member_since) : ''
            data.person_member_graduated_at = data.person_member_graduated_at !== null && data.person_member_graduated_at !== '0000-00-00' ? moment.utc(data.person_member_graduated_at) : ''

            this.setState({
                'person_type': data.person_type,
                'person_address_id': _.isObjectLike(data.person_address) ? data.person_address.person_address_id : undefined,
                'address_id': _.isObjectLike(data.person_address) ? data.person_address.address_id : undefined,
                'person_phones': data.person_phones,
                data,
                attachments
            })

            this.props.form.setFieldsValue({
                'person_tags': this.state.data.person_tags
            })

            this.setState({ isLoading: false })
            this.updateData()

            this.doSalaryCalc()
        }
    }

    updateData(objKeys) {
        const keys = objKeys || this.props.form.getFieldsValue()
        Object.keys(keys).forEach(key => {
            if (key !== 'key' && key !== 'keys' && key !== 'phone_keys') {
                const obj = {
                    [key]: this.state.data[key]
                }
                this.props.form.setFieldsValue(obj)
            }
        })
    }

    async saveItem(data) {
        const id = typeof this.params.id !== "undefined" ? this.params.id : "new"
        const phones = []
        if (typeof data.person_phones !== 'undefined')
            data.person_phones.forEach((el, i) => {
                phones.push(el)
            })

        const numberFields = [
            'person_employee_salary',
            'person_employee_salary_discounts',
            'person_employee_inss',
            'person_employee_irrf',
            'person_employee_salary_final',
            'person_info_capital',
        ]

        numberFields.forEach((fieldKey) => {
            data[fieldKey] = parseFloat(data[fieldKey])
        })

        const postData = _.merge(data, {
            person_id: this.state.data.person_id,
            person_type: this.state.person_type,
            person_phones: phones,
            person_tags: this.state.person_tags,
            person_address: {
                address_id: this.state.address_id,
                person_address_id: this.state.person_address_id
            },
            newAttachments: this.state.newAttachments
        })

        if (id !== "new")
            return await EIAPI.put(`/persons/${this.params.id}`, {
                body: postData
            })
        else
            return await EIAPI.post(`/persons/`, {
                body: postData
            })
    }

    async deleteItem() {
        return EIAPI.del(`/persons/${this.params.id}`)
    }

    onBlur = event => {
        // const { setFields } = this.props.form

        // const field_name = event.target.id || event.target.name
        // const field_value = event.target.value

        // if (field_name === "form_person_document") {
        //     const person_type = this.state.person_type //getFieldValue('form_person_type')
        //     const doc = new PersonDocument(field_value, person_type)
        //     if (doc.isValid()) {
        //         setFields({
        //             person_document: {
        //                 value: doc.format(),
        //                 errors: null
        //             }
        //         })
        //     }
        // }
    }

    handleSubmit = async event => {
        event.preventDefault()

        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (!err) {
                this.setState({ isLoading: true })

                try {
                    this.setState({ isLoading: false })
                    const res = await this.saveItem(values)
                    if (res.status !== 'success') {
                        if (res.code === 409)
                            throw new Error('Não foi possível salvar esse registro pois já existe uma pessoa cadastrada com esse mesmo documento')
                        else
                            throw new Error('Não foi possível salvar o registro :-(')
                    }

                    this.props.history.push(`/persons/`)
                } catch (e) {
                    dealWithError("Persons.handleSubmit", e)
                    this.setState({ isLoading: false })
                    this.props.history.push(`/persons/`)
                }
            }
        })

        return false
    }

    handleDelete = async event => {
        event.preventDefault();

        this.setState({ isDeleting: true });

        try {
            await this.deleteItem();
            this.props.history.push("/persons");
        } catch (e) {
            dealWithError('Persons.handleDelete', e)
            this.setState({ isDeleting: false })
        }
    }


    // handleDelete = async event => {
    // 	event.preventDefault()

    // 	this.setState({ isDeleting: true })

    // 	try {
    // 		await this.deleteNote()
    // 		this.props.history.push("/")
    // 	} catch (e) {
    // 		console.error(e)
    // 		message.error("Ops! Não foi possível excluir este item :-(")
    // 		this.setState({ isDeleting: false })
    // 	}
    // }

    onChange = event => {
        const field_name = event.target.id || event.target.name
        const field_value = event.target.value

        this.setState({
            [field_name]: field_value
        });
    }

    onDocumentChange = async (event) => {
        const field_name = event.target.id || event.target.name
        const field_value = event.target.value
        this.setState({
            data: { [field_name]: field_value }
        })

        const { setFieldsValue, getFieldValue } = this.props.form

        console.debug('checkDocument', field_value, this.state.data.person_document)

        const person_type = this.state.person_type
        const person_document = field_value || this.state.person_document
        const doc = new PersonDocument(person_document, person_type)

        if (doc.isValid()) {
            this.setState({ isDataLoading: true })

            if (person_document !== this.state.person_document) {
                const docInfo = await EIAPI.get(`/helpers/${doc.type().toLowerCase()}/${doc.digits}`)

                if (this._isMounted && docInfo.status === 'success') {
                    this.setState({ isDataLoading: false })

                    const currentEmail = (getFieldValue('person_email') || '').toLowerCase().replace(/,|;| /g, ',').replace(/,,+/g, ',').split(',')
                    const newEmail = (docInfo.email || '').replace(/,|;| /g, ',').toLowerCase().replace(/,,+/g, ',').split(',')
                    const emailList = _.filter(_.union(currentEmail, newEmail), _.negate(_.isEmpty))

                    // let cnaeSecundarios = (typeof typeof docInfo.atividades_secundarias !== 'undefined' ? typeof docInfo.atividades_secundarias : [])
                    let fieldValues = {
                        person_document: doc.format(),
                        person_name: docInfo.razao || docInfo.nome,
                        person_nicename: docInfo.nome || docInfo.razao,
                        person_email: emailList.join(', '),
                        person_address: {
                            // id: typeof this.state.address !== 'undefined' ? this.state.address.id : 'new',
                            address_street: docInfo.logradouro,
                            address_number: docInfo.numero,
                            address_complement: docInfo.complemento,
                            address_district: docInfo.bairro,
                            address_state: docInfo.uf,
                            address_city: docInfo.municipio,
                            address_zipcode: docInfo.cep,
                        },
                        person_info_cnae_principal: docInfo.cnae,
                        person_info_capital: docInfo.capital_social,
                        person_info_open_date: moment(docInfo.dataAbertura, 'DD/MM/YYYY'),
                        person_info_status_date: moment(docInfo.dataSituacao, 'DD/MM/YYYY'),
                        person_info_legal_origin: docInfo.natureza_juridica,
                        person_info_legal_status: docInfo.situacao,
                        person_info_cnae_others: (docInfo.atividades_secundarias || []).join('\n')
                    }

                    setFieldsValue(fieldValues)
                    this.setState({
                        data: fieldValues
                    })

                    // atividades_secundarias: Array [ "94.93-6-00 - Atividades de organizações associativas ligadas à cultura e à arte*", "94.99-5-00 - Atividades associativas não especificadas anteriormente*" ]
                    // efr: ""
                    // email: ""
                    // inscricao_estadual: ""
                    // motivo_situacao_cadastral: ""
                    // porte: null
                    // quadro_societario: Array [ {…} ]

                    if (typeof docInfo.fones !== 'undefined' && docInfo.fones !== null) {
                        const phoneList = this.state.person_phones || []
                        docInfo.fones.forEach(phoneNumber => {
                            phoneList.push(parsePhoneNumber(phoneNumber))
                        })
                        this.setState({
                            person_phones: phoneList
                        })
                    }
                }
                // this.updateData()
            }

            return true
        }

        showMessageError('Ops!', 'O documento informado não é válido..')
        return false
    }

    remove = i => {
        const { form } = this.props
        let keys = form.getFieldValue('phone_keys') || []
        delete keys[i]

        form.setFieldsValue({
            phone_keys: Object.values(keys)
        })
    }

    add = () => {
        const { form } = this.props
        const keys = form.getFieldValue('phone_keys') || []

        keys.push({
            phone_area_code: keys.length > 0 && typeof keys[keys.length - 1] !== 'undefined' ? keys[keys.length - 1].phone_area_code : '',
            phone_description: 'Fixo'
        })

        form.setFieldsValue({
            phone_keys: keys
        })
    }

    doSalaryCalc = () => {
        const { form } = this.props
        const salary = parseFloat(form.getFieldValue("person_employee_salary"))

        if (salary > 0) {
            const discounts = parseFloat(form.getFieldValue("person_employee_salary_discounts"))
            const dependents = parseFloat(form.getFieldValue("person_employee_depedents_count"))

            const inssTax = getTaxINSS(salary)
            const base = salary - discounts - inssTax.value
            const irrfTax = getTaxIRRF(base, dependents)

            const salaryValue = (base - irrfTax.value)

            console.debug('SALARY', { 'salary': salary, 'base': base, 'salaryValue': salaryValue, 'inssTax': inssTax, 'irrfTax': irrfTax })
            form.setFieldsValue({
                "person_employee_inss": Number.parseFloat(inssTax.value).toLocaleString('pt-BR', { style: 'decimal', useGrouping: true, minimumFractionDigits: 2 }),
                "person_employee_irrf": Number.parseFloat(irrfTax.value).toLocaleString('pt-BR', { style: 'decimal', useGrouping: true, minimumFractionDigits: 2 }),
                "person_employee_salary_final": Number.parseFloat(salaryValue).toLocaleString('pt-BR', { style: 'decimal', useGrouping: true, minimumFractionDigits: 2 }),
            })
        }
    }

    _changedTimeout = null
    onSalaryChange = () => {
        console.debug('SALARY CHANGED')
        if (this._changedTimeout !== null) {
            clearTimeout(this._changedTimeout)
        }

        this._changedTimeout = setTimeout(async () => {
            this.doSalaryCalc()
        }, 500)
    }

    customRequest = async ({ onSuccess, onError, file }) => {
        if (file && file.size > config.MAX_ATTACHMENT_SIZE) {
            message.info(`O tamanho máximo de anexos é de ${config.MAX_ATTACHMENT_SIZE / 1000000} MB.`);
            return;
        }

        try {
            if (file) {
                const storedKey = await s3Upload(file)

                const fileData = {
                    attachment_name: file.name,
                    attachment_size: file.size,
                    attachment_content_type: file.type,
                    attachment_store_key: storedKey
                }

                this.setState({
                    newAttachments: [...this.state.newAttachments, fileData]
                })

                onSuccess(file.storedKey, file)
            }
        } catch (e) {
            dealWithError('PersonsForm.customRequest', e)
            onError(e.message)
        }
    }

    onAttachmentRemove = async event => {
        const upload_id = event.uid
        const attachments = this.state.newAttachments.filter(file => file.upload_id !== upload_id)
        this.setState({
            newAttachments: attachments
        })
        EIAPI.del(`/attachments/persons/${this.params.id}/${upload_id}`);
    }

    render() {
        const attachments = this.state.attachments
        const { getFieldDecorator, getFieldValue } = this.props.form;

        const wrapperCol = {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 22,
                offset: 1,
            },
        }
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        }
        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: { span: 24, offset: 0 },
                sm: { span: 20, offset: 4 },
            },
        }

        const phoneTypeSelector = (idx) => {
            // const person_phones = this.state.person_phones || []
            const keys = getFieldValue('phone_keys') || [];

            return getFieldDecorator(`person_phones.${idx}.phone_description`, {
                initialValue: typeof keys[idx] !== 'undefined' ? keys[idx].phone_description : "Fixo"
            })(
                <Select style={{ width: 130 }} showSearch placeholder="Tipo">
                    <Select.Option value="Fixo"><Icon type="phone" /> Fixo</Select.Option>
                    <Select.Option value="Celular"><Icon type="mobile" /> Celular</Select.Option>
                    <Select.Option value="Fax"><Icon type="printer" /> Fax</Select.Option>
                    <Select.Option value="Outros"><Icon type="question-circle" /> Outros</Select.Option>
                </Select>
            );
        }

        const phonePrefixSelector = (idx) => {
            // const person_phones = this.state.person_phones || []
            const keys = getFieldValue('phone_keys') || [];
            return getFieldDecorator(`person_phones.${idx}.phone_area_code`, {
                initialValue: typeof keys[idx] !== 'undefined' ? keys[idx].phone_area_code : ""
            })(
                <Select style={{ width: 70 }} showSearch placeholder="DDD">
                    <Select.Option value="11">11</Select.Option>
                    <Select.Option value="12">12</Select.Option>
                    <Select.Option value="13">13</Select.Option>
                    <Select.Option value="14">14</Select.Option>
                    <Select.Option value="15">15</Select.Option>
                    <Select.Option value="16">16</Select.Option>
                    <Select.Option value="17">17</Select.Option>
                    <Select.Option value="18">18</Select.Option>
                    <Select.Option value="19">19</Select.Option>
                    <Select.Option value="21">21</Select.Option>
                    <Select.Option value="22">22</Select.Option>
                    <Select.Option value="24">24</Select.Option>
                    <Select.Option value="27">27</Select.Option>
                    <Select.Option value="28">28</Select.Option>
                    <Select.Option value="31">31</Select.Option>
                    <Select.Option value="32">32</Select.Option>
                    <Select.Option value="33">33</Select.Option>
                    <Select.Option value="34">34</Select.Option>
                    <Select.Option value="35">35</Select.Option>
                    <Select.Option value="37">37</Select.Option>
                    <Select.Option value="38">38</Select.Option>
                    <Select.Option value="41">41</Select.Option>
                    <Select.Option value="42">42</Select.Option>
                    <Select.Option value="43">43</Select.Option>
                    <Select.Option value="44">44</Select.Option>
                    <Select.Option value="45">45</Select.Option>
                    <Select.Option value="46">46</Select.Option>
                    <Select.Option value="47">47</Select.Option>
                    <Select.Option value="48">48</Select.Option>
                    <Select.Option value="49">49</Select.Option>
                    <Select.Option value="51">51</Select.Option>
                    <Select.Option value="53">53</Select.Option>
                    <Select.Option value="54">54</Select.Option>
                    <Select.Option value="55">55</Select.Option>
                    <Select.Option value="61">61</Select.Option>
                    <Select.Option value="62">62</Select.Option>
                    <Select.Option value="63">63</Select.Option>
                    <Select.Option value="64">64</Select.Option>
                    <Select.Option value="65">65</Select.Option>
                    <Select.Option value="66">66</Select.Option>
                    <Select.Option value="67">67</Select.Option>
                    <Select.Option value="68">68</Select.Option>
                    <Select.Option value="69">69</Select.Option>
                    <Select.Option value="71">71</Select.Option>
                    <Select.Option value="73">73</Select.Option>
                    <Select.Option value="74">74</Select.Option>
                    <Select.Option value="75">75</Select.Option>
                    <Select.Option value="77">77</Select.Option>
                    <Select.Option value="79">79</Select.Option>
                    <Select.Option value="81">81</Select.Option>
                    <Select.Option value="82">82</Select.Option>
                    <Select.Option value="83">83</Select.Option>
                    <Select.Option value="84">84</Select.Option>
                    <Select.Option value="85">85</Select.Option>
                    <Select.Option value="86">86</Select.Option>
                    <Select.Option value="87">87</Select.Option>
                    <Select.Option value="88">88</Select.Option>
                    <Select.Option value="89">89</Select.Option>
                    <Select.Option value="91">91</Select.Option>
                    <Select.Option value="92">92</Select.Option>
                    <Select.Option value="93">93</Select.Option>
                    <Select.Option value="94">94</Select.Option>
                    <Select.Option value="95">95</Select.Option>
                    <Select.Option value="96">96</Select.Option>
                    <Select.Option value="97">97</Select.Option>
                    <Select.Option value="98">98</Select.Option>
                    <Select.Option value="99">99</Select.Option>
                </Select>
            );
        }

        // const person_phones = this.state.person_phones || []
        // this.state.person_phones ||
        getFieldDecorator('phone_keys', { initialValue: this.state.person_phones })
        const keys = getFieldValue('phone_keys') || [];
        const formItems = keys.map((_k, idx) => (
            <Form.Item
                {...(idx === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                label={idx === 0 ? 'Telefones' : ''}
                required={false}
                key={idx}
            >
                {getFieldDecorator(`person_phones.${idx}.phone_number`, {
                    validateTrigger: ['onChange', 'onBlur'],
                    initialValue: typeof keys[idx] !== 'undefined' ? keys[idx].phone_number : "",
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: "O número do telefone é obrigatório :-)",
                        },
                    ],
                })(<Input
                    placeholder=""
                    style={{ width: '60%', marginRight: 8 }}
                    addonBefore={phonePrefixSelector(idx)}
                    addonAfter={phoneTypeSelector(idx)}
                />)}
                {keys.length > 0 ? (
                    <Icon
                        className="dynamic-delete-button"
                        type="minus-circle-o"
                        onClick={() => this.remove(idx)}
                    />
                ) : null}
            </Form.Item>
        ));

        const personTags = getFieldValue('person_tags') || this.state.data.person_tags || []
        const currentPersonType = this.state.person_type || "LEGAL"

        return (
            <div className="Persons">
                {this.state.isLoading && (
                    <div className="up-spin-area">
                        <Spin />
                    </div>
                )}
                {!this.state.isLoading && (
                    <Form {...config.FORM_LAYOUT} onSubmit={this.handleSubmit}>
                        <Form.Item label="Pessoa">
                            <Radio.Group
                                id="person_type"
                                name="person_type"
                                defaultValue={currentPersonType}
                                onChange={this.onChange}
                                buttonStyle="solid"
                            >
                                <Radio.Button value="NATURAL">Física</Radio.Button>
                                <Radio.Button value="LEGAL">Jurídica</Radio.Button>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Classificação">
                            {getFieldDecorator("person_tags", {
                                initialValue: personTags
                            })(
                                <HotTags />,
                            )}
                        </Form.Item>
                        <Form.Item label="Documento" hasFeedback validateStatus={this.state.isDataLoading ? "validating" : undefined}>
                            {getFieldDecorator("person_document", {
                                initialValue: this.state.person_document,
                                validateTrigger: ['onBlur'],
                                rules: [
                                    {
                                        required: true,
                                        message: "O documento é obrigatório..."
                                    }
                                ]
                            })(
                                <Input
                                    id="person_document"
                                    type="text"
                                    placeholder={
                                        this.state.person_type !== "NATURAL"
                                            ? "00.000.000/0000-00"
                                            : "000.000.000-00"
                                    }
                                    // onChange={this.onDocumentChange}
                                    onBlur={this.onDocumentChange}
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            label={
                                this.state.person_type !== "NATURAL"
                                    ? "Razão Social"
                                    : "Nome Completo"
                            }
                            validateStatus={this.state.isDataLoading ? "validating" : undefined}
                        >
                            {getFieldDecorator("person_name", {
                                initialValue: this.state.person_name,
                                rules: [
                                    {
                                        required: true,
                                        message: "Ops! Esse campo é obrigatório"
                                    }
                                ]
                            })(<Input
                                id="person_name"
                                type="text"
                                placeholder=""
                                disabled={this.state.isDataLoading}
                            />)}
                        </Form.Item>
                        <Form.Item
                            label={
                                this.state.person_type !== "NATURAL" ? (
                                    "Nome Fantasia"
                                ) : (
                                        <span>
                                            Apelido&nbsp;
                                            <Tooltip title="Como essa pessoa gosta de ser chamada?">
                                                <Icon type="question-circle-o" />
                                            </Tooltip>
                                        </span>
                                    )
                            }
                            validateStatus={this.state.isDataLoading ? "validating" : undefined}
                        >
                            {getFieldDecorator("person_nicename", {
                                initialValue: this.state.person_nicename,
                                rules: [
                                    {
                                        required: true,
                                        message: "Ops! Esse campo é obrigatório"
                                    }
                                ]
                            })(<Input
                                id="person_nicename"
                                type="text"
                                disabled={this.state.isDataLoading}
                                placeholder=""
                            />)}
                        </Form.Item>
                        <Form.Item label="E-mail" validateStatus={this.state.isDataLoading ? "validating" : undefined}>
                            {getFieldDecorator("person_email", {
                                initialValue: this.state.person_email,
                                rules: [
                                    // {
                                    //     type: "email",
                                    //     message:
                                    //         "Você precisa preencher este campo com um e-mail válido"
                                    // },
                                    {
                                        required: true,
                                        message: "Ops! Esse campo é obrigatório"
                                    }
                                ]
                            })(<Input
                                id="person_email"
                                type="text"
                                disabled={this.state.isDataLoading}
                                placeholder=""
                            />)}
                        </Form.Item>
                        <Row style={{ marginBottom: '20px' }}>
                            <Col {...wrapperCol}>
                                <Tabs>
                                    <TabPane tab="Endereços" key="tab-address">
                                        {/* extra={<a href="/mapa/">Ver no mapa</a>} */}
                                        <Card title="Endereço">
                                            <Row gutter={[12, 8]}>
                                                <Col lg={18}>
                                                    <Form.Item label="Logradouro" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("person_address.address_street", {
                                                        })(<Input id="person_address_street" type="text" disabled={this.state.isDataLoading} placeholder="Rua, Avenida, Travessa..." />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Item label="Número" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("person_address.address_number", {
                                                        })(<Input id="person_address_number" type="text" disabled={this.state.isDataLoading} placeholder="" />)}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[12, 8]}>
                                                <Col lg={18}>
                                                    <Form.Item label="Bairro" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("person_address.address_district", {
                                                        })(<Input id="person_address_district" type="text" disabled={this.state.isDataLoading} placeholder="" />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Item label="Complemento" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("person_address.address_complement", {
                                                        })(<Input id="person_address_complement" type="text" disabled={this.state.isDataLoading} placeholder="" />)}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[12, 8]}>
                                                <Col lg={6}>
                                                    <Form.Item label="Estado" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("person_address.address_state", {
                                                        })(
                                                            <Select id="person_address_state" disabled={this.state.isDataLoading}>
                                                                <Select.Option value="AC">AC - Acre</Select.Option>
                                                                <Select.Option value="AL">AL - Alagoas</Select.Option>
                                                                <Select.Option value="AM">AM - Amazonas</Select.Option>
                                                                <Select.Option value="AP">AP - Amapá</Select.Option>
                                                                <Select.Option value="BA">BA - Bahia</Select.Option>
                                                                <Select.Option value="CE">CE - Ceará</Select.Option>
                                                                <Select.Option value="DF">DF - Distrito Federal</Select.Option>
                                                                <Select.Option value="ES">ES - Espírito Santo</Select.Option>
                                                                <Select.Option value="GO">GO - Goiás</Select.Option>
                                                                <Select.Option value="MA">MA - Maranhão</Select.Option>
                                                                <Select.Option value="MG">MG - Minas Gerais</Select.Option>
                                                                <Select.Option value="MS">MS - Mato Grosso do Sul</Select.Option>
                                                                <Select.Option value="MT">MT - Mato Grosso</Select.Option>
                                                                <Select.Option value="PA">PA - Pará</Select.Option>
                                                                <Select.Option value="PB">PB - Paraíba</Select.Option>
                                                                <Select.Option value="PE">PE - Pernambuco</Select.Option>
                                                                <Select.Option value="PI">PI - Piauí</Select.Option>
                                                                <Select.Option value="PR">PR - Paraná</Select.Option>
                                                                <Select.Option value="RJ">RJ - Rio de Janeiro</Select.Option>
                                                                <Select.Option value="RN">RN - Rio Grande do Norte</Select.Option>
                                                                <Select.Option value="RO">RO - Rondônia</Select.Option>
                                                                <Select.Option value="RR">RR - Roraima</Select.Option>
                                                                <Select.Option value="RS">RS - Rio Grande do Sul</Select.Option>
                                                                <Select.Option value="SC">SC - Santa Catarina</Select.Option>
                                                                <Select.Option value="SE">SE - Sergipe</Select.Option>
                                                                <Select.Option value="SP">SP - São Paulo</Select.Option>
                                                                <Select.Option value="TO">TO - Tocantins</Select.Option>
                                                            </Select>
                                                        )}
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={12}>
                                                    <Form.Item label="Cidade" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("person_address.address_city", {
                                                        })(<Input id="person_address_city" type="text" disabled={this.state.isDataLoading} placeholder="" />)}
                                                    </Form.Item>
                                                </Col>
                                                <Col lg={6}>
                                                    <Form.Item label="CEP" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                        {getFieldDecorator("person_address.address_zipcode", {
                                                        })(<Input id="person_address_zipcode" type="text" disabled={this.state.isDataLoading} placeholder="" />)}
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                    <TabPane tab="Telefones" key="tab-phones">
                                        <Card>
                                            {formItems}
                                            <Form.Item {...formItemLayoutWithOutLabel}>
                                                <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
                                                    <Icon type="plus" /> Adicionar telefone
                                                </Button>
                                            </Form.Item>
                                        </Card>
                                    </TabPane>
                                    {personTags.indexOf('Associado') >= 0 &&
                                        <TabPane tab="Dados do Associado" key="tab-associated">
                                            {/* 
                                            person_birthday = Data Nascimento
                                            */}
                                            <Card>
                                                <Row gutter={[12, 8]}>
                                                    <Col lg={12}>
                                                        <Form.Item label="Área" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_member_business", {
                                                                initialValue: []
                                                            })(
                                                                <Select
                                                                    id="person_member_business"
                                                                // mode="multiple"
                                                                >
                                                                    <Select.Option value="Agronomia">Agronomia</Select.Option>
                                                                    <Select.Option value="Engenharia">Engenharia</Select.Option>
                                                                    <Select.Option value="Geografia">Geografia</Select.Option>
                                                                    <Select.Option value="Geologia">Geologia</Select.Option>
                                                                    <Select.Option value="Meteorologia">Meteorologia</Select.Option>
                                                                    <Select.Option value="Tecnólogos">Tecnólogos</Select.Option>
                                                                    <Select.Option value="Técnicos Agrícolas">Técnicos Agrícolas</Select.Option>
                                                                </Select>
                                                            )}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <Form.Item label="Título" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_member_title", {
                                                            })(<Input id="person_member_title" type="text" disabled={this.state.isDataLoading} placeholder="Engenheiro Civil, Engenheiro Mecânico, Técnico em Eletrônica..." />)}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[12, 8]}>
                                                    <Col lg={12}>
                                                        <Form.Item label="Data Associação" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_member_since", {
                                                            })(<DatePicker id="person_member_since" disabled={this.state.isDataLoading} format="DD/MM/YYYY" />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <Form.Item label="Registro do CREA / CAU / CFT" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_member_register_doc", {
                                                            })(<Input id="person_member_register_doc" type="text" disabled={this.state.isDataLoading} placeholder="" />)}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[12, 8]}>
                                                    <Col lg={12}>
                                                        <Form.Item label="Graduação" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_member_graduation", {
                                                            })(<Input id="person_member_graduation" type="text" disabled={this.state.isDataLoading} placeholder="" />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <Form.Item label="Graduado em" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_member_graduated_at", {
                                                            })(<DatePicker id="person_member_graduated_at" disabled={this.state.isDataLoading} format="DD/MM/YYYY" />)}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </TabPane>
                                    }
                                    {personTags.indexOf('Funcionário') >= 0 &&
                                        <TabPane tab="Dados do Funcionário" key="tab-employee">
                                            <Card>
                                                <Row gutter={[12, 8]}>
                                                    <Col lg={12}>
                                                        <Form.Item label="Data Contratação" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_employee_hired_at", {
                                                            })(<DatePicker id="person_employee_hired_at" format="DD/MM/YYYY" />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <Form.Item label="Data Desligamento" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_employee_turnoffed_at", {
                                                            })(<DatePicker id="person_employee_turnoffed_at" format="DD/MM/YYYY" />)}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row gutter={[12, 8]} style={{ marginTop: 15 }}>
                                                    <Col lg={8}>
                                                        <Form.Item label="Salário Bruto" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_employee_salary", {
                                                            })(<InputCurrency id="person_employee_salary" onChange={this.onSalaryChange} placeholder="" />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={8}>
                                                        <Form.Item label="Descontos" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_employee_salary_discounts", {
                                                            })(<InputCurrency id="person_employee_salary_discounts" onChange={this.onSalaryChange} placeholder="" />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={8}>
                                                        <Form.Item label="Número Dependentes" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_employee_depedents_count", {
                                                            })(<InputNumber id="person_employee_depedents_count" min={0} max={30} onChange={this.onSalaryChange} placeholder="" />)}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row gutter={[12, 8]}>
                                                    <Col lg={8}>
                                                        <Form.Item label="INSS" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_employee_inss", {
                                                            })(<InputCurrency id="person_employee_inss" disabled placeholder="" />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={8}>
                                                        <Form.Item label="IRRF" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_employee_irrf", {
                                                            })(<InputCurrency id="person_employee_irrf" disabled placeholder="" />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={8}>
                                                        <Form.Item label={<strong>Salário Líquido</strong>} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_employee_salary_final", {
                                                            })(<InputCurrency id="person_employee_salary_final" disabled placeholder="" />)}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>

                                                <Row style={{ marginLeft: 20, marginTop: 35 }}>
                                                    <Col lg={24}>{getFieldDecorator("person_employee_dedicated", {
                                                    })(
                                                        <div>
                                                            <Switch defaultChecked style={{ marginRight: 10 }} />
                                                            Este funcionário é dedicado para atividades relacionadas ao CREA
                                                        </div>
                                                    )}</Col>
                                                </Row>
                                                <Row style={{ marginLeft: 20, marginTop: 15 }}>
                                                    <Switch defaultChecked style={{ marginRight: 10 }} />
                                                    Quero gerar o Contas a Pagar mensalmente para esse funcionário
                                                </Row>
                                            </Card>
                                        </TabPane>
                                    }
                                    {this.state.person_type !== "NATURAL" &&
                                        <TabPane tab="Mais informações" key="tab-more-info">
                                            <Card>
                                                <Row gutter={[12, 8]}>
                                                    <Col lg={18}>
                                                        <Form.Item label="Natureza Juridica" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_info_legal_origin", {
                                                            })(<Input id="person_info_legal_origin" type="text" disabled={this.state.isDataLoading} placeholder="" />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <Form.Item label="Situação SEFAZ" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_info_legal_status", {
                                                            })(<Input id="person_info_legal_status" type="text" disabled placeholder="" />)}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[12, 8]}>
                                                    <Col lg={8}>
                                                        <Form.Item label="Data Abertura" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_info_open_date", {
                                                            })(<DatePicker id="person_info_open_date" disabled={this.state.isDataLoading} format="DD/MM/YYYY" />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={8}>
                                                        <Form.Item label="Data Situação Cadastral" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_info_status_date", {
                                                            })(<DatePicker id="person_info_status_date" disabled format="DD/MM/YYYY" />)}
                                                        </Form.Item>
                                                    </Col>
                                                    <Col lg={8}>
                                                        <Form.Item label="Capital Social" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_info_capital", {
                                                            })(<InputCurrency id="person_info_capital" type="text" disabled={this.state.isDataLoading} placeholder="" />)}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                                <Row gutter={[12, 8]}>
                                                    <Col lg={24}>
                                                        <Form.Item label="CNAE" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                                            {getFieldDecorator("person_info_cnae_principal", {
                                                            })(<Input id="person_info_cnae_principal" type="text" disabled={this.state.isDataLoading} placeholder="" />)}
                                                            {getFieldDecorator("person_info_cnae_others", {
                                                            })(<TextArea autoSize={{ minRows: 4, maxRows: 15 }} id="person_info_cnae_others" type="text" disabled={this.state.isDataLoading} placeholder="" />)}
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </TabPane>
                                    }
                                    <TabPane tab="Anexos" key="tab-attachments">
                                        <Form.Item label="Anexo">
                                            <Upload
                                                id="upload-field"
                                                multiple={true}
                                                customRequest={this.customRequest}
                                                defaultFileList={attachments}
                                                onRemove={this.onAttachmentRemove}
                                            >
                                                <Button>
                                                    <Icon type="upload" /> Enviar anexos
                                                </Button>
                                            </Upload>
                                        </Form.Item>

                                    </TabPane>
                                </Tabs>
                            </Col>
                        </Row>
                        <Form.Item {...config.TAIL_FORM_ITEM_LAYOUT}>
                            {typeof this.state.data.person_id !== "undefined" && (
                                <Popconfirm
                                    title="Tem certeza que deseja excluir?"
                                    onConfirm={this.handleDelete}
                                    okText="Sim"
                                    cancelText="Não"
                                    className="up-right-button"
                                >
                                    <Button type="danger" ghost>
                                        Excluir
                                    </Button>
                                </Popconfirm>
                            )}
                            <LoaderButton
                                type="primary"
                                htmlType="submit"
                                loading={this.state.isLoading}
                                text="Salvar"
                                loadingText="Salvando..."
                            />
                        </Form.Item>
                    </Form>
                )}
            </div>
        )
    }
}

export default class PersonsForm extends Component {
    MainForm = Form.create({ name: "form" })(PersonsFormComponent);

    render() {
        const childProps = {
            // isAuthenticated: this.state.isAuthenticated,
            // isAppLoading: this.state.isAppLoading,
            match: this.props.match,
            history: this.props.history,
            appSetTitle: this.props.appSetTitle
        };

        return <this.MainForm {...childProps} />;
    }
}

import { notification } from 'antd';
import { Auth } from 'aws-amplify';
import { API } from "aws-amplify";
import _ from 'loadsh';

import moment from 'moment';
import 'moment/locale/pt-br';
// import { Next } from 'react-bootstrap/lib/Pagination';
moment.locale('pt-br');

const upOpenNotificationWithIcon = (type, message, description) => {
    notification[type]({
        message: message,
        description: description
    })
}

export function showMessageError(message, description) {
    return upOpenNotificationWithIcon('error', message, description)
}

export function showMessageInfo(message, description) {
    return upOpenNotificationWithIcon('info', message, description)
}

export function getBaseUri(uri) {
    if (typeof uri !== 'undefined')
        uri = uri.replace(/^\//g, '')

    return `/${uri}`
}

export function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);
    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        let begin = sliceIndex * sliceSize;
        let end = Math.min(begin + sliceSize, bytesLength);

        let bytes = new Array(end - begin);
        for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
}

export function dealWithError(module, errParam, args) {
    let errorMessage = (typeof errParam === 'object' ? errParam.message : '')
    console.error('dealWithError', { "module": module, "errParam": errParam, "errorMessage": errorMessage, "args": args })

    if (errorMessage === 'Network Error' || errorMessage.match(/Request failed with status code/)) {
        errorMessage = 'Alguma coisa não aconteceu como o esperado, mas você não precisa se preocupar, nossa equipe já foi avisada e logo mais tudo deve estar funcionando novamente.'

    } else if (errorMessage === 'Incorrect username or password.') {
        errorMessage = 'O usuário/senha digitado não existe ou está incorreto..'

    } else if (errorMessage === 'Password cannot be empty') {
        errorMessage = 'A senha não pode ser vazia';

    } else if (errorMessage === 'User does not exist.') {
        errorMessage = 'O usuário informado não existe, tem certeza que digitou certo?';

    } else if (errorMessage === 'Password does not conform to policy: Password not long enough') {
        errorMessage = 'A senha não atende a política de segurança: sua senha está muito pequena';

    } else if (errorMessage === 'Password does not conform to policy: Password must have numeric characters') {
        errorMessage = 'A senha não atende a política de segurança: é preciso usar números';

    } else if (errorMessage === 'Password does not conform to policy: Password must have lowercase characters') {
        errorMessage = 'A senha não atende a política de segurança: você precisa usar símbolos (Ex: $, #, !, etc)';

    } else if (errorMessage === 'Password does not conform to policy: Password must have lowercase characters') {
        errorMessage = 'A senha não atende a política de segurança: você precisa usar caracteres minúsculos';

    } else if (errorMessage === 'No current user' && errorMessage === 'not authenticated') {
        errorMessage = null
    } else if (errorMessage === 'Invalid verification code provided, please try again.') {
        errorMessage = 'Código de verificação inválido.'
    } else if (errorMessage === 'Attempt limit exceeded, please try after some time.') {
        errorMessage = 'Limite de tentativas alcançado, tente novamente mais tarde.'
    } else if (errorMessage === 'Username/client id combination not found.') {
        errorMessage = 'E-mail não encontrado'
    } else if (typeof errParam.code !== 'undefined') {
        switch (errParam.code) {
            case 409: {
                errorMessage = 'Não foi possível salvar esse registro pois ele está dúplicado :-(';
                break;
            }

            default: {
                errorMessage = `${errorMessage} [${errParam.code}]`;
            }
        }
    }
    console.error('dealWithError->end', { "module": module, "e": errorMessage })

    if (errorMessage !== null && errorMessage !== '')
        return showMessageError('Ops!', errorMessage)
}

export function parseBoolean(str) {
    return (str === 'true' || str === 1)
}

export function parseInt(numberStr) {
    return Number.parseInt(numberStr, 10)
}

export function parseFloat(numberStr) {
    let numberAux = typeof numberStr === 'undefined' || numberStr === null ? '0' : numberStr + ''
    if (numberAux.indexOf(',') < numberAux.indexOf('.'))
        numberAux = numberAux.replace(',', '')
    else
        numberAux = numberAux.replace(/\./g, '').replace(/,/, '.')

    return Number.parseFloat(numberAux)
}

export function formatDate(date, format) {
    if (typeof date === 'undefined' || date === null || date === '')
        return ''

    if (typeof format === 'undefined')
        format = 'DD/MM/YYYY'

    return moment.utc(date).format(format)
}

export function formatDateTime(date, format) {
    if (typeof format === 'undefined')
        format = 'DD/MM/YYYY HH:MM'

    return formatDate(date, format)
}

export function formatCurrency(number) {
    if (typeof number === 'undefined' || number === null || number === 0)
        number = 0.00
    else if (typeof number === 'string')
        number = parseFloat(number)

    var currency = number.toFixed(2).split('.');
    currency[0] = "R$ " + currency[0].split(/(?=(?:...)*$)/).join('.');
    return currency.join(',');
}

export function parsePhoneNumber(phoneStr) {
    let phoneNumber = ((phoneStr || '').match(/\d/g) || []).join('')
    let res = ''

    if (phoneNumber.length <= 9) {
        res = { phone_number: phoneNumber }
    } else {
        res = {
            phone_area_code: phoneNumber.substr(0, 2),
            phone_number: phoneNumber.substr(2)
        }
    }

    if (res.phone_number.length <= 8) {
        res.phone_description = 'Fixo'
    } else if (res.phone_number.length === 9) {
        res.phone_description = 'Celular'
    } else {
        res.phone_description = 'Outros'
    }

    return res
}

export function getTaxIRRF(salaryBase, dependents) {
    let irrfTax

    // https://www.calculador.com.br/tabela/trabalhista/IRRF/2015
    if (salaryBase <= 1903.98) {
        irrfTax = { "value": 0, "tax": 0, "deduction": 0 }

    } else if (salaryBase >= 1903.99 && salaryBase <= 2826.65) {
        irrfTax = { "tax": 0.075, "deduction": 142.80 }

    } else if (salaryBase >= 2826.66 && salaryBase <= 3751.05) {
        irrfTax = { "tax": 0.015, "deduction": 354.80 }

    } else if (salaryBase >= 3751.06 && salaryBase <= 4664.68) {
        irrfTax = { "tax": 0.0225, "deduction": 636.13 }

    } else {
        irrfTax = { "tax": 0.0275, "deduction": 869.36 }

    }

    if (typeof irrfTax.value === 'undefined') {
        irrfTax.value = (salaryBase - (dependents * irrfTax.deduction)) * irrfTax.tax
    }

    return irrfTax
}

export function getTaxINSS(salary) {
    // https://www.inss.gov.br/servicos-do-inss/calculo-da-guia-da-previdencia-social-gps/tabela-de-contribuicao-mensal/
    if (salary <= 1830.29)
        return { "value": (salary * 0.08), "tax": 0.08 }
    else if (salary >= 1830.30 && salary <= 3050.52)
        return { "value": (salary * 0.09), "tax": 0.09 }
    else if (salary >= 3050.53 && salary <= 6101.06)
        return { "value": (salary * 0.11), "tax": 0.11 }
    else
        return { "value": (6101.06 * 0.11), "tax": 0.11 }
}

export function getStepCascade(stepId, metas, metaProperty) {
    let stepCascade = []
    metaProperty = (typeof metaProperty === "undefined" ? "value" : metaProperty)

    _.each(metas, meta => {
        // console.log('getStepCascade->meta', meta.value, meta.label)
        _.each(meta.children, category => {
            // console.log('getStepCascade->category', category.value, category.label)
            _.each(category.children, step => {
                // console.log('getStepCascade->step', step.value, step.label)
                if (step.value === stepId) {
                    stepCascade = [meta[metaProperty], category[metaProperty], step[metaProperty]];
                }
            })
        })
    })

    return stepCascade
}

export async function getMetas(packId) {
    const EIAPI = new EIAPIHelper()
    const packs = {}
    let meta = {}

    const res = await EIAPI.get("/metas/")
    _.forEach(res.data, function (row) {
        if (typeof packs[row.pack_id] === 'undefined')
            packs[row.pack_id] = {
                value: row.pack_id,
                label: row.pack_description,
                children: []
            }

        if (typeof meta[row.meta_id] === 'undefined')
            meta[row.meta_id] = {
                value: row.meta_id,
                label: row.meta_title,
                pack_id: row.pack_id,
                children: {}
            }

        if (typeof meta[row.meta_id].children[row.category_id] === 'undefined') {
            meta[row.meta_id].children[row.category_id] = {
                value: row.category_id,
                label: row.category_title,
                pack_id: row.pack_id,
                children: {}
            }
        }

        if (typeof meta[row.meta_id].children[row.category_id].children[row.type_id] === 'undefined') {
            meta[row.meta_id].children[row.category_id].children[row.type_id] = {
                value: row.type_id,
                label: row.type_title,
                pack_id: row.pack_id,
            }
        }
    })

    meta = Object.values(meta)
    meta.forEach(el => {
        el.children = Object.values(el.children)

        el.children.forEach(el => {
            el.children = Object.values(el.children)
        })
        packs[el.pack_id].children.push(el);
    })

    return typeof packId !== 'undefined' ? packs[packId] : packs;
    // return Object.values(packs);
}

export async function getMetasStepDetail(stepId) {
    const EIAPI = new EIAPIHelper()
    return await EIAPI.get(`/metas/pta-step/${stepId}`);
}

export async function getMetasWithValues(packId, group, period) {
    const EIAPI = new EIAPIHelper()
    let meta = {}

    if (typeof packId === 'undefined')
        packId = '4582bb40-8bc4-11ea-ba6e-1636a2bc008f' // pta 2020

    if (typeof group === 'undefined')
        group = 'total'

    let url = `/metas/pta/${packId}/${group}`

    if (typeof period !== 'undefined')
        url += `/${period}`

    const res = await EIAPI.get(url)
    _.forEach(res.data, function (row) {
        if (typeof meta[row.meta_id] === 'undefined') {
            meta[row.meta_id] = {
                value: row.meta_id,
                label: row.meta_title,
                value_paid: 0,
                budget: 0,
                value_proponent: 0,
                value_grantor: 0,
                children: {}
            }
        }

        if (typeof meta[row.meta_id].children[row.category_id] === 'undefined') {
            meta[row.meta_id].children[row.category_id] = {
                value: row.category_id,
                label: row.category_title,
                value_paid: 0,
                budget: 0,
                value_proponent: 0,
                value_grantor: 0,
                children: {}
            }
        }

        if (typeof meta[row.meta_id].children[row.category_id].children[row.type_id] === 'undefined') {
            meta[row.meta_id].children[row.category_id].children[row.type_id] = {
                value: row.type_id,
                label: row.type_title,
                value_paid: 0,
                budget: 0,
                value_proponent: 0,
                value_grantor: 0
            }
        }

        // value paid
        meta[row.meta_id].value_paid += row.value_paid
        meta[row.meta_id].children[row.category_id].value_paid += row.value_paid
        meta[row.meta_id].children[row.category_id].children[row.type_id].value_paid += row.value_paid

        // value proponent
        meta[row.meta_id].value_proponent += row.value_proponent || 0
        meta[row.meta_id].children[row.category_id].value_proponent += row.value_proponent || 0
        meta[row.meta_id].children[row.category_id].children[row.type_id].value_proponent += row.value_proponent || 0

        // value grantor
        meta[row.meta_id].value_grantor += row.value_grantor || 0
        meta[row.meta_id].children[row.category_id].value_grantor += row.value_grantor || 0
        meta[row.meta_id].children[row.category_id].children[row.type_id].value_grantor += row.value_grantor || 0

        // budget
        // meta[row.meta_id].budget += row.budget
        if (group === 'total') {
            meta[row.meta_id].budget = meta[row.meta_id].value_proponent + meta[row.meta_id].value_grantor
            meta[row.meta_id].children[row.category_id].budget += meta[row.meta_id].children[row.category_id].value_proponent + meta[row.meta_id].children[row.category_id].value_grantor
            meta[row.meta_id].children[row.category_id].children[row.type_id].budget += meta[row.meta_id].children[row.category_id].children[row.type_id].value_proponent + meta[row.meta_id].children[row.category_id].children[row.type_id].value_grantor
        } else if (group === 'proponent') {
            meta[row.meta_id].budget = meta[row.meta_id].value_proponent
            meta[row.meta_id].children[row.category_id].budget += meta[row.meta_id].children[row.category_id].value_proponent
            meta[row.meta_id].children[row.category_id].children[row.type_id].budget += meta[row.meta_id].children[row.category_id].children[row.type_id].value_proponent
        } else if (group === 'grantor') {
            meta[row.meta_id].budget = meta[row.meta_id].value_grantor
            meta[row.meta_id].children[row.category_id].budget += meta[row.meta_id].children[row.category_id].value_grantor
            meta[row.meta_id].children[row.category_id].children[row.type_id].budget += meta[row.meta_id].children[row.category_id].children[row.type_id].value_grantor
        }

        // progress_description
        const budget = meta[row.meta_id].children[row.category_id].children[row.type_id].budget;

        meta[row.meta_id].children[row.category_id].children[row.type_id].progress = Math.round(meta[row.meta_id].children[row.category_id].children[row.type_id].value_paid / meta[row.meta_id].children[row.category_id].children[row.type_id].budget * 100)
        meta[row.meta_id].children[row.category_id].children[row.type_id].showInfo = true;

        if (meta[row.meta_id].children[row.category_id].children[row.type_id].budget === 0) {
            meta[row.meta_id].children[row.category_id].children[row.type_id].progress_description = "Nenhuma verba alocada";
            meta[row.meta_id].children[row.category_id].children[row.type_id].progress_tooltip = "";
            meta[row.meta_id].children[row.category_id].children[row.type_id].progress_icon = { "type": "info-circle" };
            meta[row.meta_id].children[row.category_id].children[row.type_id].status = "exception";
            meta[row.meta_id].children[row.category_id].children[row.type_id].showInfo = false;
        } else if (row.value_paid <= budget) {
            meta[row.meta_id].children[row.category_id].children[row.type_id].progress_description = "Saldo: " + formatCurrency(row.budget - row.value_paid);
            meta[row.meta_id].children[row.category_id].children[row.type_id].progress_tooltip = "Dentro do planejado";
            meta[row.meta_id].children[row.category_id].children[row.type_id].progress_icon = { "type": "check-circle" };
        } else {
            meta[row.meta_id].children[row.category_id].children[row.type_id].progress_description = formatCurrency((row.budget - row.value_paid) * -1) + " acima da verba";
            meta[row.meta_id].children[row.category_id].children[row.type_id].progress_tooltip = "Acima da verba";
            meta[row.meta_id].children[row.category_id].children[row.type_id].progress_icon = { "type": "exclamation-circle" };
            meta[row.meta_id].children[row.category_id].children[row.type_id].status = "exception";
        }


    })

    meta = Object.values(meta)
    meta.forEach(el => {
        el.children = Object.values(el.children)

        el.children.forEach(el => {
            el.children = Object.values(el.children)
        })
    })

    return meta
}

export async function getDocumentsList(stepId) {
    const EIAPI = new EIAPIHelper()
    const documentList = await EIAPI.get('/expenses/documents')
    return _.mapKeys(documentList.data, (i) => { return i.document_id });
}

export class EIAPIHelper {
    async get(url, params) {
        return await API.get('ei-api', url, {
            queryStringParameters: params
        })
    }
    async post(url, data) {
        return await API.post('ei-api', url, data)
    }
    async put(url, data) {
        return await API.put('ei-api', url, data)
    }
    async del(url) {
        return await API.del('ei-api', url)
    }
}

export class PersonDocument {
    original = ''
    digits = ''
    type = ''

    constructor(original, type) {
        this.original = original || ''
        this.digits = this.original.replace(/[^\d]/g, "")

        if (typeof type !== 'undefined' && (type === 'CNPJ' || type === 'LEGAL'))
            this.type = function () { return 'CNPJ' }
        else if (typeof type !== 'undefined' && (type === 'CPF' || type === 'NATURAL'))
            this.type = function () { return 'CPF' }
        else
            this.type = function () { return this.detectType(original) }
    }

    toString() {
        return this.original
    }

    documentType() {
        return this.type()
    }

    digits() {
        return this.digits
    }

    document() {
        return this.format()
    }

    isCPF() {
        return this.type() === "CPF" || this.type() === 'NATURA:'
    }

    isCNPJ() {
        return this.type() === "CNPJ" || this.type() === 'LEGAL'
    }

    isValidCPF() {
        var s, n, i
        var c = this.original;
        if ((c = c.replace(/[^\d]/g, "").split("")).length !== 11) return false;
        if (new RegExp("^" + c[0] + "{11}$").test(c.join(""))) return false;
        for (s = 10, n = 0, i = 0; s >= 2; n += c[i++] * s--);
        if (parseInt(c[9], 10) !== (((n %= 11) < 2) ? 0 : 11 - n)) return false;
        for (s = 11, n = 0, i = 0; s >= 2; n += c[i++] * s--);
        if (parseInt(c[10], 10) !== (((n %= 11) < 2) ? 0 : 11 - n)) return false;
        return true;
    }

    isValidCNPJ() {
        var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2],
            c = this.original;
        var i, n
        if ((c = c.replace(/[^\d]/g, "").split("")).length !== 14) return false;

        for (i = 0, n = 0; i < 12; n += c[i] * b[++i]);
        if (parseInt(c[12], 10) !== (((n %= 11) < 2) ? 0 : 11 - n)) return false;
        for (i = 0, n = 0; i <= 12; n += c[i] * b[i++]);
        if (parseInt(c[13], 10) !== (((n %= 11) < 2) ? 0 : 11 - n)) return false;
        return true;
    }

    isValid() {
        return this['isValid' + this.type()]()
    }

    formatCPF() {
        return ('000000000' + this.digits).substr(-11).replace(/(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4")
    }

    formatCNPJ() {
        return ('000000000' + this.digits).substr(-14).replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5")
    }

    format() {
        return this.isValid() ? this['format' + this.type()]() : this.original
    }

    detectType() {
        const value = String(this.original).replace(/\D/g, "");
        return (value.length > 11) ? 'CNPJ' : 'CPF';
    }
}

class UpHelpers {
    static async getUserId() {
        const userInfo = await Auth.currentUserInfo()
        if (typeof userInfo == 'object')
            return userInfo.id.split(/:/)[1]
    }
}


export default UpHelpers;

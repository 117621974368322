import React from "react";
import { Button } from 'antd'
// import { Glyphicon } from "react-bootstrap";
// import "./LoaderButton.css";

export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) =>
  <Button
    className={`LoaderButton ${className}`}
    disabled={disabled || isLoading}
    loading={isLoading}
    {...props}
  >
    {!isLoading ? text : loadingText}
  </Button>;

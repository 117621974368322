import React, { Component } from "react";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { dealWithError } from "../libs/ei-helpers";
import "./Home.css";
import { Button, Card, Row, Col, Calendar, Badge, Result, Timeline, Icon, Spin } from "antd";

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const { Meta } = Card;

export default class Home extends Component {
	constructor(props) {
		super(props);

		props.appSetTitle('Dashboard')

		this._isMounted = false
		this.state = {
			notes: [],
			tasks: [],
			isLoading: true,
			isLoadingNotes: true,
			isLoadingTasks: true,
		};
	}

	async componentDidMount() {
		this._isMounted = true
		if (this.props.isAuthenticated) {
			try {
				API.get("ei-api", "/home").then(res => {
					if (!res.status)
						dealWithError('Home', new Error("Ops! Não foi possível recuperar as informações do seu dashboard. Vamos tentar novamente em alguns minutos?"));
						
					if (typeof res.notes !== 'undefined') {
						this.setState({
							notes: res.notes.data,
							tasks: res.tasks
						}, () => {
							this.setState({
								isLoadingNotes: false,
								isLoadingTasks: false
							})
						})
					}
				})

			} catch (e) {
				dealWithError('Home', e);
			}
		}

		this.toggleAppLoading(false)
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	toggleAppLoading = (isLoading) => {
		if (this._isMounted) {
			this.setState({
				isLoading: typeof isLoading !== 'undefined' ? isLoading : !this.state.isLoading
			}, () => {
				// console.log('toggleAppLoading', this.state.isLoading)
			})
		}
	}

	getListData(value) {
		let listData;
		switch (value.date()) {
			case 8:
				listData = [
					{ type: 'warning', content: 'This is warning event.' },
					{ type: 'success', content: 'This is usual event.' },
				];
				break;
			case 10:
				listData = [
					{ type: 'warning', content: 'This is warning event.' },
					{ type: 'success', content: 'This is usual event.' },
					{ type: 'error', content: 'This is error event.' },
				];
				break;
			case 15:
				listData = [
					{ type: 'warning', content: 'This is warning event' },
					{ type: 'success', content: 'This is very long usual event。。....' },
					{ type: 'error', content: 'This is error event 1.' },
					{ type: 'error', content: 'This is error event 2.' },
					{ type: 'error', content: 'This is error event 3.' },
					{ type: 'error', content: 'This is error event 4.' },
				];
				break;
			default:
		}
		return listData || [];
	}

	dateCellRender(value) {
		const listData = this.getListData(value);
		return (
			<ul className="events">
				{listData.map(item => (
					<li key={item.content}>
						<Badge status={item.type} text={item.content} />
					</li>
				))}
			</ul>
		);
	}

	renderNotesList() {
		// const notes = this.state.isLoadingNotes ? new Array(2) : this.state.notes
		const notes = this.state.notes
		const renderNotes = notes.map(
			(note, i) =>
				<Card className="dashboard-note"
					key={note.note_id}
				>
					<Link
						key={note.note_id}
						to={`/notes/${note.note_id}`}
					>
						<Meta
							title={note.note_title}
							description={note.note_content.trim().split("\n").slice(0, 2).join(' ')}
						/>
					</Link>
				</Card>
		)

		return (
			<Card title="Anotações"
				bordered={false}
				className="dashboard-widget dashboard-notes"
				extra={
					<Link to="/notes/new">
						<Button type="primary">Criar nova anotação</Button>
					</Link>
				}
				loading={this.state.isLoadingNotes}
			>
				{renderNotes}
			</Card>
		)
	}

	renderTimeline() {
		// const notes = this.state.isLoadingNotes ? new Array(2) : this.state.notes
		const tasks = this.state.tasks
		const renderTasks = tasks.map(
			(task, i) =>
				<Timeline.Item key={task.task_id} dot={task.task_dot ? <Icon type={task.task_dot} /> : ''}>
					<Link
						key={task.task_id}
						to={task.task_url}
					>
						{task.task_title}<br />
						<small style={{ color: 'gray' }}>{moment(task.task_date).format('DD/MM')}</small>
					</Link>
				</Timeline.Item>
		)

		return (
			<Timeline
				style={{ marginTop: '15px', marginLeft: '20px' }}
			>
				{renderTasks}
			</Timeline>
		)
	}

	renderLoading() {
		return (
			<div className="lander">
				<Result
					icon={<img src="/imgs/ei_logo.png" className="logo" alt="Entidade Integrada" />}
					extra={
						<div className="up-spin-area">
							<Spin size="large" />
						</div>
					}
				/>
			</div>
		)
	}

	renderWelcome() {
		return (
			<div className="lander">
				<Result
					icon={<img src="/imgs/ei_logo.png" className="logo" alt="Entidade Integrada" />}
					title="Um novo mundo para entidades do CREA começa aqui! :-)"
					extra={
						<div>
							<Link to="/login" className="btn btn-info btn-lg">
								<Button type="primary">Entrar</Button>
							</Link>
							<div style={{ marginTop: '25px' }}>
								<Link to="/signup/request" className="btn btn-link">
									Ainda não tem os dados de acesso? Clique aqui para começar
								</Link>
							</div>
						</div>
					}
				/>
			</div>
		)
	}

	renderLander() {
		return (
			<div>
				{this.props.isAppLoading ? this.renderLoading() : this.renderWelcome()}
			</div>
		)
	}

	renderNotes() {
		return (
			<div>
				<Row>
					<Col lg={16}>
						{this.renderNotesList()}
					</Col>
					<Col className="dashboard-widget dashboard-calendar" lg={8}>
						<Calendar
							headerRender={function () { }}
							dataCellRender={this.dataCellRender}
							fullscreen={false}
							readonly
						/>

						{this.renderTimeline()}
					</Col>
				</Row>
			</div>
		);
	}

	render() {
		return (
			<div className="Home">
				{this.props.isAuthenticated ? this.renderNotes() : this.renderLander()}
			</div>
		)
	}
}
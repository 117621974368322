import React, { Component } from "react"
// import { API } from "aws-amplify"
import { dealWithError, EIAPIHelper, formatDate, parseFloat } from "../../libs/ei-helpers"
import { Link } from "react-router-dom"
import { Table, Card, Icon, Popover, Tag, Menu, Dropdown } from 'antd'

import _ from 'loadsh'
import ls from 'local-storage'
import Excel from "exceljs"
import { saveAs } from 'file-saver';

import moment from 'moment'
import 'moment/locale/pt-br'
moment.locale('pt-br')

const { Meta } = Card
const EIAPI = new EIAPIHelper()

export default class Persons extends Component {
  constructor(props) {
    super(props)

    props.appSetTitle(props.pageTitle || 'Cadastro de Pessoas')

    this.state = {
      isLoading: true,
      persons: [],
      personsPagination: {},
      pageFilter: props.filters || {},
      filteredInfo: ls.get('persons.filteredInfo') || null,
      sortedInfo: ls.get('persons.sortedInfo') || null
    }
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return
    }

    try {
      console.debug('GRID FILTERRRR', this.state.pageFilter)
      const res = await EIAPI.get("/persons", this.state.pageFilter)
      this.setState({
        persons: res.data,
        personsPagination: res.pagination
      })
    } catch (e) {
      dealWithError('Notes', e)
    }

    this.setState({ isLoading: false })
  }

  onChange = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });

    ls.set('persons.filteredInfo', filters);
    ls.set('persons.sortedInfo', sorter);
  };

  doExport = async () => {
    const res = await EIAPI.get("/persons", this.state.pageFilter)

    // try {
    const workbook = new Excel.Workbook()
    const worksheet = workbook.addWorksheet("Pessoas")

    worksheet.columns = [
      { header: 'Documento', key: 'person_document', width: 10 },
      { header: 'Tipo', key: 'person_type', width: 32 },
      { header: 'Nome', key: 'person_name', width: 60 },
      { header: 'Nome Fantasia', key: 'person_nicename', width: 60 },
      { header: 'E-mail', key: 'person_email', width: 60 },
      { header: 'Aniversário', 'key': 'person_birthday', width: 26 },
      { header: 'Funcionário Dedicado', 'key': 'person_dedicated_employee', width: 40 },
      { header: 'CNAE Principal', 'key': 'person_info_cnae_principal', width: 40 },
      { header: 'Data Abertura', 'key': 'person_info_open_date', width: 26 },
      { header: 'Data Criação', 'key': 'person_created_at', width: 26 },
      { header: 'Data Última Alteração', 'key': 'person_updated_at', width: 26 },
      { header: 'Tags', 'key': 'person_tags', width: 40 },
    ];

    const headRow = worksheet.getRow(1)
    headRow.font = { bold: true }

    _.map(res.data, (row) => {
      row.person_info_capital = parseFloat(row.person_info_capital)
      row.person_birthday = formatDate(row.person_birthday, 'DD/MM/YYYY')
      row.person_info_open_date = formatDate(row.person_info_open_date, 'DD/MM/YYYY')
      row.person_created_at = formatDate(row.person_created_at, 'DD/MM/YYYY HH:mm')
      row.person_updated_at = formatDate(row.person_updated_at, 'DD/MM/YYYY HH:mm')
      row.person_tags = row.person_tags != null ? row.person_tags.join(', ') : ''
    })
    worksheet.addRows(res.data)

    worksheet.autoFilter = {
      from: {
        row: 1,
        column: 1
      },
      to: {
        row: worksheet.lastRow._number,
        column: worksheet.columnCount
      }
    }

    workbook.xlsx.writeBuffer().then(function (data) {
      var blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      saveAs(blob, `pessoas-${moment().format()}.xlsx`);
    });


    // } catch (e) {
    //   dealWithError('Exportar', e)
    // }
  }

  renderPersonList(persons, personsPagination) {
    let { sortedInfo, filteredInfo } = this.state;
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    var columns = [
      {
        title: 'Classificação',
        dataIndex: 'person_id',
        key: 'person_tags',
        ellipsis: true,
        filteredValue: filteredInfo.person_tags || null,
        sortOrder: sortedInfo.columnKey === 'person_tags' && sortedInfo.order,

        filters: [
          { "text": "Associado", "value": ["Associado"] },
          { "text": "Cliente", "value": ["Cliente"] },
          { "text": "Fornecedor", "value": ["Fornecedor"] },
          { "text": "Funcionário", "value": ["Funcionário"] }
        ],
        onFilter: (value, row) => _.intersection(value, row.person_tags).length > 0,
        sorter: (a, b) => _.join(_.sortBy(a.person_tags)).localeCompare(_.join(_.sortBy(b.person_tags))),

        render: (tags, row) => _.isArray(row.person_tags) && (
          <Link className="ei-tag-render" to={`/persons/${row.person_id}`}>
            {row.person_tags.map(tag => (
              <Tag key={`tags-${row.person_id}-${tag}`} className={`ei-tag-${tag}`}>{tag}</Tag>
            ))}
          </Link>
        )
      },
      {
        title: 'Documento',
        dataIndex: 'person_document',
        // filters: persons.map((note) => { return { "text": note.person_document, "value": note.person_document } }),
        // onFilter: (value, record) => record.person_document.indexOf(value) === 0,
        sorter: (a, b) => a.person_document.localeCompare(b.person_document),
        render: (text, row) => <Link to={`/persons/${row.person_id}`}>{text}</Link>
      },
      // {
      //   title: 'Razão Social / Nome Completo',
      //   dataIndex: 'person_name',
      //   // filters: persons.map((note) => { return { "text": note.name, "value": note.name } }),
      //   // onFilter: (value, record) => record.name.indexOf(value) === 0,
      //   sorter: (a, b) => a.person_name.localeCompare(b.person_name),
      //   render: (text, row) => <Link to={`/persons/${row.person_id}`}>{text}</Link>
      // },
      {
        title: 'Nome Fantasia / Apelido',
        dataIndex: 'person_nicename',
        ellipsis: true,
        // filters: persons.map((note) => { return { "text": note.nicename, "value": note.nicename } }),
        // onFilter: (value, record) => record.nicename.indexOf(value) === 0,
        sorter: (a, b) => a.person_nicename.localeCompare(b.person_nicename),
        render: (text, row) => <Link to={`/persons/${row.person_id}`}>{text}</Link>
      },
      {
        title: 'E-mail',
        dataIndex: 'person_email',
        ellipsis: true,
        // filters: persons.map((note) => { return { "text": note.email, "value": note.email } }),
        // onFilter: (value, record) => record.email.indexOf(value) === 0,
        sorter: (a, b) => a.person_email.localeCompare(b.person_email),
        render: (text, row) => <Link to={`/persons/${row.person_id}`}>{text}</Link>
      },
      // {
      //   title: 'Data Criação',
      //   dataIndex: 'person_created_at',
      //   sorter: (a, b) => moment(a.person_created_at) - moment(b.person_created_at),
      //   render: (text) => <span>{moment(text).fromNow()}</span>
      // },
      // {
      //   title: 'Data Alteração',
      //   dataIndex: 'person_updated_at',
      //   defaultSortOrder: 'descend',
      //   sorter: (a, b) => moment(a.person_updated_at) - moment(b.person_updated_at),
      //   render: (text) => <span>{moment(text).fromNow()}</span>
      // },
      {
        title: <Icon type="info-circle" />,
        width: 50,
        render: (_text, row) => <Popover placement="bottomRight" content={
          <Card
            style={{ width: 300 }}
            size="small"
            bordered={false}
          // actions={[
          //   <span><Icon type="check" /> Pagar agora</span>,
          //   <span><Icon type="edit" key="edit" /> Editar</span>,
          // ]}
          >
            <div>
              <Meta
                title="Detalhes"
                description={<span>Criado em {moment(row.person_created_at).format('DD/MM/YYYY HH:mm')}, mas foi alterado em {moment(row.person_updated_at).format('DD/MM/YYYY HH:mm')}</span>}
              />
            </div>
          </Card>
        } trigger="click">
          <Icon type="more" />
        </Popover>
      }
    ]

    return (
      <div>
        <div className="ei-table-actions">
          <Dropdown.Button type="primary" overlay={(
            <Menu>
              <Menu.Item key="1" onClick={this.doExport}>
                Exportar para Excel
              </Menu.Item>
              <Menu.Item key="2">
                <Link to="/persons/import">
                  Importar planilha
                </Link>
              </Menu.Item>
            </Menu>
          )}>
            <Link to="/persons/new">
              <Icon type="plus" />
              <span className="nav-text">Novo cadastro</span>
            </Link>
          </Dropdown.Button>
        </div>
        <Table
          bordered
          columns={columns}
          rowKey='person_id'
          dataSource={persons}
          onChange={this.onChange}
          pagination={{
            'position': 'both'
          }}
        />
      </div>
    )
    // return [{}].concat(persons).map(
    //   (note, i) =>
    //     i !== 0
    //       ? <LinkContainer
    //           key={note.noteId}
    //           to={`/persons/${note.noteId}`}
    //         >
    //           <ListGroupItem header={note.content.trim().split("\n")[0]}>
    //             {"Created: " + new Date(note.createdAt).toLocaleString()}
    //           </ListGroupItem>
    //         </LinkContainer>
    //       : <LinkContainer
    //           key="new"
    //           to="/persons/new"
    //         >
    //           <ListGroupItem>
    //             <h4>
    //               <b>{"\uFF0B"}</b> Criar nova anotação 2
    //             </h4>
    //           </ListGroupItem>
    //         </LinkContainer>
    // )
  }

  render() {
    return (
      <div className="persons">
        {!this.state.isLoading && this.renderPersonList(this.state.persons, this.state.personsPagination)}
      </div>
    )
  }
}

import React, { Component } from "react";
import { API } from "aws-amplify";
import { Form, Input, Button, Popconfirm, message, Upload, Spin, Icon, Select } from 'antd'
import LoaderButton from "../../components/LoaderButton";
import _ from 'loadsh'

import { dealWithError, EIAPIHelper } from "../../libs/ei-helpers"
import { s3Upload, s3GetDownloadURL } from '../../libs/awsLib'
import config from "../../config"

import "./BankAccounts.css"

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const EIAPI = new EIAPIHelper()

class BankAccountsFormComponent extends React.Component {
	_isMounted = false
	params = {}
	state = {
		isLoading: false,
		isFetchingBanks: true,

		data: {},
		banks: [],

		attachments: [],
		newAttachments: []
	}

	constructor(props) {
		super(props);

		this.params = this.props.match.params

		this.props.appSetTitle('Conta Corrente')
	}

	async componentDidMount() {
		this._isMounted = true;

		try {
			await this.getData()
		} catch (e) {
			dealWithError("componentDidMount", e)
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	async getData() {
		const id = typeof this.params.id !== "undefined" ? this.params.id : "new"
		let data = {}

		this.setState({ isLoading: true })

		if (this.state.banks.length === 0) {
			EIAPI.get(`/banks`).then((res) => {
				if (typeof res.data === 'object') {
					this.setState({
						banks: res.data,
						isFetchingBanks: false
					})
				}
			})
		}

		if (id !== "new")
			data = await EIAPI.get(`/bank-accounts/${id}`)

		if (this._isMounted) {
			const attachments = await s3GetDownloadURL(data.attachments || []);

			data.account_remind_at = data.account_remind_at !== null && data.account_remind_at !== '0000-00-00 00:00:00' ? moment(data.account_remind_at) : ''

			this.setState({
				data,
				attachments
			})

			this.setState({ isLoading: false })
			this.updateData()
		}
	}

	updateData(objKeys) {
		const keys = objKeys || this.props.form.getFieldsValue()
		Object.keys(keys).forEach(key => {
			if (key !== 'key' && key !== 'keys') {
				const obj = {
					[key]: this.state.data[key]
				}
				this.props.form.setFieldsValue(obj)
			}
		})
	}

	async saveItem(data) {
		const id = typeof this.params.id !== "undefined" ? this.params.id : "new"
		const postData = _.merge(data, {
			account_id: this.state.account_id,
			newAttachments: this.state.newAttachments
		})

		// if (postData.account_remind_at !== 'undefined')
		// 	postData.account_remind_at = moment(postData.account_remind_at).toJSON()

		if (id !== "new")
			return await EIAPI.put(`/bank-accounts/${this.params.id}`, {
				body: postData
			})
		else
			return await EIAPI.post(`/bank-accounts/`, {
				body: postData
			})
	}

	async deleteNote() {
		return API.del("ei-api", `/bank-accounts/${this.params.id}`);
	}

	handleSubmit = async event => {
		event.preventDefault()

		this.props.form.validateFieldsAndScroll(async (err, values) => {
			if (!err) {
				this.setState({ isLoading: true })
				try {
					const res = await this.saveItem(values)
					if (res.status !== 'success')
						throw new Error('Não foi possível salvar o registro :-(')

					this.props.history.push("/bank-accounts/")
				} catch (e) {
					dealWithError('BankAccounts.handleSubmit', e)
					this.setState({ isLoading: false })
				}
			}
		})

		return false
	}

	handleDelete = async event => {
		event.preventDefault();

		this.setState({ isDeleting: true });

		try {
			await this.deleteNote();
			this.props.history.push("/bank-accounts");
		} catch (e) {
			console.error(e)
			message.error("Ops! Não foi possível excluir este item :-(")
			this.setState({ isDeleting: false })
		}
	}

	customRequest = async ({ onSuccess, onError, file }) => {
		if (file && file.size > config.MAX_ATTACHMENT_SIZE) {
			message.info(`O tamanho máximo de anexos é de ${config.MAX_ATTACHMENT_SIZE / 1000000} MB.`);
			return;
		}

		try {
			if (file) {
				const storedKey = await s3Upload(file)

				const fileData = {
					upload_id: file.uid,
					attachment_name: file.name,
					attachment_size: file.size,
					attachment_content_type: file.type,
					attachment_store_key: storedKey
				}

				this.setState({
					newAttachments: [...this.state.newAttachments, fileData]
				})

				onSuccess(file.storedKey, file)
			}
		} catch (e) {
			dealWithError('BankAccounts.customRequest', e)
			onError(e.message)
		}
	}

	onAttachmentRemove = async event => {
		const upload_id = event.uid
		const attachments = this.state.newAttachments.filter(file => file.upload_id !== upload_id)
		this.setState({
			newAttachments: attachments
		})
		EIAPI.del(`/attachments/bank-accounts/${this.params.id}/${upload_id}`);
	}

	render() {
		const { TextArea } = Input
		const attachments = this.state.attachments

		const { getFieldDecorator } = this.props.form

		return (
			<div className="BankAccounts" >
				{this.state.isLoading && (
					<div className="up-spin-area">
						<Spin />
					</div>
				)}
				{!this.state.isLoading && (
					<Form {...config.FORM_LAYOUT} onSubmit={this.handleSubmit}>
						<Form.Item label="Instituição">
							{getFieldDecorator('bank_id', {
								rules: [
									{
										required: true,
										message: 'O nome da conta é obrigatório. Coloque um nome fácil, pois ele será usado como referência no cadastro das despesas...',
									},
								],
							})(<Select
								showSearch
								// disabled={isExpenseBlocked}
								// style={this.props.style}
								hasFeedback
								defaultActiveFirstOption={false}
								showArrow={true}
								filterOption={true}
								// onSearch={this.fetchPersons}
								// onChange={this.handleChange}
								// notFoundContent={this.state.personListStatus || 'Comece a digitar para pesquisar por um fornecedor...'}
								loading={this.state.isFetchingBanks}
								validateStatus={this.state.isFetchingBanks ? "validating" : "valid"}
								optionLabelProp="label"
							>
								{this.state.banks.map(d => (
									<Select.Option key={d.bank_id} value={d.bank_id} label={(`${d.bank_nro} - ${d.bank_name} [${d.bank_type_name}]`)}>
										<div>
											{/* <Avatar shape="square" size="large" style={{ marginRight: 5 }} icon={} /> */}
											<span style={{ float: 'right' }}>{d.bank_type_name}</span>
											<div className="bank-small-icon">
												<img src={(`/${d.bank_icon}`)} alt={d.bank_nro} />
											</div>
											<strong>{d.bank_nro}<br/>{d.bank_name}</strong>
										</div>
									</Select.Option>
								))}
							</Select>)}
						</Form.Item>
						<Form.Item label="Nome da Conta">
							{getFieldDecorator('account_name', {
								rules: [
									{
										required: true,
										message: 'O nome da conta é obrigatório. Coloque um nome fácil, pois ele será usado como referência no cadastro das despesas...',
									},
								],
							})(<Input
								id="account_name"
								type="text"
								placeholder="Ex: Itaú"
							/>)}
						</Form.Item>
						<Form.Item label="Agência">
							{getFieldDecorator('account_agency', {
								rules: [
								],
							})(<Input
								id="account_agency"
								type="text"
								placeholder="0000-0"
							/>)}
						</Form.Item>
						<Form.Item label="Número da Conta">
							{getFieldDecorator('account_nro', {
								rules: [
								],
							})(<Input
								id="account_nro"
								type="text"
								placeholder="0000000-0"
							/>)}
						</Form.Item>
						<Form.Item label="Observações">
							{getFieldDecorator('account_description', {
								rules: [
								],
							})(<TextArea
								id="account_description"
								autoSize={{ minRows: 4, maxRows: 15 }}
							/>)}
						</Form.Item>
						{/* <Form.Item label="Lembrar em">
							{getFieldDecorator('account_remind_at', {
							})(<DatePicker
								id="account_remind_at"
								allowClear
								showTime={{ format: 'HH:mm' }}
								format="DD/MM/YYYY HH:mm"
								placeholder="Quer ser lembrado dessa tarefa em uma data específica?" />)}
						</Form.Item> */}
						<Form.Item label="Anexo">
							<Upload id="upload-field"
								multiple={true}
								customRequest={this.customRequest}
								defaultFileList={attachments}
								onRemove={this.onAttachmentRemove}
							>
								<Button>
									<Icon type="upload" /> Enviar anexos
								</Button>
							</Upload>
						</Form.Item>
						<Form.Item {...config.TAIL_FORM_ITEM_LAYOUT}>
							{typeof this.state.data.account_id !== 'undefined' &&
								<Popconfirm
									title="Tem certeza que deseja excluir?"
									onConfirm={this.handleDelete}
									okText="Sim"
									cancelText="Não"
									className="up-right-button"
								>
									<Button type="danger" ghost>Excluir</Button>
								</Popconfirm>
							}
							<LoaderButton
								type="primary"
								htmlType="submit"
								loading={this.state.isLoading}
								text="Salvar"
								loadingText="Salvando..."
							/>
						</Form.Item>
					</Form>
				)}
			</div>
		)
	}
}

export default class BankAccountsForm extends Component {
	MainForm = Form.create({ name: 'form' })(BankAccountsFormComponent);

	render() {
		const childProps = {
			// isAuthenticated: this.state.isAuthenticated,
			// isAppLoading: this.state.isAppLoading,
			match: this.props.match,
			history: this.props.history,
			appSetTitle: this.props.appSetTitle
		}

		return (
			<this.MainForm {...childProps} />
		)
	}
}
import { Alert, Row, Col, Button, Card, Form, Spin, Table } from "antd";
import React, { Component } from "react";
// import config from "../../config";
import { s3GetDownloadURL, s3GetPublic } from "../../libs/awsLib";
import { formatDate, dealWithError, EIAPIHelper, formatDateTime } from "../../libs/ei-helpers"; // , getStepCascade
import _ from 'loadsh';

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');


const EIAPI = new EIAPIHelper()

class PTAFilesViewComponent extends React.Component {
    _isMounted = false;
    params = {};
    state = {
        isLoading: false,
        isSaving: false,
        pta: {},
        ptaId: 0,
        downloadLink: '',

        attachments: [],
        newAttachments: []
    }

    constructor(props) {
        super(props);

        this.params = this.props.match.params;

        this.props.appSetTitle("PTA 2020 - Resumo Gerencial");
    }

    async componentDidMount() {
        this._isMounted = true;

        try {
            await this.getData()
        } catch (e) {
            dealWithError("componentDidMount", e)
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getData() {
        if (this._isMounted) {
            const ptaId = typeof this.params.ptaId !== "undefined" ? this.params.ptaId : undefined;

            this.setState({
                ptaId: ptaId,
                isLoading: true
            })

            const res = await EIAPI.get(`/crea/pta-submit/${ptaId}`)
            const data = res.data

            const attachments = typeof data !== 'undefined' ? await s3GetDownloadURL(data.attachments) : [];
            const downloadLink = await s3GetPublic(data.pta_final_url);

            this.setState({
                pta: data,
                attachments: attachments,
                downloadLink: downloadLink
            })

            this.setState({ isLoading: false })
        }
    }

    render() {
        const pta = this.state.pta || {}

        const downloadLink = this.state.downloadLink;

        const columns = [
            {
                key: 'description',
                title: 'Descrição',
                render: (text, row) => {
                    if (row.uid === pta.expense_pta_invoice_id)
                        return "Documento Fiscal";
                    else if (row.uid === pta.expense_pta_receipt_id)
                        return "Comprovante pagamento";
                    else if ([pta.expense_pta_provider1_propose_id, pta.expense_pta_provider2_propose_id, pta.expense_pta_provider3_propose_id].includes(row.uid))
                        return "Orçamento";
                    else
                        return "Outros documentos";
                }
            },
            {
                key: 'name',
                title: 'Arquivo',
                dataIndex: 'name'
            },
            {
                key: 'size',
                title: 'Tamanho',
                dataIndex: 'size',
                render: (text) => Math.floor(text / 1024) + " KB"
            },
            {
                key: 'createdAt',
                title: 'Data',
                dataIndex: 'createdAt',
                render: (text) => formatDateTime(text)
            },
            {
                key: 'actions',
                title: 'Ações',
                dataIndex: 'actions',
                render: (text, row) => <div>
                    <Button key={row.uid} icon="file-search" type="link" href={row.url} target="_blank">Visualizar</Button>
                </div>
            }
        ];

        const expenseAttachments = _.orderBy(this.state.attachments, 'name');

        const postData = async (form) => {
            this.setState({
                isSaving: true
            })

            const data = {
                pta_id: this.state.ptaId,
                pta_received: 1
            }

            return await EIAPI.put(`/crea/pta-submit/${this.state.ptaId}`, {
                body: data
            }).then(() => {
                this.setState({
                    isSaving: false
                })
                this.getData();
            })
        }

        return (
            <div className="Expenses">
                {this.state.isLoading && (
                    <div className="up-spin-area">
                        <Spin />
                    </div>
                )}
                {!this.state.isLoading && (
                    <div>
                        {typeof pta.entity !== 'undefined' && (
                            <Card title="Dados da Entidade">
                                <Row gutter={[12, 12]}>
                                    <Col lg={24}>
                                        <h3>
                                            {pta.entity.person_document}<br />
                                            {pta.entity.person_name}
                                        </h3>
                                        <div>
                                            {pta.entity.person_address.address_street}, {pta.entity.person_address.address_number}<br />
                                            {pta.entity.person_address.address_city} - {pta.entity.person_address.address_state}<br />
                                            {pta.entity.person_address.address_zipcode}
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        )}

                        <Card title="Anexos" style={{ marginTop: 15 }}>
                            <Table
                                pagination={false}
                                bordered
                                dataSource={expenseAttachments}
                                columns={columns}
                                rowKey="attachment_id"
                            />
                            {(pta.pta_signed === 1 && !_.isEmpty(downloadLink)) && (
                            <Button
                                type="default"
                                icon="download"
                                size="large"
                                block
                                style={{ marginTop: 15, margimBottom: 15 }}
                                href={downloadLink}
                                target="_blank"
                            >
                                Fazer download do pacote com todos anexos
                            </Button>
                        )}
                        </Card>
                        {pta.pta_signed === 1 && pta.pta_received === 0 && (
                            <Button
                                type="primary"
                                icon="check"
                                size="large"
                                block
                                style={{ marginTop: 15, margimBottom: 15 }}
                                onClick={postData}
                            >
                                Confirmar recebimento
                            </Button>
                        )}
                        {pta.pta_signed === 1 && pta.pta_received === 1 && (
                            <Alert
                                message="Documentação Recebida pelo CREA"
                                description={
                                    <div>
                                        <p>Recebimento confirmado: {formatDate(pta.pta_received_date)}</p>
                                    </div>
                                }
                                showIcon
                                type="success"
                                style={{ marginTop: 20 }}
                            />
                        )}
                    </div>
                )
                }
            </div>
        )
    }
}

export default class PTAFilesView extends Component {
    MainForm = Form.create({ name: "form" })(PTAFilesViewComponent);

    render() {
        const childProps = {
            // isAuthenticated: this.state.isAuthenticated,
            // isAppLoading: this.state.isAppLoading,
            match: this.props.match,
            history: this.props.history,
            appSetTitle: this.props.appSetTitle
        };

        return <this.MainForm {...childProps} />;
    }
}

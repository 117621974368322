import React from "react";
import { Input, InputNumber } from 'antd'
import NumberFormat from 'react-number-format'

const InputGroup = Input.Group

class InputCurrency extends InputNumber {
  formatBrazil(value) {
    // console.log('InputCurrency->formatBrazil', value, Number.parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }))
    return value.toLocaleString('pt-BR', { style: 'decimal' })
  }

  render() {
    return (
      <InputGroup compact>
        <Input style={{ width: 43 }} disabled value="R$" />
        <div className="ant-input-number" style={{ width: 'calc(100% - 48px)' }}>
          <div className="ant-input-number-input-wrap">
            <NumberFormat
              style={{ border: 0, outline: 0, height: 30 }}
              decimalScale={2}
              fixedDecimalScale={true}
              thousandSeparator="."
              decimalSeparator=","
              className="ant-input"
              inputMode="numeric"

              {...this.props}
            />
          </div>
        </div>
      </InputGroup>
    )
  }
}

        // {/* <Input
        //   style={{ width: 'calc(100% - 48px)' }}
        //   // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
        //   // parser={value => this.parseLocaleNumber(value)}
        //   // formatter={value => value.toLocaleString('pt-BR', { style: 'decimal' })}
        //   {...this.props}
        // /> */}
        // {/* <Input
        //       style={{ width: 'calc(100% - 48px)' }}
        //       onChange={this.formatBrazil}
        //       // decimalSeparator=","
        //       // formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')}
        //       // parser={value => this.parseLocaleNumber(value)}
        //       // formatter={value => value.toLocaleString('pt-BR', { style: 'decimal' })}
        //       {...this.props}
        //     /> */}

export default InputCurrency

import React, { Component } from "react"
// import { API } from "aws-amplify"
import "./Persons.css";
import { EIAPIHelper, parsePhoneNumber } from "../../libs/ei-helpers"
import { Table, Progress, Alert, Result, Icon } from 'antd'
// import { Link } from "react-router-dom"

// import _ from 'loadsh'
// import ls from 'local-storage'
import Excel from "exceljs"

import moment from 'moment'
import 'moment/locale/pt-br'
moment.locale('pt-br')

const EIAPI = new EIAPIHelper()

export default class Persons extends Component {
  constructor(props) {
    super(props)

    props.appSetTitle(props.pageTitle || 'Cadastro de Pessoas')

    this.state = {
      isLoading: true,
      isImporting: false,
      persons: [],
      errors: [],
      importCount: 0,
      // pageFilter: props.filters || {},
      // filteredInfo: ls.get('persons.filteredInfo') || null,
      // sortedInfo: ls.get('persons.sortedInfo') || null
    }
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return
    }
  }

  doImport = async () => {
    const persons = this.state.persons
    const errors = this.state.errors

    this.setState({
      isImporting: true
    })

    await persons.forEach(async person => {
      const postData = JSON.parse(JSON.stringify(person))
      delete postData.person_import_status

      try {
        let res = await EIAPI.post(`/persons/`, {
          body: postData
        })
        if (res.error) {
          person.person_import_status = 'error';
          errors.push({
            'row_num': person.person_import_id,
            'description': res.error
          })
        } else {
          person.person_import_status = 'success';
        }

      } catch (error) {
        person.person_import_status = 'error';
        errors.push({
          'row_num': person.person_import_id,
          'description': 'Erro de comunicação'
        });
      }
      
      this.setState({
        persons: persons,
        errors: errors,
        importCount: this.state.importCount + 1
      })
    })
  }

  handleDragOver(e) {
    e.preventDefault();
  }

  _doParseFile(file) {
    if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      const wb = new Excel.Workbook();
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const buffer = reader.result;
        wb.xlsx.load(buffer).then(workbook => {
          const persons = []

          const sheet = workbook.getWorksheet(1); // Clientes
          sheet.eachRow((row, rowIndex) => {
            if (rowIndex >= 6
              && typeof row.values[3] === 'string' && row.values[3].trim() !== ''
              && typeof row.values[4] === 'string' && row.values[4].trim() !== ''
            ) {
              const person = {
                "person_import_id": row.values[2],
                "person_import_status": "new",
                "person_type": (row.values[3] === 'Física' ? 'NATURAL' : 'LEGAL'),
                "person_document": row.values[4],
                "person_name": row.values[5],
                "person_nicename": row.values[6],
                "person_email": (typeof row.values[11] === 'object' ? row.values[11].text : row.values[11]),
                "person_tags": [],
                "person_address": {
                  "address_street": row.values[12],
                  "address_number": row.values[13],
                  "address_district": row.values[14],
                  "address_complement": row.values[15],
                  "address_state": row.values[16],
                  "address_city": row.values[17],
                  "address_zipcode": row.values[18]
                },
                "person_phones": [
                  parsePhoneNumber(row.values[19] + ' ' + row.values[20])
                ]
              }

              if (row.values[7] === 'Sim')
                person.person_tags.push('Associado')
              if (row.values[8] === 'Sim')
                person.person_tags.push('Cliente')
              if (row.values[9] === 'Sim')
                person.person_tags.push('Fornecedor')
              if (row.values[10] === 'Sim')
                person.person_tags.push('Funcionário')

              persons.push(person)
            }
          })

          this.setState({
            persons: persons,
            isLoading: false
          })

        })
      }
    }
  }

  handleDrop(e) {
    e.preventDefault();

    if (typeof e.dataTransfer !== 'undefined' && typeof e.dataTransfer.files !== 'undefined' && e.dataTransfer.files.length > 0) {
      const file = e.dataTransfer.files[0];
      this._doParseFile(file);
    }
  }

  handleFileChange(e) {
    console.log('handleFileChange', e);
    const file = document.getElementById("xlf").files[0];
    this._doParseFile(file)
  }

  renderImportFile() {
    return (
      <Result
        icon={<Icon type="tool" theme="twoTone" />}
        title="Através dessa ferramenta é possível cadastrar em massa seus associados, clientes, fornecedores e funcionários."
        extra={<div>
          <div id="upload-pre-reqs">
            <div><a href="https://app.entidadeintegrada.com.br/drive/EI-ImportacaoPessoas-v1_01.xlsx">Baixe aqui a planilha-modelo</a> que deve ser utilizada na importação</div>
            <div>Preencha todas as informações - fique atento nos campos obrigatórios</div>
            <div>Importe a planilha aqui mesmo, arrastando na área destacada ou selecionando o arquivo do seu computador</div>
          </div>
          <div id="upload-drop"
            onDragOver={(e) => this.handleDragOver(e)}
            onDrop={(e) => this.handleDrop(e)}
          >
            Arraste o arquivo aqui para iniciar a importação
        </div>
          <div id="upload-field">
            <div>
              <input type="file" name="xlfile" id="xlf" onChange={(e) => this.handleFileChange(e)} />
            </div>
            <span>... ou clique aqui para selecionar um arquivo.</span>
          </div>
        </div>}
      />
    )
  }

  renderStatusBar(personsCount, importCount, errorCount) {
    return (
      <div style={{
        margin: 20
      }}>
        <Progress
          percent={importCount / personsCount * 100}
          status={importCount === personsCount ? (errorCount > 0 ? "exception" : "success") : "active"}
          successPercent={0}
        />
        <div>Importando <span className="text-bold">{importCount}</span> de <span className="text-bold">{personsCount}</span> registros.</div>
      </div>
    )
  }

  renderPersonList(persons, errors) {
    // let { sortedInfo, filteredInfo } = this.state;
    // sortedInfo = sortedInfo || {};
    // filteredInfo = filteredInfo || {};

    var columns = [
      {
        title: 'Status',
        dataIndex: 'person_import_status',
        key: 'person_import_status',
        render: (text, row) => {
          let statusLabel = '-'
          if (text === "new") {
            statusLabel = 'Novo'
          } else if (text === 'success') {
            statusLabel = 'Cadastrado'
          } else if (text === 'error') {
            statusLabel = 'Erro'
          }

          return <div>
            {statusLabel}
          </div>
        }
      },
      {
        title: 'Documento',
        dataIndex: 'person_document',
        key: 'person_document',
        render: (text, row) => <div>
          <div className="text-bold">{row.person_document}</div>
          <small>{row.person_type === 'LEGAL' ? 'Jurídica' : 'Física'}</small>
        </div>
      },
      {
        title: 'Razão Social / Nome Fantasia',
        dataIndex: 'person_name',
        render: (text, row) => <div>
          <div className="text-bold">{row.person_name}</div>
          <small>{row.person_nicename}</small>
        </div>
      },
      {
        title: 'E-mail',
        dataIndex: 'person_email',
        ellipsis: true,
      },
      {
        title: 'Endereço',
        dataIndex: 'person_address',
        ellipsis: true,
        render: (text, row) => <div>
          <div>{row.person_address.address_street}, {row.person_address.address_number}</div>
          <div>{row.person_address.address_city} - {row.person_address.address_state}</div>
          <div>{row.person_address.address_zipcode}</div>
        </div>
      },
      {
        title: 'Telefone',
        dataIndex: 'person_phones',
        ellipsis: true,
        render: (phone, row) => (phone.length > 0 ? (<div>
          <div>{phone[0].phone_area_code + ' ' + phone[0].phone_number}</div>
          <small>{phone[0].phone_description}</small>
        </div>
        ) : '')
      },
    ]

    const ErrorList = ({ errorList }) => {
      if (errorList.length > 0)
        return (
          <Alert
            message="Lista de erros"
            description={
              <div>
                <p>Ocorreram erros durante a importação da planilha, favor revisar e criar uma nova planilha apenas com os registros abaixo:</p>
                <ul className="error-list">
                  {errorList.map(err => {
                    return (<li key={err.row_num}>
                      <span className="text-bold">Linha {err.row_num}</span>: {err.description}
                    </li>)
                  })}
                </ul>
              </div>
            }
            type="error"
            showIcon
          />
        );

      return <div />
    }

    return (
      <div>
        <div className="ei-table-actions">
          <button type="button" className="ant-btn ant-btn-primary" onClick={this.doImport} disabled={this.state.isImporting}>
            {/* <Icon type="plus" /> */}
            <span className="nav-text">Importar todas as linhas abaixo</span>
          </button>
        </div>
        <ErrorList errorList={this.state.errors} />
        <Table
          bordered
          columns={columns}
          rowKey='person_import_id'
          dataSource={persons}
          pagination={{
            'position': 'both'
          }}
        />
      </div >
    )
  }

  render() {
    return (
      <div className="persons">

        {!this.state.isLoading && this.state.isImporting && this.renderStatusBar(this.state.persons.length, this.state.importCount, this.state.errors.length)}
        {!this.state.isLoading && this.renderPersonList(this.state.persons, this.state.errors)}
        {this.state.isLoading && this.renderImportFile()}
      </div>
    )
  }
}

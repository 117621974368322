import { Auth } from "aws-amplify";
import React, { Component } from "react";
// import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import LoaderButton from "../components/LoaderButton";
import { dealWithError } from "../libs/ei-helpers";
import "./Login.css";
import {
  Form,
  Icon,
  Input,
  Result,
  Spin,
  Alert,
  Button,
  Modal,
  message,
} from "antd";

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      passwordChallenge: false,
      email: "",
      password: "",
      new_password: "",
      email_recovery_password: "",
      forgotPassword: false,
      showRecoveryCode: false,
      recovery_code: "",
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: false });
  }

  validateForm = (e) => {
    return this.state.email.length > 0 && this.state.password.length > 0;
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      const passwordChallenge = this.state.passwordChallenge;
      const newPassword = this.state.new_password;

      const user = await Auth.signIn({
        username: this.state.email,
        password: this.state.password,
      });
      if (
        user.challengeName === "NEW_PASSWORD_REQUIRED" &&
        !passwordChallenge
      ) {
        this.setState({ passwordChallenge: true, isLoading: false });
      } else {
        if (passwordChallenge) {
          await Auth.completeNewPassword(user, newPassword, {});

          await Auth.signIn({
            username: this.state.email,
            password: newPassword,
          });
        }

        this.props.userHasAuthenticated(true);
      }
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        // TODO: deal with UserNotConfirmedException
        // this.changeState('confirmSignUp', { username });
      } else if (err.code === "PasswordResetRequiredException") {
        this.setState({ passwordChallenge: true, isLoading: false });
        // logger.debug('the user requires a new password');
        // this.setState({ 'newPasswordChallenge')];
      } else {
        // this.error(err);
        dealWithError("Login", err);
      }

      this.setState({ isLoading: false });
    }
  };

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  handleForgot = () => {
    this.setState({
      forgotPassword: true,
    });
  };

  recoveryPassword = () => {
    if (!this.state.email_recovery_password) {
      message.error("E-mail precisa ser preenchido.");
    } else {
      !this.state.showRecoveryCode
        ? Auth.forgotPassword(this.state.email_recovery_password)
            .then((data) => {
              this.setState({ showRecoveryCode: true });
            })
            .catch((err) => dealWithError("Recovery", err))
        : Auth.forgotPasswordSubmit(
            this.state.email_recovery_password,
            this.state.recovery_code,
            this.state.new_password
          )
            .then((data) => {
              message.success("Senha alterada com sucesso.", 10);
              this.setState({
                forgotPassword: false,
                showRecoveryCode: false,
              });
            })
            .catch((err) => {
              this.setState({
                forgotPassword: false,
                showRecoveryCode: false,
              });
              dealWithError("Recovery", err);
            });
    }
  };

  renderLoading() {
    return (
      <div className="lander">
        <Result
          icon={
            <img
              src="/imgs/ei_logo.png"
              className="logo"
              alt="Entidade Integrada"
            />
          }
          extra={
            <div className="up-spin-area">
              <Spin size="large" />
            </div>
          }
        />
      </div>
    );
  }

  renderForm() {
    const newpwd = this.state.new_password;
    const pwdCheck1 = newpwd.length >= 8;
    const pwdCheck2 = newpwd.match(/[A-Z]/) && newpwd.match(/[a-z]/);
    const pwdCheck3 = newpwd.match(/[0-9]/);
    const pwdCheck4 = newpwd.match(/\W/);

    return (
      <>
        <Form onSubmit={this.handleSubmit} className="login-form">
          <img
            src="/imgs/ei_logo.png"
            className="logo"
            alt="Entidade Integrada"
          />
          <Form.Item>
            <Input
              id="email"
              type="text"
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="E-mail"
              // value={this.state.email}
              onChange={this.handleChange}
              disabled={this.state.passwordChallenge}
            />
          </Form.Item>
          <Form.Item>
            <Input
              id="password"
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Senha"
              // value={this.state.password}
              onChange={this.handleChange}
              disabled={this.state.passwordChallenge}
            />
          </Form.Item>
          {this.state.passwordChallenge && (
            <Alert
              style={{ paddingTop: 25, marginBottom: 20 }}
              message={
                <div>
                  <div>
                    Antes de continuar, você precisa cadastrar uma nova senha:
                  </div>
                  <Form.Item>
                    <Input
                      id="new_password"
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      type="password"
                      placeholder="Nova senha"
                      autocomplete="new-password"
                      // value={this.state.password}
                      onChange={this.handleChange}
                    />
                  </Form.Item>
                  Por questões de segurança, sua senha precisa conter:
                  <div style={{ marginLeft: 8, padding: 4 }}>
                    <Icon
                      type={pwdCheck1 ? "check-circle" : "stop"}
                      theme="twoTone"
                      twoToneColor={pwdCheck1 ? "#52c41a" : "red"}
                    />{" "}
                    No mínimo 8 caracteres
                  </div>
                  <div style={{ marginLeft: 8, padding: 4 }}>
                    <Icon
                      type={pwdCheck2 ? "check-circle" : "stop"}
                      theme="twoTone"
                      twoToneColor={pwdCheck2 ? "#52c41a" : "red"}
                    />{" "}
                    Letras minúsculas e maiúsculas
                  </div>
                  <div style={{ marginLeft: 8, padding: 4 }}>
                    <Icon
                      type={pwdCheck3 ? "check-circle" : "stop"}
                      theme="twoTone"
                      twoToneColor={pwdCheck3 ? "#52c41a" : "red"}
                    />{" "}
                    Números
                  </div>
                  <div style={{ marginLeft: 8, padding: 4 }}>
                    <Icon
                      type={pwdCheck4 ? "check-circle" : "stop"}
                      theme="twoTone"
                      twoToneColor={pwdCheck4 ? "#52c41a" : "red"}
                    />{" "}
                    Pelo menos um caracter especial
                    <br />
                    (ex: !, $, #, %, &, etc)
                  </div>
                </div>
              }
              type="info"
            />
          )}
          <Form.Item>
            {/* {getFieldDecorator('remember', {
                valuePropName: 'checked',
                initialValue: true,
              })(<Checkbox>Remember me</Checkbox>)}
              <a className="login-form-forgot" href="">
                Forgot password
            </a> */}
            {/* <Button type="primary" htmlType="submit" className="login-form-button">
              Log in
            </Button> */}
            <LoaderButton
              block
              disabled={!this.validateForm()}
              htmlType="submit"
              isLoading={this.state.isLoading}
              text={
                this.state.passwordChallenge
                  ? "Alterar senha e entrar"
                  : "Entrar"
              }
              loadingText="Entrando…"
            />
            {/* Or <a href="">register now!</a> */}
          </Form.Item>
          <Form.Item>
            <Button block onClick={this.handleForgot}>
              Esqueci a Senha
            </Button>
          </Form.Item>
        </Form>
        <Modal
          closable={true}
          title="Esqueceu a senha?"
          visible={this.state.forgotPassword}
          onOk={this.recoveryPassword}
          onCancel={() => this.setState({ forgotPassword: false })}
          okText="Enviar"
        >
          <Form>
            {this.state.showRecoveryCode || (
              <Form.Item>
                <h3>Informe seu e-mail abaixo: </h3>
                <Input
                  id="email_recovery_password"
                  type="text"
                  prefix={
                    <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="E-mail"
                  onChange={this.handleChange}
                />
                <p style={{ marginTop: 10, marginBottom: 0 }}>
                  Vamos enviar um e-mail para recuperação da senha.
                </p>
              </Form.Item>
            )}
            {this.state.showRecoveryCode && (
              <>
                <div>
                  <div>Informe o código recebido por e-mail:</div>
                  <Input
                    style={{ marginTop: 5, marginBottom: 20 }}
                    id="recovery_code"
                    type="text"
                    prefix={
                      <Icon
                        type="number"
                        style={{ color: "rgba(0,0,0,.25)" }}
                      />
                    }
                    placeholder="Informe o código de verificação"
                    onChange={this.handleChange}
                  />
                  <div>
                    Antes de continuar, você precisa cadastrar uma nova senha:
                  </div>
                  <Form.Item>
                    <Input
                      id="new_password"
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: "rgba(0,0,0,.25)" }}
                        />
                      }
                      type="password"
                      placeholder="Nova senha"
                      autocomplete="new-password"
                      // value={this.state.password}
                      onChange={this.handleChange}
                    />
                  </Form.Item>
                  Por questões de segurança, sua senha precisa conter:
                  <div style={{ marginLeft: 8, padding: 4 }}>
                    <Icon
                      type={pwdCheck1 ? "check-circle" : "stop"}
                      theme="twoTone"
                      twoToneColor={pwdCheck1 ? "#52c41a" : "red"}
                    />{" "}
                    No mínimo 8 caracteres
                  </div>
                  <div style={{ marginLeft: 8, padding: 4 }}>
                    <Icon
                      type={pwdCheck2 ? "check-circle" : "stop"}
                      theme="twoTone"
                      twoToneColor={pwdCheck2 ? "#52c41a" : "red"}
                    />{" "}
                    Letras minúsculas e maiúsculas
                  </div>
                  <div style={{ marginLeft: 8, padding: 4 }}>
                    <Icon
                      type={pwdCheck3 ? "check-circle" : "stop"}
                      theme="twoTone"
                      twoToneColor={pwdCheck3 ? "#52c41a" : "red"}
                    />{" "}
                    Números
                  </div>
                  <div style={{ marginLeft: 8, padding: 4 }}>
                    <Icon
                      type={pwdCheck4 ? "check-circle" : "stop"}
                      theme="twoTone"
                      twoToneColor={pwdCheck4 ? "#52c41a" : "red"}
                    />{" "}
                    Pelo menos um caracter especial
                    <br />
                    (ex: !, $, #, %, &, etc)
                  </div>
                </div>
              </>
            )}
          </Form>
        </Modal>
      </>
    );
  }

  render() {
    const userHasAuthenticated = this.state.userHasAuthenticated;

    return (
      !userHasAuthenticated && (
        <div className="Login">
          {this.props.isAppLoading ? this.renderLoading() : this.renderForm()}
        </div>
      )
    );
  }
}
// export default class Login extends Component {
//   constructor(props) {
//     super(props);
//   }

//   render() {
//     const WrappedNormalLoginForm = Form.create({ name: 'login_form' })(NormalLoginForm);

//     return (
//       <WrappedNormalLoginForm childProps={this.state} />
//     );
//   }
// }

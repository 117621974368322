import { Button, DatePicker, Col, Form, Icon, Result, Row, Steps, Spin, Table, Typography } from 'antd';
import React, { Component } from "react";
import config from "../../config";
import { dealWithError, EIAPIHelper, base64toBlob } from "../../libs/ei-helpers";
import FileSaver from "file-saver";

import "../PTA/PTAComplete.css";

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const EIAPI = new EIAPIHelper()

const { MonthPicker } = DatePicker;
const { Step } = Steps
const { Paragraph } = Typography

class CDUCompleteComponent extends React.Component {
	_isMounted = false;
	params = {};
	state = {
		isLoading: true,
		isDownloading: false,
		confirmCheck: false,
		pack_id: "0742f704-9fa8-11eb-8587-167a37a95f75", // Cessão de Uso
		displayDate: moment(),
		data: {},
		files: {
			'pesquisa_satisfacao_usuario': [],
			'formulario_de_adequacao_do_pta_2020-oficial': [],
			'relatorio_de_cumprimento_do_objeto': [],
			'rfc-rdpm': [],
		}
	}

	constructor(props) {
		super(props);
		this.params = this.props.match.params;

		props.appSetTitle('Fechamento Cessão de Uso')
	}

	onDisplayChange = e => {
		this.setState({
			displayDate: e,
		}, this.getData);
	}

	async componentDidMount() {
		// if (!this.props.isAuthenticated) {
		// 	return;
		// }

		this._isMounted = true;

		try {
			const res = await EIAPI.get("/crea/pta-submit")
			const data = typeof res.data !== 'undefined' ? res.data : []

			this.setState({
				isLoading: false,
				confirmCheck: data.pta_finished === 1,
				data: data
			});
		} catch (e) {
			dealWithError('PTA Complete', e)
		}

	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	render() {
		const wrapperCol = {
			xs: {
				span: 24,
				offset: 0,
			},
			sm: {
				span: 22,
				offset: 1,
			},
		}

		const RenderPTALoading = (state) => {
			return (
				<div className="up-spin-area">
					<Spin size="large" />
				</div>
			)
		}

		const RenderCDUComplete = () => {
			// const customRequest = async ({ onSuccess, onError, file, data }) => {
			// 	if (file && file.size > config.MAX_ATTACHMENT_SIZE) {
			// 		message.info(`O tamanho máximo de anexos é de ${config.MAX_ATTACHMENT_SIZE / 1000000} MB.`);
			// 		return;
			// 	}

			// 	try {
			// 		if (file) {
			// 			const storedKey = await s3Upload(file)

			// 			const fileData = {
			// 				attachment_name: file.name,
			// 				attachment_size: file.size,
			// 				attachment_content_type: file.type,
			// 				attachment_store_key: storedKey
			// 			}

			// 			const files = this.state.files;
			// 			files[data.key] = [fileData];
			// 			this.setState({
			// 				files: files
			// 			})

			// 			// this.setState({
			// 			// 	newAttachments: [...this.state.newAttachments, fileData]
			// 			// })

			// 			onSuccess(file.storedKey, file)
			// 		}
			// 	} catch (e) {
			// 		dealWithError('CDUComplete.customRequest', e)
			// 		onError(e.message)
			// 	}
			// }

			const downloadFile = async (e) => {
				try {
					this.setState({
						isDownloading: true
					})

					const res = await EIAPI.get(`/metas/financial-report/export/${this.state.pack_id}/${this.state.displayDate.format('YYYY-MM')}`)
					if (typeof res.name === 'undefined' || typeof res.content === 'undefined' || typeof res.contentType === 'undefined')
						throw new Error('Ocorreu um erro ao gerar a planilha :-(');

					let blob = new Blob([base64toBlob(res.content, res.contentType)], {});
					FileSaver.saveAs(blob, res.name);

				} catch (e) {
					dealWithError('PTAComplete.DownloadFile', e);
				}

				this.setState({
					isDownloading: false
				})
			}


			// const genPTAfile = async (e) => {
			// 	try {
			// 		this.setState({
			// 			isDownloading: true
			// 		})
			// 		const res = await EIAPI.get("/crea/pta-download/")
			// 		console.log('result', res)
			// 		debugger;

			// 		// if (typeof res.name === 'undefined' || typeof res.content === 'undefined' || typeof res.contentType === 'undefined')
			// 		// 	throw new Error('Ocorreu um erro ao gerar a planilha :-(');

			// 		let blob = new Blob([base64toBlob(res.content, res.contentType)], {});
			// 		FileSaver.saveAs(blob, res.name);

			// 	} catch (e) {
			// 		dealWithError('genPTAfile.DownloadFile', e);
			// 	}

			// 	this.setState({
			// 		isDownloading: false
			// 	})
			// }

			const columns = [
				{
					title: 'Arquivo',
					dataIndex: 'file',
					render: (text, row) => <div>
						<div><Icon type={row.icon} theme="twoTone" /> {row.file}</div>
						<small>{row.description}</small>
					</div>
				},
				{
					title: 'Link',
					dataIndex: 'url',
					render: (text, row) => row.preFilled
						? <div>
							<div>
								<MonthPicker
									id="expense_ref_month"
									// disabled={isExpenseBlocked}
									onChange={this.onDisplayChange}
									value={this.state.displayDate}
									defaultValue={this.state.displayDate}
									size="large"
									format="MMMM / YYYY"
									placeholder="Qual o mês de referência?" />
							</div>
							<div>
								<Button
									type="link"
									onClick={downloadFile}
									loading={this.state.isDownloading}
									disabled={this.state.files[row.key].length > 0 || this.state.data.pta_finished}
								>Download do modelo pré-preenchido</Button>
							</div>
						</div>
						: <Button
							type="link"
							href={row.url}
							disabled={this.state.files[row.key].length > 0 || this.state.data.pta_finished}
						>Download do modelo</Button>

				},
				// {
				// 	title: 'Upload',
				// 	render: (text, row) => <Upload
				// 		id={row.key}
				// 		multiple={false}
				// 		customRequest={customRequest}
				// 		// fileList={this.state.files[row.key]}
				// 		data={row}
				// 		showUploadList={{
				// 			showRemoveIcon: false,
				// 			removeIcon: false
				// 		}}
				// 		disabled={this.state.files[row.key].length > 0 || this.state.data.pta_finished === 1}
				// 	>
				// 		<Button
				// 			disabled={this.state.files[row.key].length > 0 || this.state.data.pta_finished === 1}
				// 		>
				// 			<Icon type="upload" /> Selecionar
				// 		</Button >
				// 	</Upload >
				// },
			];

			const data = [
				{
					'key': 'pesquisa_satisfacao_usuario',
					'file': 'PESQUISA DE SATISFAÇÃO DO USUÁRIO.docx',
					'icon': 'file-excel',
					'description': '(Obrigatório) Pesquisa de satisfação',
					'url': '/drive/cdu/pesquisa_satisfacao_usuario.docx',
					'preFilled': false
				},
				// {
				// 	'key': 'relatorio_de_atividade_mensal_e_atesto-cessao_de_uso',
				// 	'file': 'RELATÓRIO DE ATIVIDADE MENSAL E ATESTO - CESSÃO DE USO.docx',
				// 	'icon': 'file-word',
				// 	'description': '(Obrigatório) Relatório de atividades mensais e atesto - cessão de uso',
				// 	'url': '/drive/pta-2020/relatorio_de_atividade_mensal_e_atesto-cessao_de_uso.docx'
				// },
				// {
				// 	'key': 'relatorio_de_cumprimento_do_objeto-cessao_de_uso',
				// 	'file': 'RELATÓRIO DE CUMPRIMENTO DO OBJETO - CESSÃO DE USO.docx',
				// 	'icon': 'file-word',
				// 	'description': '(Obrigatório) Relatório de cumprimento do objeto - cessão de uso',
				// 	'url': '/drive/pta-2020/relatorio_de_cumprimento_do_objeto-cessao_de_uso.docx'
				// },
				{
					'key': 'relatorio_de_cumprimento_do_objeto',
					'file': 'RELATÓRIO DE CUMPRIMENTO DO OBJETO.docx',
					'icon': 'file-word',
					'description': '(Obrigatório) Relatório de cumprimento do objeto',
					'url': '/drive/pta-2020/relatorio_de_cumprimento_do_objeto.docx',
					'preFilled': false
				},
				{
					'key': 'rfc-rdpm',
					'file': 'RFC - RDPM.xlsx',
					'icon': 'file-excel',
					'description': '(Obrigatório) Relatório Físico detalhado por meta',
					'url': config.apiGateway.URL + '/metas/financial-report/export',
					'preFilled': true
				},
			];

			// const toggleConfirmCheck = (e) => {
			// 	console.log(e);

			// 	this.setState({
			// 		confirmCheck: e.target.checked
			// 	})
			// }

			// const allowSubmit = (e) => {
			// 	return !(this.state.confirmCheck
			// 		&& this.state.files['relatorio_de_cumprimento_do_objeto'].length > 0
			// 		&& this.state.files['rfc-rdpm'].length > 0);
			// }

			// const submitHandler = async (e) => {
			// 	console.log(e);

			// 	const postData = {
			// 		...this.state.data,
			// 		files: this.state.files
			// 	}

			// 	try {
			// 		// debugger;
			// 		const res = await EIAPI.post(`/crea/pta-submit/`, {
			// 			body: postData
			// 		})

			// 		if (res.status !== 'success')
			// 			throw new Error('Não foi possível salvar o registro :-(')

			// 		window.location.reload();
			// 	} catch (e) {
			// 		dealWithError("CDUComplete.handleSubmit", e)
			// 	}
			// 	this.setState({ isLoading: false })
			// }

			let ptaStep = 2;
			if (this.state.data.pta_received && this.state.data.pta_signed && this.state.data.pta_finished)
				ptaStep = 5
			else if (this.state.data.pta_signed && this.state.data.pta_finished)
				ptaStep = 4
			else if (this.state.data.pta_finished)
				ptaStep = 3

			return (
				<div className="CDUComplete" >
					<Row>
						<Col {...wrapperCol}>
							<div>
								<Result
									title="Fechamento Cessão de Uso"
									subTitle={
										<div>
											<Paragraph>A Cessão de Uso deve ser enviada mensalmente ao CREA.</Paragraph>
											<Paragraph>Para ajudá-lo, preparamos o passo a passo abaixo com todas as informações que precisão ser enviadas.</Paragraph>
										</div>
									}
								/>
								<Steps progressDot current={ptaStep} direction="vertical" style={{ marginBottom: 50 }}>
									<Step title="1. Conclusão de despesas" description="Para garantir a qualidade e segurança da informação prestada ao CREA, é necessário que você conclua cada uma das despesas com as informações adicionais. (Após concluir uma despesa, não é mais possível editá-la)" />
									<Step title="2. Formulários CREA" description={
										<Table columns={columns} dataSource={data} size="middle" pagination={false} showHeader={false} />
									} />
									<Step title="3. Enviar a documentação" description={
										<div>
											<Paragraph><i>Os documentos preenchidos deverão ser enviados para <a href="mailto:colaboracao@creasp.org.br">colaboracao@creasp.org.br</a>.</i></Paragraph>
											{/* <Paragraph><i>Todas notas, comprovantes e anexos comprobatórios já foram disponibilizados em links seguros ao gerar o Relatório Financeiro acima.</i></Paragraph>
									<Paragraph>Para concluir, basta enviar selecionar os documentos devidamente preenchidos nos campos acima e confirmar clicando no botão abaixo.</Paragraph>
									<div>
										<Checkbox
											onChange={toggleConfirmCheck}
											size="large"
											disabled={this.state.data.pta_finished === 1}
											checked={this.state.confirmCheck}
										>
											Confirmo que todos os dados preenchidos estão cadastrados corretamentes.
												</Checkbox>
									</div>
									{!this.state.data.pta_finished &&
										<Button
											type="primary"
											icon="check"
											// size="medium"
											onClick={submitHandler}
											style={{ marginTop: 15 }}
											disabled={allowSubmit()}
										>
											Enviar documentação
                            					</Button>
									}
									{this.state.data.pta_finished ? <Alert
										message="Documentação Enviada"
										description={
											<div>
												<p>Data do envio: {formatDate(this.state.data.pta_delivery_date)}</p>
											</div>
										}
										showIcon
										type="success"
										style={{ marginTop: 20 }}
									/> : ''} */}
										</div>
									} />
									{/* <Step title="4. Assinatura da documentação" description={
								<div>
									<Paragraph>Esse é o último passo: a assinatura digital da documentação.</Paragraph>
									<Paragraph>Após a conclusão do passo 2 e 3, o responsável por cadastrar todas as informações dentro do EI e o presidente da entidade receberão um e-mail para validar e assinar os documentos antes da entrega para o CREA.</Paragraph>
									{this.state.data.pta_signed ? <Alert
										message="Documentação Assinada"
										description={
											<div>
												<p>Data da assinatura: {formatDate(this.state.data.pta_signed_date)}</p>
											</div>
										}
										showIcon
										type="success"
										style={{ marginTop: 20 }}
									/> : ''}
								</div>
							} />
							<Step title="5. Confirmação CREA" description={
								<div>
									<Paragraph>Você pode acompanhar o feedback do CREA por aqui, mas também será notificado via e-mail :-)</Paragraph>
									{this.state.data.pta_received ? <Alert
										message="Documentação Recebida pelo CREA"
										description={
											<div>
												<p>Data do recebimento: {formatDate(this.state.data.pta_received_date)}</p>
											</div>
										}
										showIcon
										type="success"
										style={{ marginTop: 20 }}
									/> : ''}
								</div>
							} /> */}
									{/* <Step title="6. Assinatura" description={
								<div>
									<Button onClick={genPTAfile}>Assinar</Button>
								</div>
							} /> */}
								</Steps>
							</div>
						</Col>
					</Row>
				</div>
			)
		}

		return (
			<div>
				{this.state.isLoading ? RenderPTALoading() : RenderCDUComplete()}
			</div>
		)
	}
}

export default class CDUComplete extends Component {
	MainForm = Form.create({ name: "form" })(CDUCompleteComponent);

	render() {
		const childProps = {
			// isAuthenticated: this.state.isAuthenticated,
			// isAppLoading: this.state.isAppLoading,
			match: this.props.match,
			history: this.props.history,
			appSetTitle: this.props.appSetTitle
		};

		return <this.MainForm {...childProps} />;
	}
}

import { Button, Card, Form, Spin, Table } from "antd";
import React, { Component } from "react";
// import config from "../../config";
import { s3GetDownloadURL } from "../../libs/awsLib";
import { dealWithError, EIAPIHelper, formatDateTime } from "../../libs/ei-helpers"; // , getStepCascade
import _ from 'loadsh';

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');


const EIAPI = new EIAPIHelper()

class ExpensesAttachmentsViewComponent extends React.Component {
    _isMounted = false;
    params = {};
    state = {
        isLoading: false,
        entity: {},
        person: {},
        // category: {},
        document: {},
        codeBaseURI: '',
        qrcode: '',
        data: {
            person_id: ''
        },

        attachments: [],
        newAttachments: []
    }

    constructor(props) {
        super(props);

        this.params = this.props.match.params;

        this.props.appSetTitle("Despesas - Anexos");
    }

    async componentDidMount() {
        this._isMounted = true;

        try {
            await this.getData()
        } catch (e) {
            dealWithError("componentDidMount", e)
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getData() {
        const id = typeof this.params.id !== "undefined" ? this.params.id : "new"

        if (this._isMounted) {

            this.setState({
                isLoading: true
            })

            const data = await EIAPI.get(`/expenses/${id}`)
            const entity = await EIAPI.get(`/entity/`)
            const attachments = await s3GetDownloadURL(data.attachments || []);

            this.setState({
                entity: entity,
                data: data,
                attachments: attachments
            })

            this.setState({ isLoading: false })
        }
    }

    render() {
        const expense = this.state.data
        const columns = [
            {
                key: 'description',
                title: 'Descrição',
                render: (text, row) => {
                    if (row.uid === expense.expense_pta_invoice_id)
                        return "Documento Fiscal";
                    else if (row.uid === expense.expense_pta_receipt_id)
                        return "Comprovante pagamento";
                    else if ([expense.expense_pta_provider1_propose_id, expense.expense_pta_provider2_propose_id, expense.expense_pta_provider3_propose_id].includes(row.uid))
                        return "Orçamento";
                    else
                        return "Outros documentos";
                }
            },
            {
                key: 'name',
                title: 'Arquivo',
                dataIndex: 'name'
            },
            {
                key: 'size',
                title: 'Tamanho',
                dataIndex: 'size',
                render: (text) => Math.floor(text / 1024) + " KB"
            },
            {
                key: 'createdAt',
                title: 'Data',
                dataIndex: 'createdAt',
                render: (text) => formatDateTime(text)
            },
            {
                key: 'actions',
                title: 'Ações',
                dataIndex: 'actions',
                render: (text, row) => <div>
                    <Button key={row.uid} icon="file-search" type="link" href={row.url} target="_blank">Visualizar</Button>
                </div>
            }
        ];

        const expenseAttachments = _.orderBy(this.state.attachments, 'name');

        return (
            <div className="Expenses">
                {this.state.isLoading && (
                    <div className="up-spin-area">
                        <Spin />
                    </div>
                )}
                {!this.state.isLoading && (
                    <div>
                        <Card title="Anexos">
                            <Table
                                pagination={false}
                                bordered
                                dataSource={expenseAttachments}
                                columns={columns}
                                isExpenseBlocked={true}
                                rowKey="attachment_id"
                            />
                            <Button
                                type="primary"
                                icon="download"
                                size="large"
                                block
                                style={{ marginTop: 15, margimBottom: 15 }}
                            >
                                Fazer download de todos os anexos
                            </Button>
                        </Card>



                        {/* <Card title="Documento Fiscal">
                            {expense.expense_pta_invoice_id}
                        </Card>
                        <Card title="Comprovante de Pagamento">
                            {expense.expense_pta_receipt_id}
                        </Card>
                        <Card title="Comprovante de Pagamento">
                            <div>{expense.expense_pta_provider1_propose_id}</div>
                            <div>{expense.expense_pta_provider2_propose_id}</div>
                            <div>{expense.expense_pta_provider3_propose_id}</div>
                        </Card> */}
                    </div>
                )
                }
            </div>
        )
    }
}

export default class ExpensesAttachmentsView extends Component {
    MainForm = Form.create({ name: "form" })(ExpensesAttachmentsViewComponent);

    render() {
        const childProps = {
            // isAuthenticated: this.state.isAuthenticated,
            // isAppLoading: this.state.isAppLoading,
            match: this.props.match,
            history: this.props.history,
            appSetTitle: this.props.appSetTitle
        };

        return <this.MainForm {...childProps} />;
    }
}

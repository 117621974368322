import { Alert, Card, Col, Form, Row, Spin, Table } from "antd";
import React, { Component } from "react";
// import config from "../../config";
import { s3GetDownloadURL } from "../../libs/awsLib";
import { dealWithError, EIAPIHelper, formatCurrency, formatDate, formatDateTime, getMetas, getStepCascade } from "../../libs/ei-helpers"; // , getStepCascade
import _ from 'loadsh';
import * as QRCode from 'qrcode'
import FilePreviewer from 'react-file-previewer';

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');


const EIAPI = new EIAPIHelper()

class PaymentsTable extends React.Component {
    constructor(props) {
        super(props);

        this.onDataChanged = props.onDataChanged || function () { }

        this.state = {
            dataSource: props.dataSource,
            bankAccounts: props.bankAccounts,
            metas: props.metas,
            isExpenseBlocked: props.isExpenseBlocked
        };

        this.columns = [
            {
                key: 'col_expense_payment_date',
                title: 'Data Pagamento',
                dataIndex: 'expense_payment_date',
                render: (text) => formatDate(text),
                editable: false
            },
            {
                key: 'col_expense_payment_value',
                title: 'Valor',
                dataIndex: 'expense_payment_value',
                render: (text) => formatCurrency(text),
                editable: false
            },
            {
                key: 'col_account',
                title: 'Conta / Verba',
                render: (text, row) => <div>
                    {row.expense_payment_by_proponent ? "Entidade" : "CREA"}
                </div>,
                editable: false
            },
            {
                key: 'col_expense_crea_step_id',
                title: 'Meta PTA',
                dataIndex: 'expense_crea_step_id',
                render: (text, row) => _.last(getStepCascade(text, this.state.metas, "label")),
                editable: false
            }
        ];
    }

    handleEdit = key => {
        const dataEdit = this.state.dataSource.filter(row => row.expense_payment_id === key);
        this.setState({
            paymentFormVisible: true,
            paymentFormData: dataEdit[dataEdit.length - 1],

        })
    }

    handleDelete = key => {
        const dataSource = _.map([...this.state.dataSource], (row) => {
            if (row.expense_payment_id === key)
                row.expense_payment_deleted_at = new Date()

            return row
        });
        this.setState({
            dataSource: dataSource
        }, () => this.onDataChanged(this.state.dataSource));
    };

    paymentRegisterFormToggle = () => {
        this.setState({
            paymentFormVisible: true
        })
    };

    handlePaymentCancel = (form) => {
        form.resetFields()
        this.setState({
            expense_payment_by_proponent: 0,
            paymentFormVisible: false,
            paymentFormData: this.initialPaymentFormData
        })
    }

    handlePaymentCreate = async (form) => {
        if (typeof form !== 'undefined')
            form.validateFieldsAndScroll(async (err, values) => {
                if (!err) {
                    const { dataSource, bankAccounts } = this.state;
                    const accountId = form.getFieldValue('account_id');
                    const stepId = _.last(form.getFieldValue('expense_crea_step')); // parseInt(form.getFieldValue('expense_payment_by_proponent')) !== 1 ? _.last(form.getFieldValue('expense_crea_step')) : null;
                    const account = _.find(bankAccounts, (row) => { if (row.account_id === accountId) { return row } })
                    const newData = {
                        expense_payment_id: form.getFieldValue('payment_id'),
                        account_id: accountId,
                        expense_crea_step_id: stepId,
                        account_name: account.account_name,
                        bank_name: account.bank_name,
                        bank_icon: account.bank_icon,
                        expense_payment_date: form.getFieldValue('expense_payment_date'),
                        expense_payment_value: parseFloat(form.getFieldValue('expense_payment_value')),
                        expense_payment_by_proponent: parseInt(form.getFieldValue('expense_payment_by_proponent')),
                        expense_payment_deleted_at: null
                    };
                    // console.log("============================================================================", newData)
                    form.resetFields()

                    if (form.getFieldValue('payment_id')) {
                        this.state.dataSource.forEach(data => {
                            if (data.expense_payment_id === newData.expense_payment_id) {
                                this.handleDelete(data.expense_payment_id);
                            }
                        })

                        this.setState({
                            dataSource: [...this.state.dataSource, newData],
                            paymentFormVisible: false,
                            paymentFormData: this.initialPaymentFormData,
                        }, () => {
                            this.onDataChanged(this.state.dataSource)
                        })
                    } else {
                        this.setState({
                            dataSource: [...dataSource, newData],
                            paymentFormVisible: false,
                        }, () => {
                            this.onDataChanged(this.state.dataSource)
                        });
                    }

                }
            })
    }

    handleSave = row => {
        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ dataSource: newData }, () => this.onDataChanged(this.state.dataSource));
    };

    render() {
        const dataSource = _.filter(this.state.dataSource, (row) => {
            return row.expense_payment_deleted_at === null
        })

        // const components = {
        //     body: {
        //         row: EditableFormRow,
        //         cell: EditableCell,
        //     },
        // };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });

        return (
            <div>
                <Table
                    {...this.props}
                    // components={components}
                    rowClassName={() => 'editable-row'}
                    // bordered
                    dataSource={dataSource}
                    columns={columns}
                />
            </div>
        );
    }
}

class ExpensesViewComponent extends React.Component {
    _isMounted = false;
    params = {};
    state = {
        isLoading: false,
        entity: {},
        person: {},
        // category: {},
        document: {},
        codeBaseURI: '',
        qrcode: '',
        data: {
            person_id: ''
        },

        attachments: [],
        newAttachments: []
    }

    constructor(props) {
        super(props);

        this.params = this.props.match.params;

        this.props.appSetTitle("Despesas");
    }

    async componentDidMount() {
        this._isMounted = true;

        try {
            await this.getData()
        } catch (e) {
            dealWithError("componentDidMount", e)
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getData() {
        const id = typeof this.params.id !== "undefined" ? this.params.id : "new"

        if (this._isMounted) {

            this.setState({
                isLoading: true
            })

            const data = await EIAPI.get(`/expenses/${id}`)
            const person = await EIAPI.get(`/persons/${data.person_id}`)
            const entity = await EIAPI.get(`/entity/`)

            const metas = await getMetas()

            // const categoryList = await EIAPI.get('/expenses/categories', {
            //     expense_category_outgoing: 1
            // })
            // const category = _.find(categoryList, { expense_category_id: data.expense_category_id })

            const documentList = await EIAPI.get('/expenses/documents')
            const document = _.find(documentList, { document_id: data.expense_document_type })

            const codeBaseURI = `https://app.entidadeintegrada.com.br/validar`
            const qrcode = await QRCode.toDataURL(`${codeBaseURI}/${data.expense_sign_code_verify}/${data.expense_sign_code_ei}`)

            const attachments = await s3GetDownloadURL(data.attachments || []);

            // if (data.expense_crea_step_id !== '') {
            //     const expenseMetaKey = getStepCascade(data.expense_crea_step_id, metas, "label");
            //     data.expense_crea_step = expenseMetaKey;
            // }

            this.setState({
                entity: entity,
                person: person,
                data: data,
                attachments: attachments,
                // category: category,
                codeBaseURI: codeBaseURI,
                qrcode: qrcode,
                document: document,
                metas: metas
            })

            this.setState({ isLoading: false })
        }
    }

    render() {
        const expense = this.state.data
        const entity = this.state.entity
        const person = this.state.person
        const address = this.state.person.person_address || {}
        const attachments = this.state.attachments
        // const checkedMetas = expense.expense_crea_step || []

        const codeBaseURI = this.state.codeBaseURI
        const qrcode = this.state.qrcode

        const getAttachmentsURL = (attachId) => {
            const attach = _.find(attachments, { uid: attachId })
            return _.isUndefined(attach) ? '' : attach.url
        }

        const isExpenseBlocked = expense.expense_blocked === 1

        if (!isExpenseBlocked) {
            return (
                <div className="PTAWizard">
                    <Alert
                        message="Carregando informações"
                        description={
                            <span>
                                <span>Carregando informações sobre a despesa....</span>
                            </span>
                        }
                        showIcon
                        type="info"
                        style={{ marginBottom: 20 }}
                    />
                </div>
            )
        }

        return (
            <div className="Expenses">
                {this.state.isLoading && (
                    <div className="up-spin-area">
                        <Spin />
                    </div>
                )}
                {!this.state.isLoading && (
                    <div>
                        <Card title="Plano de Trabalho">
                            <Row gutter={[12, 12]}>
                                <Col lg={24}>
                                    <Form.Item label="Plano de Trabalho">
                                        <div>CREA-SP - CHAMAMENTO PÚBLICO 002/2019</div>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>

                        <Card title="Dados do Fornecedor">
                            <Row gutter={[12, 12]}>
                                <Col lg={24}>
                                    <Form.Item label="CNPJ">
                                        <div>{person.person_document}</div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[12, 12]}>
                                <Col lg={24}>
                                    <Form.Item label="Razao Social">
                                        <div>{person.person_name}</div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[12, 12]}>
                                <Col lg={24}>
                                    <Form.Item label="Endereço">
                                        <div>
                                            {address.address_street}, {address.address_number}<br />
                                            {address.address_city} - {address.address_state}<br />
                                            {address.address_zipcode}
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <Card>
                            <table style={{ width: '100%', border: '1px solid darkgray' }}>
                                <tbody>
                                    <tr>
                                        <td style={{ width: 101 }}><img src={qrcode} alt="Imagem com URL para validar o documento" style={{ width: 96 }} /></td>
                                        <td style={{ padding: '6px 0' }}>
                                            <p>Este documento foi assinado digitalmente por <strong>{entity.person_name}</strong>, CNPJ: <strong>{entity.person_document}</strong> em <strong>{formatDateTime(expense.expense_signed_at)}</strong>.</p>
                                            <p>A autenticidade deste documento pode ser conferida no site <a href="{codeBaseURI}/{expense.expense_sign_code_verify}/{expense.expense_sign_code_ei}" target="_blank">{codeBaseURI}</a>, informando o código verificador <strong>{expense.expense_sign_code_verify}</strong> e o código EI <strong>{expense.expense_sign_code_ei}</strong></p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Card>
                        <div className="pagebreak"></div>

                        <Card title="Datas & Valores">
                            <Row gutter={[12, 12]}>
                                <Col lg={8}>
                                    <Form.Item label="Valor da Conta" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                        <div>{formatCurrency(expense.expense_value_paid)}</div>
                                    </Form.Item>
                                </Col>
                                <Col lg={8}>
                                    <Form.Item label="Data de Vencimento" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                        <div>{formatDate(expense.expense_due_day)}</div>
                                    </Form.Item>
                                </Col>
                                {/* <Col lg={8}>
                                    <Form.Item label="Previsão de Pagamento" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                        <div>{formatDate(expense.expense_payment_forecast)}</div>
                                    </Form.Item>
                                </Col> */}
                            </Row>
                        </Card>

                        <Card title="Pagamentos">
                            <PaymentsTable
                                header="Pagamentos realizados"
                                pagination={false}
                                dataSource={expense.payments}
                                // bankAccounts={this.state.bankAccounts}
                                isExpenseBlocked={true}
                                metas={this.state.metas}
                                rowKey="expense_payment_id"
                                size="small"
                            />
                        </Card>

                        <Card title="Informações do Documento">
                            <Row gutter={[12, 12]}>
                                <Col lg={6}>
                                    <Form.Item label="Número da Nota" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                        <div>{expense.expense_invoice}</div>
                                    </Form.Item>
                                </Col>
                                <Col lg={6}>
                                    <Form.Item label="Data Emissão" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                        <div>{formatDate(expense.expense_invoice_issued_at)}</div>
                                    </Form.Item>
                                </Col>
                                <Col lg={12}>
                                    <Form.Item label="Chave NF-e" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                        <div>{expense.expense_invoice_nfe}</div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[12, 12]}>
                                {/* <Col lg={12}>
                                    <Form.Item label="Tipo Documento" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                        <div>{expense.expense_document_name}</div>
                                    </Form.Item>
                                </Col> */}
                                <Col lg={24}>
                                    <Form.Item label="Número do Documento" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                        <div>{expense.expense_document_nro}</div>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <div className="pagebreak"></div>

                        <Card title="Documento Fiscal">
                            {expense.expense_pta_invoice_id}
                            <FilePreviewer
                                file={{ url: getAttachmentsURL(expense.expense_pta_invoice_id) }}
                                hideControls={true}
                            />
                        </Card>
                        <div className="pagebreak"></div>

                        <Card title="Comprovante de Pagamento">
                            <FilePreviewer
                                file={{ url: getAttachmentsURL(expense.expense_pta_receipt_id) }}
                                hideControls={true}
                            />
                        </Card>
                        <div className="pagebreak"></div>

                        <Card title="Orçamento 1">
                            <Row gutter={[12, 12]}>
                                <Col lg={24}>
                                    <Form.Item label="Fornecedor" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                        <div>{expense.expense_pta_provider1_name}</div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[12, 12]}>
                                <Col lg={24}>
                                    <Form.Item label="Valor Orçamento" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                        <div>{formatCurrency(expense.expense_pta_provider1_value_total)}</div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <FilePreviewer
                                file={{ url: getAttachmentsURL(expense.expense_pta_provider1_propose_id) }}
                                hideControls={true}
                            />
                        </Card>
                        <div className="pagebreak"></div>

                        <Card title="Orçamento 2">
                            <Row gutter={[12, 12]}>
                                <Col lg={24}>
                                    <Form.Item label="Fornecedor" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                        <div>{expense.expense_pta_provider2_name}</div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[12, 12]}>
                                <Col lg={24}>
                                    <Form.Item label="Valor Orçamento" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                        <div>{formatCurrency(expense.expense_pta_provider2_value_total)}</div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <FilePreviewer
                                file={{ url: getAttachmentsURL(expense.expense_pta_provider2_propose_id) }}
                                hideControls={true}
                            />
                        </Card>
                        <div className="pagebreak"></div>

                        <Card title="Orçamento 3">
                            <Row gutter={[12, 12]}>
                                <Col lg={24}>
                                    <Form.Item label="Fornecedor" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                        <div>{expense.expense_pta_provider3_name}</div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[12, 12]}>
                                <Col lg={24}>
                                    <Form.Item label="Valor Orçamento" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                                        <div>{formatCurrency(expense.expense_pta_provider3_value_total)}</div>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <FilePreviewer
                                file={{ url: getAttachmentsURL(expense.expense_pta_provider3_propose_id) }}
                                hideControls={true}
                            />
                        </Card>
                    </div>
                )
                }
            </div>
        )
    }
}

export default class ExpensesView extends Component {
    MainForm = Form.create({ name: "form" })(ExpensesViewComponent);

    render() {
        const childProps = {
            // isAuthenticated: this.state.isAuthenticated,
            // isAppLoading: this.state.isAppLoading,
            match: this.props.match,
            history: this.props.history,
            appSetTitle: this.props.appSetTitle
        };

        return <this.MainForm {...childProps} />;
    }
}

import { Button, Form, Result } from 'antd';
import MaskedInput from 'antd-mask-input';
import React, { Component } from "react";
import LoaderButton from "../../components/LoaderButton";
import "./ExpensesCheck.css";
import { formatDateTime } from "../../libs/ei-helpers";


class ExpensesCheckFormComponent extends React.Component {
  params = {}
  state = {
    isLoading: false,
    formVisible: true,
    code_verify: '',
    code_ei: ''
  }

  constructor(props) {
    super(props);

    this.params = this.props.match.params;

    this.props.appSetTitle("Validar Despesa");
  }

  async componentDidMount() {
    this.setState({ isLoading: false });
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    setTimeout(() => {
      this.setState({
        isLoading: false,
        formVisible: false
      });
    })
  }

  onBlur = event => {
    const field_name = event.target.id || event.target.name
    const field_value = event.target.value

    if (field_name === "email")
      this.setState({
        [field_name]: field_value.toLowerCase()
      })
  }

  onChange = event => {
    const field_name = event.target.id || event.target.name
    const field_value = event.target.value

    this.setState({
      [field_name]: field_value
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const entity = {}
    const expense = {}

    return (
      <div className="ExpensesCheck">
        <Form onSubmit={this.handleSubmit} className="expense-check-form">
          <img src="/imgs/ei_logo.png" className="logo" alt="Entidade Integrada" />
          {this.state.formVisible &&
            <div>
              <Form.Item label="Código de Verificação">
                {getFieldDecorator("code_verify", {
                  initialValue: this.state.code_verify,
                  rules: [
                    {
                      required: true,
                      message: "O Código de Verificação é obrigatório..."
                    }
                  ]
                })(
                  <MaskedInput
                    id="person_document"
                    type="text"
                    mask="111-111-111"
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                  />
                )}
              </Form.Item>
              <Form.Item label="Código EI">
                {getFieldDecorator("code_ei", {
                  initialValue: this.state.code_ei,
                  rules: [
                    {
                      required: true,
                      message: "O Código EI é obrigatório..."
                    }
                  ]
                })(
                  <MaskedInput
                    id="person_document"
                    type="text"
                    mask="## ## ## ##"
                    onChange={this.onChange}
                    onBlur={this.onBlur}
                  />
                )}
              </Form.Item>
              <LoaderButton
                block
                htmlType="submit"
                isLoading={this.state.isLoading}
                text="Validar Despesa"
                loadingText="Validando…"
              />
            </div>
          }
          {!this.state.formVisible &&
            <Result
              status="success"
              title="Documento válido!"
              subTitle={
                <p>Este documento foi assinado digitalmente por <strong>{entity.person_name}</strong>, CNPJ: <strong>{entity.person_document}</strong> em <strong>{formatDateTime(expense.expense_signed_at)}</strong>.</p>
              }
              extra={[
                <Button type="primary" key="console">
                  Visualizar Documento
                </Button>,
              ]}
            />
          }
        </Form>
      </div>
    )
  }
}

export default class ExpensesCheckForm extends Component {
  MainForm = Form.create({ name: "form" })(ExpensesCheckFormComponent);

  render() {
    const childProps = {
      // isAuthenticated: this.state.isAuthenticated,
      // isAppLoading: this.state.isAppLoading,
      match: this.props.match,
      history: this.props.history,
      appSetTitle: this.props.appSetTitle
    };

    return <this.MainForm {...childProps} />;
  }
}

import { Card, Col, Divider, Drawer, Select, Dropdown, Icon, List, Menu, Progress, Radio, Row, Spin, Statistic, Tooltip } from 'antd';
import _ from 'loadsh';
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { dealWithError, EIAPIHelper, formatCurrency, formatDate, getMetasWithValues } from "../../libs/ei-helpers";
import ls from "local-storage";

import "./PTA.css";

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

export default class Expenses extends Component {
	_isMounted = false;

	params = {};
	state = {
		isLoading: true,
		isDrawerPTALoading: true,
		isDrawerPTAVisible: false,
		packId: ls.get("app.lastUsedPackId") || "4582bb40-8bc4-11ea-ba6e-1636a2bc008f", // default: pta 2020
		packList: [
			{
				"crea_pack_id": "4582bb40-8bc4-11ea-ba6e-1636a2bc008f",
				"crea_pack_description": "Plano de Trabalho 2020"
			},
			{
				"crea_pack_id": "0741bcb5-9fa8-11eb-8587-167a37a95f75",
				"crea_pack_description": "Plano de Trabalho 2021"
			}
		],
		displayGroup: "total",
		stepDetail: {},
		expenses: [],
		metas: []
	}

	constructor(props) {
		super(props);

		this.params = this.props.match.params;

		this.props.appSetTitle("Plano de Trabalho / CREA-SP");
	}

	async componentDidMount() {
		this._isMounted = true;

		try {
			await this.getData()
		} catch (e) {
			dealWithError("componentDidMount", e)
		}

	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	async getData() {
		this.setState({
			isLoading: true
		})

		const metas = await getMetasWithValues(this.state.packId, this.state.displayGroup)
		const total_paid = _.sumBy(metas, 'value_paid');
		const total_budget = _.sumBy(metas, 'budget');
		const total_balance = total_budget - total_paid;

		this.setState({
			metas: metas,
			metas_total_paid: total_paid,
			metas_total_budget: total_budget,
			metas_total_balance: total_balance,
			isLoading: false
		})
	}

	onDisplayChange = e => {
		this.setState({
			displayGroup: e.target.value,
		}, this.getData);
	}

	onPackSelect = value => {
		ls.set("app.lastUsedPackId", value)
		this.setState({
			packId: value,
		}, this.getData);
	}

	renderPage() {
		const metas = this.state.metas

		const showDrawerPTA = async ({ key }) => {
			const EIAPI = new EIAPIHelper()
			const stepId = key.replace('details_', '')

			EIAPI.get(`/metas/pta-step/${stepId}`).then((data) => {
				this.setState({
					stepDetail: data,
					isDrawerPTAVisible: true,
					isDrawerPTALoading: false
				})
			})

			this.setState({
				isDrawerPTAVisible: true,
				isDrawerPTALoading: true
			})
		}

		const hideDrawerPTA = () => {
			this.setState({
				isDrawerPTAVisible: false
			})
		}

		const pStyle = {
			fontSize: 16,
			color: 'rgba(0,0,0,0.85)',
			lineHeight: '24px',
			display: 'block',
			marginBottom: 16,
		};

		const DescriptionItem = ({ title, content }) => (
			<div
				style={{
					fontSize: 14,
					lineHeight: '22px',
					marginBottom: 7,
					color: 'rgba(0,0,0,0.65)',
				}}
			>
				<p
					style={{
						marginRight: 8,
						display: 'inline-block',
						color: 'rgba(0,0,0,0.85)',
					}}
				>
					{title}:
				</p>
				{content}
			</div>
		)

		const RenderPTALoading = () => {
			return (
				<div className="up-spin-area">
					<Spin size="large" />
				</div>
			)
		}

		const RenderPTADetails = () => {
			const stepDetail = this.state.stepDetail

			if (stepDetail.subtitle === null)
				stepDetail.subtitle = ""
			if (stepDetail.details === null)
				stepDetail.details = ""

			return (
				<div>
					<p style={{ ...pStyle, marginBottom: 24 }}>PTA</p>
					<Row>
						<Col span={24}>
							<DescriptionItem
								title="Nome" content={(
									<div>
										<div><strong>{stepDetail.name}</strong></div>
										<div><small>{stepDetail.subtitle}</small></div>
										<div><small>{stepDetail.details}</small></div>
									</div>
								)}
							/>
						</Col>
					</Row>
					<Divider />
					<Row>
						<Col span={24}>
							<DescriptionItem
								title="Melhor Proposta" content={stepDetail.provider_win_name}
							/>
						</Col>
					</Row>
					<Row>
						<Col span={8}>
							<DescriptionItem
								title="Quantidade" content={stepDetail.quantity}
							/>
						</Col>
						<Col span={8}>
							<DescriptionItem
								title="Data Início" content={formatDate(stepDetail.date_start)}
							/>
						</Col>
						<Col span={8}>
							<DescriptionItem
								title="Data Fim" content={formatDate(stepDetail.date_end)}
							/>
						</Col>
					</Row>
					<Row>
						<Col span={8}>
							<DescriptionItem
								title="Valor Proponente" content={formatCurrency(stepDetail.value_proponent)}
							/>
						</Col>
						<Col span={8}>
							<DescriptionItem
								title="Valor Concedente" content={formatCurrency(stepDetail.value_grantor)}
							/>
						</Col>
						<Col span={8}>
							<DescriptionItem
								title="Valor Total" content={formatCurrency(stepDetail.value_total)}
							/>
						</Col>
					</Row>
					<Divider />
					<p style={pStyle}>Fornecedor 1</p>
					<Row>
						<Col span={24}>
							<DescriptionItem
								title="Nome" content={stepDetail.provider_name1}
							/>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<DescriptionItem title="Valor Unitário" content={formatCurrency(stepDetail.provider_value_unit1)} />
						</Col>
						<Col span={12}>
							<DescriptionItem title="Valor Total" content={formatCurrency(stepDetail.provider_value_total1)} />
						</Col>
					</Row>
					<Divider />
					<p style={pStyle}>Fornecedor 2</p>
					<Row>
						<Col span={24}>
							<DescriptionItem
								title="Nome" content={stepDetail.provider_name2}
							/>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<DescriptionItem title="Valor Unitário" content={formatCurrency(stepDetail.provider_value_unit2)} />
						</Col>
						<Col span={12}>
							<DescriptionItem title="Valor Total" content={formatCurrency(stepDetail.provider_value_total2)} />
						</Col>
					</Row>
					<Divider />
					<p style={pStyle}>Fornecedor 3</p>
					<Row>
						<Col span={24}>
							<DescriptionItem
								title="Nome" content={stepDetail.provider_name3}
							/>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<DescriptionItem title="Valor Unitário" content={formatCurrency(stepDetail.provider_value_unit3)} />
						</Col>
						<Col span={12}>
							<DescriptionItem title="Valor Total" content={formatCurrency(stepDetail.provider_value_total3)} />
						</Col>
					</Row>
				</div>
			)
		}

		return (
			<div>
				<div id="pta-options" className="ant-alert ant-alert-info no-print">
					<Divider>Selecione aqui o chamamento que deseja consultar</Divider>
					<Select
						defaultActiveFirstOption={true}
						showArrow={true}
						options={this.state.packList}
						onSelect={this.onPackSelect}
						value={this.state.packId}
					>
						{this.state.packList.map(d => (
							<Select.Option key={d.crea_pack_id} value={d.crea_pack_id}>
								{d.crea_pack_description}
							</Select.Option>
						))}
					</Select>
					<Divider>Selecione aqui qual a verba que deseja analisar</Divider>
					<Radio.Group
						autoFocus={true}
						defaultValue="all"
						buttonStyle="solid"
						size="large"
						onChange={this.onDisplayChange}
						value={this.state.displayGroup}
					>
						<Radio.Button value="total">Verba Total (CREA + Entidade)</Radio.Button>
						<Radio.Button value="grantor">Verba Concedente (CREA)</Radio.Button>
						<Radio.Button value="proponent">Verba Proponente (Entidade)</Radio.Button>
					</Radio.Group>
				</div>

				<div style={{ background: '#ECECEC', padding: '30px' }}>
					<Row gutter={15}>
						<Col sm={8} xs={24}>
							<Card bordered={true}>
								<Statistic title="Total da Verba" value={formatCurrency(this.state.metas_total_budget)} />
							</Card>
						</Col>
						<Col sm={8} xs={24}>
							<Card bordered={true}>
								<Statistic title="Valor Gasto" value={formatCurrency(this.state.metas_total_paid)} />
							</Card>
						</Col>
						<Col sm={8} xs={24}>
							<Card bordered={true}>
								<Statistic title="Saldo" value={formatCurrency(this.state.metas_total_balance)} />
							</Card>
						</Col>
					</Row>
				</div>
				<Card>
					<List
						rowKey="meta_id"
						loading={this.state.isLoading}
						dataSource={metas}
						renderItem={meta => (
							<div>
								<h2>{meta.label}</h2>
								<List
									rowKey="id"
									style={{ marginLeft: 15 }}
									dataSource={meta.children}
									renderItem={category => (
										<List
											header={(<strong>{category.label}</strong>)}
											dataSource={category.children}
											renderItem={step => (
												<List.Item
													style={{ marginLeft: 15 }}
												>
													<List.Item.Meta
														title={step.label}
													/>
													<div>
														<div className='up-list-item-block'>
															<List.Item.Meta
																title="Verba"
																description={
																	<Tooltip title={
																		<div>
																			<div>Concedente: {formatCurrency(step.value_grantor)}</div>
																			<div>Proponente: {formatCurrency(step.value_proponent)}</div>
																		</div>
																	}>
																		{formatCurrency(step.budget)}
																	</Tooltip>
																}
															/>
														</div>
														<div className='up-list-item-block'>
															<List.Item.Meta
																title="Valor Gasto"
																description={formatCurrency(step.value_paid)}
															/>
														</div>
														<div className='up-list-item-block'>
															<List.Item.Meta
																title={
																	<span>
																		{step.progress_description}&nbsp;
																		<Tooltip title={step.progress_tooltip}>
																			<Icon {...step.progress_icon} />
																		</Tooltip>
																	</span>
																}
																// {moment(step.created_at).format('DD/MM/YYYY HH:mm')}
																description={
																	<div>
																		<Progress percent={step.progress} status={step.status} showInfo={step.showInfo} strokeWidth={6} style={{ width: 180 }} />
																	</div>
																}
															/>
														</div>
														<div className='up-list-item-actions'>
															<Dropdown overlay={(
																<Menu>
																	<Menu.Item key={`filter_${step.value}`}>
																		<Link to={`/expenses/?filter.step_id=${step.value}`}>
																			{/* <Icon type="credit-card" /> */}
																			<span>Exibir despesas dessa meta</span>
																		</Link>
																	</Menu.Item>
																	<Menu.Item key={`details_${step.value}`} onClick={showDrawerPTA}>
																		{/* <Icon type="reconciliation" /> */}
																		<span>Detalhes do PTA</span>
																	</Menu.Item>
																</Menu>
															)}>
																<div className="ant-dropdown-link">
																	<Icon type="more" size="large" />
																</div>
															</Dropdown>
														</div>
													</div>
												</List.Item>
											)}
										/>
									)}
								/>
							</div>
						)}
					/>
				</Card>
				<Drawer
					width={640}
					placement="right"
					closable={true}
					onClose={hideDrawerPTA}
					visible={this.state.isDrawerPTAVisible}
				>
					{this.state.isDrawerPTALoading ? RenderPTALoading() : RenderPTADetails()}
				</Drawer>
			</div>
		)
	}

	render() {
		return (
			<div className="notes">
				{!this.state.isLoading && this.renderPage()}
			</div>
		)
	}
}

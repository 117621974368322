import { Card, DatePicker, Divider, Button, Dropdown, Icon, List, Menu, Spin } from 'antd';
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { EIAPIHelper, dealWithError, formatCurrency, getMetasWithValues, base64toBlob } from "../../libs/ei-helpers";
import FileSaver from "file-saver";

// import "./PTA.css";

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const EIAPI = new EIAPIHelper()
const { MonthPicker } = DatePicker;

export default class Expenses extends Component {
	_isMounted = false;
	params = {};
	state = {
		isLoading: true,
		pack_id: "0742f704-9fa8-11eb-8587-167a37a95f75", // Cessão de Uso
		displayDate: moment(),
		stepDetail: {},
		expenses: [],
		metas: []
	}

	constructor(props) {
		super(props);

		this.params = this.props.match.params;

		this.props.appSetTitle("Cessão de Uso / CREA-SP");
	}

	async componentDidMount() {
		this._isMounted = true;

		try {
			await this.getData()
		} catch (e) {
			dealWithError("componentDidMount", e)
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	async getData() {
		this.setState({
			isLoading: true
		})

		const metas = await getMetasWithValues(this.state.pack_id, undefined, this.state.displayDate.format('YYYY-MM'))

		this.setState({
			metas: metas,
			isLoading: false
		})
	}

	onDisplayChange = e => {
		this.setState({
			displayDate: e,
		}, this.getData);
	}

	downloadFile = async (e) => {
		try {
			this.setState({
				isDownloading: true
			})

			const res = await EIAPI.get(`/metas/financial-report/export/${this.state.pack_id}/${this.state.displayDate.format('YYYY-MM')}`)
			if (typeof res.name === 'undefined' || typeof res.content === 'undefined' || typeof res.contentType === 'undefined')
				throw new Error('Ocorreu um erro ao gerar a planilha :-(');

			let blob = new Blob([base64toBlob(res.content, res.contentType)], {});
			FileSaver.saveAs(blob, res.name);

		} catch (e) {
			dealWithError('PTAComplete.DownloadFile', e);
		}

		this.setState({
			isDownloading: false
		})
	}

	renderPage() {
		const metas = this.state.metas

		return (
			<div>
				<div id="pta-options" className="ant-alert ant-alert-info no-print">
					<Divider>Selecione aqui o período que deseja consultar</Divider>
					<MonthPicker
						id="expense_ref_month"
						// disabled={isExpenseBlocked}
						onChange={this.onDisplayChange}
						value={this.state.displayDate}
						defaultValue={this.state.displayDate}
						size="large"
						format="MMMM / YYYY"
						placeholder="Qual o mês de referência?" />
				</div>

				<Card style={{ marginTop: 20 }}>
					<List
						rowKey="meta_id"
						loading={this.state.isLoading}
						dataSource={metas}
						renderItem={meta => (
							<div>
								<div className="ei-table-actions" style={{ float: "right" }}>
									<Button onClick={this.downloadFile} type="primary">Exportar planilha preenchida</Button>
								</div>
								<h2>{meta.label}</h2>
								<List
									rowKey="id"
									style={{ marginLeft: 15 }}
									dataSource={meta.children}
									renderItem={category => (
										<List
											header={(<strong>{category.label}</strong>)}
											dataSource={category.children}
											renderItem={step => (
												<List.Item
													style={{ marginLeft: 15 }}
												>
													<List.Item.Meta
														title={step.label}
													/>
													<div>
														<div className='up-list-item-block'>
															<List.Item.Meta
																title="Valor Gasto"
																description={formatCurrency(step.value_paid)}
															/>
														</div>
														<div className='up-list-item-actions'>
															<Dropdown overlay={(
																<Menu>
																	<Menu.Item key={`filter_${step.value}`}>
																		<Link to={`/expenses/?filter.step_id=${step.value}`}>
																			{/* <Icon type="credit-card" /> */}
																			<span>Exibir despesas dessa meta</span>
																		</Link>
																	</Menu.Item>
																</Menu>
															)}>
																<div className="ant-dropdown-link">
																	<Icon type="more" size="large" />
																</div>
															</Dropdown>
														</div>
													</div>
												</List.Item>
											)}
										/>
									)}
								/>
							</div>
						)}
					/>
				</Card>
			</div>
		)
	}

	render() {
		return (
			<div className="notes">
				{!this.state.isLoading && this.renderPage()}
				{this.state.isLoading && (
					<div className="up-spin-area">
						<Spin size="large" />
					</div>
				)}
			</div>
		)
	}
}

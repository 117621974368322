import React, { Component } from "react"
import { Result, Typography } from 'antd'

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const { Paragraph, Text } = Typography

export default class Notes extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false
    this.state = {
      isLoading: true
    }

    props.appSetTitle('Integração')
  }

  // async componentDidMount() {
  //   this._isMounted = true

  //   if (!this.props.isAuthenticated) {
  //     return;
  //   }

  //   if (this._isMounted) {
  //     this.setState({ isLoading: false });
  //   }
  // }

  // componentWillUnmount() {
  //   this._isMounted = false
  // }

  render() {
    return (
      <Result
        status="500"
        title="Integração do PTA"
        subTitle={(
          <div>
            <Paragraph>
              <Text strong>Seu PTA não está aqui ainda? Não fique triste, nós vamos te ajudar :)</Text>
            </Paragraph>
            <Paragraph>
              <Text>Envie o PDF ou Planilha do Excel com o seu Plano de Trabalho que nossa equipe se encarregará de importá-lo para cá.</Text>
            </Paragraph>
          </div>
        )}
        extra={
          <a className="ant-btn ant-btn-primary" href="mailto:ajuda@omie.com.br">
            Enviar E-mail
        </a>
        }
      />
    )
  }
}

import React, { Component } from "react"
// import { API } from "aws-amplify"
import { dealWithError, EIAPIHelper, formatDate } from "../../libs/ei-helpers"
import { Link } from "react-router-dom"
import { Table, Icon, Tooltip } from 'antd'

import _ from 'loadsh'
import moment from 'moment'
import 'moment/locale/pt-br'
moment.locale('pt-br')

const EIAPI = new EIAPIHelper()

export default class Entities extends Component {
  constructor(props) {
    super(props)

    props.appSetTitle('CREA-SP: Lista de Entidades [PTA 2020]')

    this.state = {
      isLoading: true,
      entities: [],
      entitiesPagination: {},
      pageFilter: props.filters || {}
    }
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return
    }

    try {
      console.debug('GRID FILTERRRR', this.state.pageFilter)
      const res = await EIAPI.get("/crea/entities", this.state.pageFilter)
      this.setState({
        entities: res.data,
        entitiesPagination: res.pagination
      })
    } catch (e) {
      dealWithError('Notes', e)
    }

    this.setState({ isLoading: false })
  }

  renderEntitieList(entities, entitiesPagination) {
    const ptaLink = (row) => `/pta/${row.pta_id}/files`
    
    var columns = [
      {
        title: 'Entidade',
        dataIndex: 'entity_nicename',
        // ellipsis: true,
        // filters: entities.map((note) => { return { "text": note.nicename, "value": note.nicename } }),
        // onFilter: (value, record) => record.nicename.indexOf(value) === 0,
        sorter: (a, b) => a.entity_nicename.localeCompare(b.entity_nicename),
        render: (text, row) => <Link to={ptaLink(row)}>
                <div><b>{row.entity_name}</b></div>
                <div><small>{row.entity_nicename}</small></div>
                <div><small>{row.entity_document}</small></div>
            </Link>
      },
      {
        title: 'Documento',
        dataIndex: 'pta_name',
        ellipsis: true,
        filters: _.uniqBy(entities.map((row) => { return { "text": row.pta_name, "value": row.pta_name } }), 'pta_name'),
        // onFilter: (value, record) => record.pta_name.indexOf(value) === 0,
        sorter: (a, b) => a.entity_nicename.localeCompare(b.entity_nicename),
        render: (text, row) => <Link to={ptaLink(row)}>{row.pta_name}</Link>
      },
      {
        title: 'Enviado?',
        dataIndex: 'pta_finished',
        filters: [ { "text": "Sim", "value": 1 }, { "text": "Não", "value": 0 } ],
        onFilter: (value, record) => record.pta_finished === value,
        sorter: (a, b) => (a.pta_finished >= b.pta_finished),
        render: (text, row) => <Link to={ptaLink(row)} style={{display: "block", textAlign: "center"}}>{row.pta_finished
                ? <Tooltip title={`Enviado em ${formatDate(row.pta_delivery_date)} por ${row.pta_user_email}`}><Icon type="check-circle" title style={{fontSize: '25px', color: "green"}} /></Tooltip>
                : <Icon type="exclamation-circle" style={{fontSize: '25px', color: "red"}} />
            }</Link>
      },
      {
        title: 'Assinado?',
        dataIndex: 'pta_signed',
        filters: [ { "text": "Sim", "value": 1 }, { "text": "Não", "value": 0 } ],
        onFilter: (value, record) => record.pta_signed === value,
        sorter: (a, b) => (a.pta_signed >= b.pta_signed),
        render: (text, row) => <Link to={ptaLink(row)} style={{display: "block", textAlign: "center"}}>{row.pta_signed
            ? <Tooltip title={`Assinado em ${formatDate(row.pta_signed_date)}`}><Icon type="check-circle" title style={{fontSize: '25px', color: "green"}} /></Tooltip>
            : <Icon type="exclamation-circle" style={{fontSize: '25px', color: "red"}} />
        }</Link>
      },
      {
        title: 'Confirmado pelo CREA?',
        dataIndex: 'pta_received',
        filters: [ { "text": "Sim", "value": 1 }, { "text": "Não", "value": 0 } ],
        onFilter: (value, record) => record.pta_received === value,
        sorter: (a, b) => (a.pta_received >= b.pta_received),
        render: (text, row) => <Link to={ptaLink(row)} style={{display: "block", textAlign: "center"}}>{row.pta_received
            ? <Tooltip title={`Recebido em ${formatDate(row.pta_received_date)}`}><Icon type="check-circle" title style={{fontSize: '25px', color: "green"}} /></Tooltip>
            : <Icon type="exclamation-circle" style={{fontSize: '25px', color: "red"}} />
        }</Link>
      },
    //   {
    //     title: 'E-mail',
    //     dataIndex: 'entity_email',
    //     ellipsis: true,
    //     // filters: entities.map((note) => { return { "text": note.email, "value": note.email } }),
    //     // onFilter: (value, record) => record.email.indexOf(value) === 0,
    //     sorter: (a, b) => a.entity_email.localeCompare(b.entity_email),
    //     render: (text, row) => <Link to={ptaLink(row)}>{text}</Link>
    //   },
      // {
      //   title: 'Data Criação',
      //   dataIndex: 'entity_created_at',
      //   sorter: (a, b) => moment(a.entity_created_at) - moment(b.entity_created_at),
      //   render: (text) => <span>{moment(text).fromNow()}</span>
      // },
      // {
      //   title: 'Data Alteração',
      //   dataIndex: 'entity_updated_at',
      //   defaultSortOrder: 'descend',
      //   sorter: (a, b) => moment(a.entity_updated_at) - moment(b.entity_updated_at),
      //   render: (text) => <span>{moment(text).fromNow()}</span>
      // },
    ]

    return (
      <div>
        <Table
          bordered
          columns={columns}
          rowKey='entity_id'
          dataSource={entities}
          pagination={false}
        />
      </div>
    )
  }

  render() {
    return (
      <div className="entities">
        {!this.state.isLoading && this.renderEntitieList(this.state.entities, this.state.entitiesPagination)}
        <div style={{height: 30}}></div>
      </div>
    )
  }
}

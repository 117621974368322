import React, { Component } from "react"
// import ReactDOM from "react-dom";
import { API } from "aws-amplify"
// import { LinkContainer } from "react-router-bootstrap"
// import { ListGroup, ListGroupItem } from "react-bootstrap"
import { dealWithError } from "../../libs/ei-helpers"
import { Link } from "react-router-dom";
import { Table, Button, Icon } from 'antd'

import "./BankAccounts.css"

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

export default class BankAccounts extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false
    this.state = {
      isLoading: true,
      bankAccounts: [],
      bankAccountsPagination: {}
    }

    props.appSetTitle('Contas Correntes')
  }

  async componentDidMount() {
    this._isMounted = true

    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const res = await API.get("ei-api", "/bank-accounts");

      if (this._isMounted) {
        this.setState({
          bankAccounts: res.data,
          bankAccountsPagination: res.pagination
        });
      }
    } catch (e) {
      dealWithError('BankAccounts', e);
    }

    if (this._isMounted) {
      this.setState({ isLoading: false });
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onChange(pagination, filters, sorter, extra) {
    console.debug('params', pagination, filters, sorter, extra);
  }

  renderBankAccountsList(bankAccounts) {
    var columns = [
      {
        title: 'Banco',
        dataIndex: 'bank_name',
        filters: bankAccounts.map((row) => { return { "text": row.bank_name, "value": row.bank_name } }),
        onFilter: (value, row) => row.bank_name.indexOf(value) === 0,
        sorter: (a, b) => a.content.length - b.content.length,
        render: (text, row) => (
          <Link to={`/bank-accounts/${row.account_id}`}>
            <div className="bank-small-icon">
              <img src={(`/${row.bank_icon}`)} alt={row.bank_nro} />
            </div>
            <strong>{row.bank_type === 'CB' ? (<span>{row.bank_nro} - </span>) : ''}{row.bank_name}</strong>
          </Link>
        )
      },
      {
        title: 'Nome',
        dataIndex: 'account_name',
        // filters: bankAccounts.map((row) => { return { "text": row.account_agency, "value": row.account_agency } }),
        // onFilter: (value, row) => row.account_agency.indexOf(value) === 0,
        sorter: (a, b) => a.content.length - b.content.length,
        render: (text, row) => <Link to={`/bank-accounts/${row.account_id}`}>{text}</Link>
      },
      {
        title: 'Agência',
        dataIndex: 'account_agency',
        // filters: bankAccounts.map((row) => { return { "text": row.account_agency, "value": row.account_agency } }),
        // onFilter: (value, row) => row.account_agency.indexOf(value) === 0,
        sorter: (a, b) => a.content.length - b.content.length,
        render: (text, row) => <Link to={`/bank-accounts/${row.account_id}`}>{text}</Link>
      },
      {
        title: 'Nro. Conta',
        dataIndex: 'account_nro',
        // filters: bankAccounts.map((row) => { return { "text": row.account_nro, "value": row.account_nro } }),
        // onFilter: (value, row) => row.account_nro.indexOf(value) === 0,
        sorter: (a, b) => a.content.length - b.content.length,
        render: (text, row) => <Link to={`/bank-accounts/${row.account_id}`}>{text}</Link>
      },
      {
        title: 'Data Criação',
        dataIndex: 'account_created_at',
        sorter: (a, b) => moment(a.account_created_at) - moment(b.account_created_at),
        render: (text) => <span>{moment(text).fromNow()}</span>
      },
      {
        title: 'Data Alteração',
        dataIndex: 'account_updated_at',
        defaultSortOrder: 'descend',
        sorter: (a, b) => moment(a.account_updated_at) - moment(b.account_updated_at),
        render: (text) => <span>{moment(text).fromNow()}</span>
      }
    ];

    return (
      <div>
        <div style={{ 'textAlign': 'right', 'marginBottom': '10px' }}>
          <Link to="/bank-accounts/new">
            <Button type="primary">
              <Icon type="plus" />
              <span className="nav-text">Nova conta corrente</span>
            </Button>
          </Link>
        </div>
        <Table
          bordered
          columns={columns}
          rowKey='account_id'
          dataSource={bankAccounts}
          onChange={this.onChange}
          pagination={{
            'position': 'both'
          }}
        />
      </div>
    )
    // return [{}].concat(bankAccounts).map(
    //   (note, i) =>
    //     i !== 0
    //       ? <LinkContainer
    //           key={note.noteId}
    //           to={`/bank-accounts/${note.noteId}`}
    //         >
    //           <ListGroupItem header={note.content.trim().split("\n")[0]}>
    //             {"Created: " + new Date(note.createdAt).toLocaleString()}
    //           </ListGroupItem>
    //         </LinkContainer>
    //       : <LinkContainer
    //           key="new"
    //           to="/bank-accounts/new"
    //         >
    //           <ListGroupItem>
    //             <h4>
    //               <b>{"\uFF0B"}</b> Criar nova anotação 2
    //             </h4>
    //           </ListGroupItem>
    //         </LinkContainer>
    // );
  }

  render() {
    return (
      <div className="bankAccounts">
        {!this.state.isLoading && this.renderBankAccountsList(this.state.bankAccounts)}
      </div>
    )
  }
}

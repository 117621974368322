import React, { Component } from "react";
import { API } from "aws-amplify";
import { Form, Input, Button, Popconfirm, message, Upload, Spin, Icon, DatePicker } from 'antd'
import LoaderButton from "../../components/LoaderButton";
import _ from 'loadsh'

import { dealWithError, EIAPIHelper } from "../../libs/ei-helpers"
import { s3Upload, s3GetDownloadURL } from '../../libs/awsLib'
import config from "../../config";

import moment from 'moment';
import 'moment/locale/pt-br';
moment.locale('pt-br');

const EIAPI = new EIAPIHelper()

class NotesFormComponent extends React.Component {
	_isMounted = false
	params = {}
	state = {
		isLoading: false,
		isDataLoading: false,

		data: {},

		attachments: [],
		newAttachments: []
	}

	constructor(props) {
		super(props);

		this.params = this.props.match.params

		this.props.appSetTitle('Anotações')
	}

	async componentDidMount() {
		this._isMounted = true;

		try {
			await this.getData()
		} catch (e) {
			dealWithError("componentDidMount", e)
		}
	}

	// async componentDidMount() {
	// 	this._isMounted = true

	// 	try {
	// 		this.setState({ isLoading: true })

	// 		const note = await this.getNote();
	// 		if (this._isMounted) {
	// 			const { note_title, note_content } = note;
	// 			const attachments = await s3GetDownloadURL(note.attachments || [])

	// 			this.setState({
	// 				isLoading: false,
	// 				isDataLoading: false,
	// 				data: {},
	// 				attachments
	// 			})

	// 			this.setState({ isLoading: false })
	// 		}
	// 	} catch (e) {
	// 		dealWithError('componentDidMount', e);
	// 	}
	// }

	componentWillUnmount() {
		this._isMounted = false
	}

	async getData() {
		const id = typeof this.params.id !== "undefined" ? this.params.id : "new"
		let data = {}

		this.setState({ isLoading: true })

		if (id !== "new")
			data = await EIAPI.get(`/notes/${id}`)

		if (this._isMounted) {
			const attachments = await s3GetDownloadURL(data.attachments || []);

			data.note_remind_at = data.note_remind_at !== null && data.note_remind_at !== '0000-00-00 00:00:00' ? moment(data.note_remind_at) : ''

			this.setState({
				data,
				attachments
			})

			this.setState({ isLoading: false })
			this.updateData()
		}
	}

	updateData(objKeys) {
		const keys = objKeys || this.props.form.getFieldsValue()
		Object.keys(keys).forEach(key => {
			if (key !== 'key' && key !== 'keys') {
				const obj = {
					[key]: this.state.data[key]
				}
				this.props.form.setFieldsValue(obj)
			}
		})
	}

	async saveItem(data) {
		const id = typeof this.params.id !== "undefined" ? this.params.id : "new"
		const postData = _.merge(data, {
			note_id: this.state.note_id,
			newAttachments: this.state.newAttachments
		})

		// if (postData.note_remind_at !== 'undefined')
		// 	postData.note_remind_at = moment(postData.note_remind_at).toJSON()

		if (id !== "new")
			return await EIAPI.put(`/notes/${this.params.id}`, {
				body: postData
			})
		else
			return await EIAPI.post(`/notes/`, {
				body: postData
			})
	}

	async deleteNote() {
		return API.del("ei-api", `/notes/${this.params.id}`);
	}

	handleSubmit = async event => {
		event.preventDefault()

		this.props.form.validateFieldsAndScroll(async (err, values) => {
			if (!err) {
				this.setState({ isLoading: true })
				try {
					const res = await this.saveItem(values)
					if (res.status !== 'success')
						throw new Error('Não foi possível salvar o registro :-(')

					this.props.history.push("/notes/")
				} catch (e) {
					dealWithError('Notes.handleSubmit', e)
					this.setState({ isLoading: false })
				}
			}
		})

		return false
	}

	handleDelete = async event => {
		event.preventDefault();

		this.setState({ isDeleting: true });

		try {
			await this.deleteNote();
			this.props.history.push("/notes");
		} catch (e) {
			console.error(e)
			message.error("Ops! Não foi possível excluir este item :-(")
			this.setState({ isDeleting: false })
		}
	}

	customRequest = async ({ onSuccess, onError, file }) => {
		if (file && file.size > config.MAX_ATTACHMENT_SIZE) {
			message.info(`O tamanho máximo de anexos é de ${config.MAX_ATTACHMENT_SIZE / 1000000} MB.`);
			return;
		}

		try {
			if (file) {
				const storedKey = await s3Upload(file)

				const fileData = {
					upload_id: file.uid,
					attachment_name: file.name,
					attachment_size: file.size,
					attachment_content_type: file.type,
					attachment_store_key: storedKey
				}

				this.setState({
					newAttachments: [...this.state.newAttachments, fileData]
				})

				onSuccess(file.storedKey, file)
			}
		} catch (e) {
			dealWithError('Notes.customRequest', e)
			onError(e.message)
		}
	}

	onAttachmentRemove = async event => {
		const upload_id = event.uid
		const attachments = this.state.newAttachments.filter(file => file.upload_id !== upload_id)
		this.setState({
			newAttachments: attachments
		})
		EIAPI.del(`/attachments/notes/${this.params.id}/${upload_id}`);
	}

	render() {
		const { TextArea } = Input
		const attachments = this.state.attachments

		const { getFieldDecorator } = this.props.form

		return (
			<div className="Notes" >
				{this.state.isLoading && (
					<div className="up-spin-area">
						<Spin />
					</div>
				)}
				{!this.state.isLoading && (
					<Form {...config.FORM_LAYOUT} onSubmit={this.handleSubmit}>
						<Form.Item label="Título">
							{getFieldDecorator('note_title', {
								rules: [
									{
										required: true,
										message: 'O título é obrigatório pra criação de novas anotações...',
									},
								],
							})(<Input
								id="note_title"
								type="text"
								placeholder=""
							/>)}
						</Form.Item>
						<Form.Item label="Conteúdo">
							{getFieldDecorator('note_content', {
								rules: [
									{
										required: true,
										message: 'Não faz muito sentido criar uma anotação sem conteúdo né? :-)',
									},
								],
							})(<TextArea
								id="note_content"
								autoSize={{ minRows: 4, maxRows: 15 }}
							/>)}
						</Form.Item>
						<Form.Item label="Lembrar em">
							{getFieldDecorator('note_remind_at', {
							})(<DatePicker
								id="note_remind_at"
								allowClear
								showTime={{ format: 'HH:mm' }}
								format="DD/MM/YYYY HH:mm"
								placeholder="Quer ser lembrado dessa tarefa em uma data específica?" />)}
						</Form.Item>
						<Form.Item label="Anexo">
							<Upload id="upload-field"
								multiple={true}
								customRequest={this.customRequest}
								defaultFileList={attachments}
								onRemove={this.onAttachmentRemove}
							>
								<Button>
									<Icon type="upload" /> Enviar anexos
								</Button>
							</Upload>
						</Form.Item>
						<Form.Item {...config.TAIL_FORM_ITEM_LAYOUT}>
							{typeof this.state.data.note_id !== 'undefined' &&
								<Popconfirm
									title="Tem certeza que deseja excluir?"
									onConfirm={this.handleDelete}
									okText="Sim"
									cancelText="Não"
									className="up-right-button"
								>
									<Button type="danger" ghost>Excluir</Button>
								</Popconfirm>
							}
							<LoaderButton
								type="primary"
								htmlType="submit"
								loading={this.state.isLoading}
								text="Salvar"
								loadingText="Salvando..."
							/>
						</Form.Item>
					</Form>
				)}
			</div>
		)
	}
}

export default class NotesForm extends Component {
	MainForm = Form.create({ name: 'form' })(NotesFormComponent);

	render() {
		const childProps = {
			// isAuthenticated: this.state.isAuthenticated,
			// isAppLoading: this.state.isAppLoading,
			match: this.props.match,
			history: this.props.history,
			appSetTitle: this.props.appSetTitle
		}

		return (
			<this.MainForm {...childProps} />
		)
	}
}